import { Box, Button, Icon, Tooltip, theme } from 'ds4-beta'
import { CONTENT_TYPE, CTAButtonProps, ContentEditorCTAProps } from './types'
import {
  PUBLISH_LABEL,
  RBAC_LIMITED_EDIT_ACCESS_TOOLTIP,
  SAVE_TEXT,
  SCHEDULE_LABEL,
} from 'src/constants'
import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import {
  StyledButtonWithIconWrapper,
  StyledDropdownContent,
  StyledDropdownItem,
  StyledUpdatedAtWrapper,
} from './styles'
import { getLastUpdatedDateLabel, getSaveButtonTooltipText } from './utils'
import ChannelPreviewDropdown from 'src/ds4/modules/editor/components/ChannelPreviewDropdown'
import { DATE_FORMAT } from './constants'
import LocaleFilter from 'src/ds4/components/EditorContentHeader/EditorFilters/LocaleFilter'
import LockIcon from 'src/ds4/components/LockIcon'
import format from 'date-fns/format'
import some from 'lodash/some'
import useClickOutside from 'hooks/useClickOutside'

const ContentEditorCTA: FunctionComponent<ContentEditorCTAProps> = ({
  contentType,
  isContentLive,
  lastUpdatedDateString,
  onScheduleOptionClicked,
  publishButtonProps,
  saveButtonProps,
  shouldShowLockIcon,
  locales,
  selectedLocale,
  multiChannel,
}): JSX.Element => {
  const formattedDate =
    lastUpdatedDateString?.length &&
    format(new Date(lastUpdatedDateString), DATE_FORMAT)
  return (
    <Box
      flex={{
        flexDirection: 'column',
        alignItems: 'end',
      }}
      gap={4}
    >
      <Box
        flex={{
          alignItems: 'center',
          justifyContent: 'start',
        }}
        gap={2}
      >
        {some(locales) && (
          <LocaleFilter locales={locales} activeLang={selectedLocale} />
        )}
        {multiChannel && <ChannelPreviewDropdown />}
        <SaveButton contentType={contentType} {...saveButtonProps} />
        <PublishButton
          contentType={contentType}
          isContentLive={isContentLive}
          onScheduleOptionClicked={onScheduleOptionClicked}
          {...publishButtonProps}
        />
        {shouldShowLockIcon && (
          <LockIcon tooltipMessage={RBAC_LIMITED_EDIT_ACCESS_TOOLTIP} />
        )}
      </Box>
      {lastUpdatedDateString?.length > 0 && (
        <StyledUpdatedAtWrapper>
          {getLastUpdatedDateLabel(formattedDate)}
        </StyledUpdatedAtWrapper>
      )}
    </Box>
  )
}

const SaveButton: FunctionComponent<
  CTAButtonProps & {
    contentType: CONTENT_TYPE
  }
> = ({
  contentType,
  hasTooltip,
  isDisabled,
  isVisible = true,
  onClick,
  isSavePrimary,
}): JSX.Element => {
  if (isVisible && hasTooltip) {
    return (
      <>
        <Tooltip
          placement='bottom'
          text={getSaveButtonTooltipText(contentType)}
          dataTestid='save-button-tooltip'
        >
          <Button
            variant={isSavePrimary ? 'primary' : 'secondary'}
            onClick={onClick}
            isDisabled={isDisabled}
            text={SAVE_TEXT}
            dataTestid='save-button'
          />
        </Tooltip>
      </>
    )
  }
  if (isVisible && !hasTooltip) {
    return (
      <Button
        variant={isSavePrimary ? 'primary' : 'secondary'}
        onClick={onClick}
        isDisabled={isDisabled}
        text={SAVE_TEXT}
        dataTestid='save-button'
      />
    )
  }
  return null
}
const PublishButton: FunctionComponent<
  CTAButtonProps & {
    contentType: CONTENT_TYPE
    isContentLive: boolean
    onScheduleOptionClicked: () => void
  }
> = ({
  isContentLive,
  isDisabled,
  isVisible,
  onClick,
  onScheduleOptionClicked,
}): JSX.Element => {
  const dropdownRef = useRef(null)
  const [shouldShowFlyout, setShouldShowFlyout] = useState(false)

  useClickOutside(dropdownRef, () => {
    setShouldShowFlyout(false)
  })

  const handleDropdownClick = useCallback(() => {
    !isDisabled &&
      setShouldShowFlyout((isFlyoutVisible: boolean) => !isFlyoutVisible)
  }, [isDisabled, setShouldShowFlyout])

  const renderFlyout = () => (
    <StyledDropdownContent>
      <StyledDropdownItem
        onClick={onScheduleOptionClicked}
        data-testid='schedule-option'
      >
        {SCHEDULE_LABEL}
      </StyledDropdownItem>
    </StyledDropdownContent>
  )

  return (
    isVisible && (
      <>
        <StyledButtonWithIconWrapper
          ref={dropdownRef}
          isContentLive={isContentLive}
        >
          <Button
            text={PUBLISH_LABEL}
            dataTestid='publish-button'
            variant='primary'
            iconPosition='right'
            onClick={onClick}
            isDisabled={isDisabled}
          />
          {!isContentLive && (
            <Box as='span' onClick={handleDropdownClick}>
              <Icon
                iconName='ArrowDown'
                data-testid='publish-dropdown'
                color={theme.color.grey[0]}
              />
            </Box>
          )}
          {shouldShowFlyout && renderFlyout()}
        </StyledButtonWithIconWrapper>
      </>
    )
  )
}

export default ContentEditorCTA
