import ComponentViewMode from 'src/ds4/modules/settings/utils/ComponentViewMode'

class AllComponentsMode extends ComponentViewMode {
  retrieveId() {
    return null
  }

  retrieveOnNavigateBack() {
    return () => {
      return undefined
    }
  }

  isEditMode() {
    return false
  }

  showDescriptorLabelError() {
    return false
  }

  retrieveHeader() {
    return null
  }

  setModeAfterSave() {
    return null
  }

  showDescriptorIdError() {
    return false
  }

  shouldHideSubmitButton() {
    return true
  }

  shouldShowDeleteButton() {
    return false
  }
}

export default new AllComponentsMode()
