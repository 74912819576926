import * as Yup from 'yup'
import {
  CREATE_NEW_VERSION_LABEL,
  CREATE_PAGE_LABEL,
  EDITOR_MODAL_LABEL,
  EDIT_DETAILS_LABEL,
  LOCALES,
  MAKE_A_COPY_LABEL,
  MODAL_ACTION,
  MODAL_SECTIONS,
  PAGE_MODAL_LABEL,
  PAGE_TYPE_MODAL_LABEL,
  SEO_TITLE_MAX_LENGTH,
  VALIDATION_ERRORS,
  VIEW_DETAILS_LABEL,
} from 'src/constants'
import {
  LocalizedSeoFieldAction,
  SeoFieldAction,
} from 'components/FormDialog/common/content/ContentDetailsDialog/utils/SeoFieldAction'
import {
  LocalizedSeoFields,
  MetadataProps,
} from 'components/FormDialog/common/content/ContentDetailsDialog/types'
import { Modal, ModalContext } from 'data/types'
import getMultiSelectTextChipsOptions, {
  OptionsType,
} from 'lib/getMultiSelectTextChipsOptions'
import { CHANNELS_FILTER_LABEL } from 'components/Filters/constants'
import { ChannelGraphQLResponse } from 'src/graphql-proxy/common'
import { DEFAULT_METADATA } from 'components/FormDialog/common/content/ContentDetailsDialog/utils/utils'
import { FormikProps } from 'formik'
import { LocaleValue } from 'components/LocalesField/types'
import { ModalType } from 'components/ContentModalModule/types'
import { MultiSelectTextChipsOptions } from 'lib/getMultiSelectTextChipsOptions/types'
import { SeoMetadataListProps } from 'components/ContentModalModule/utils'
import some from 'lodash/some'

interface ModalCommonInput<I, V> extends ModalContext {
  initialValues?: I
  onModalClose?: () => void
  onSubmit?: (values: V) => Promise<void> | void
}

type NewPageModalInput = ModalCommonInput<
  null,
  NewPageModalForm['values'] & SeoMetaDataModalForm['values']
>

export const generateAddNewPageModal = (input: NewPageModalInput): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'small',
    title: CREATE_PAGE_LABEL,
    mode: MODAL_ACTION.CREATE,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateNewPageModalForm({
        context: input.context,
      }),
      generateSeoMetaDataModalForm({
        context: input.context,
        mode: MODAL_ACTION.CREATE,
      }),
    ],
  }
}

type PageEditDetailsModalInput = ModalCommonInput<
  {
    generalDetailsInitialValues: PageEditDetailsModalFormInput['initialValues']
    seoFormInitialValues: SeoMetaDataModalFormInput['initialValues']
  },
  PageEditDetailsModalForm['values'] & SeoMetaDataModalForm['values']
>

export const generatePageEditDetailsModal = (
  input: PageEditDetailsModalInput
): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'small',
    title: EDIT_DETAILS_LABEL,
    mode: MODAL_ACTION.EDIT,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateEditPageModalForm({
        initialValues: input.initialValues.generalDetailsInitialValues,
        context: input.context,
      }),
      generateSeoMetaDataModalForm({
        initialValues: input.initialValues.seoFormInitialValues,
        context: input.context,
        mode: MODAL_ACTION.EDIT,
      }),
    ],
  }
}

export const generatePageViewDetailsModal = (
  input: PageEditDetailsModalInput
): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'small',
    title: VIEW_DETAILS_LABEL,
    mode: MODAL_ACTION.VIEW,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateEditPageModalForm({
        initialValues: input.initialValues.generalDetailsInitialValues,
        context: input.context,
      }),
      generateSeoMetaDataModalForm({
        initialValues: input.initialValues.seoFormInitialValues,
        context: input.context,
        mode: MODAL_ACTION.VIEW,
      }),
    ],
  }
}

type NewPageTypeModalInput = ModalCommonInput<
  NewPageTypeModalFormInput['initialValues'],
  NewPageTypeModalForm['values']
>
export const generateAddNewTypeModal = (
  input: NewPageTypeModalInput,
  isEditModal?: boolean
): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'small',
    title: isEditModal
      ? PAGE_TYPE_MODAL_LABEL.EDIT_PAGE_TYPE
      : PAGE_TYPE_MODAL_LABEL.ADD_PAGE_TYPE,
    mode: MODAL_ACTION.CREATE,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateNewPageTypeModalForm({
        initialValues: input.initialValues,
        context: input.context,
      }),
    ],
  }
}

export const generateViewTypeModal = (input: NewPageTypeModalInput): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'small',
    title: PAGE_TYPE_MODAL_LABEL.VIEW_PAGE_TYPE,
    mode: MODAL_ACTION.VIEW,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateNewPageTypeModalForm({
        initialValues: input.initialValues,
        context: input.context,
      }),
    ],
  }
}

/** Forms */

type NewPageModalForm = {
  values: {
    locales: MultiSelectTextChipsOptions[]
    channels: MultiSelectTextChipsOptions[]
    pageType: string
    pageName: string
    pageVersionName: string
    url: string
  }
}

const generateNewPageModalForm = (input: ModalContext) => {
  const initialValues: NewPageModalForm['values'] = {
    pageType: '',
    pageName: '',
    pageVersionName: '',
    url: '',
    locales: input.context.localesChipsOptions,
    channels: [],
  }

  const hasLocales = !!input.context.localesChipsOptions?.length
  const validationSchema = Yup.object().shape({
    pageName: Yup.string().required(VALIDATION_ERRORS.EMPTY_PAGE_NAME),
    pageVersionName: Yup.string().required(
      VALIDATION_ERRORS.EMPTY_VERSION_NAME
    ),
    url: Yup.string().required(VALIDATION_ERRORS.EMPTY_URL),
    ...(hasLocales && {
      locales: Yup.array().min(1, VALIDATION_ERRORS.MIN_LOCALE_REQUIRED),
    }),
  })

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema,
    getComponents: (values: NewPageModalForm['values']) => [
      ...getLocalesAndChannelsFieldsFromContext(values, input.context),
      {
        type: 'PageTypeField',
        label: PAGE_MODAL_LABEL.PAGE_TYPE,
        name: 'pageType',
        value: values.pageType,
        dataTestId: 'input-new-page-type',
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.PAGE_NAME,
        name: 'pageName',
        value: values.pageName,
        // eslint-disable-next-line sonarjs/no-duplicate-string
        dataTestId: 'input-page-name',
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.PAGE_VERSION_NAME,
        name: 'pageVersionName',
        value: values.pageVersionName,
        dataTestId: 'input-page-version-name',
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.PAGE_URL,
        name: 'url',
        value: values.url,
        dataTestId: 'input-url',
      },
    ],
  }
}

interface PageEditDetailsModalForm {
  values: {
    locales: MultiSelectTextChipsOptions[]
    channels: MultiSelectTextChipsOptions[]
    pageType: string
    pageName: string
    url: string
  }
}

interface PageEditDetailsModalFormInput extends ModalContext {
  initialValues: Omit<
    PageEditDetailsModalForm['values'],
    'locales' | 'channels'
  > & {
    locales: string[]
    channels: ChannelGraphQLResponse[]
  }
}

const generateEditPageModalForm = (input: PageEditDetailsModalFormInput) => {
  const {
    locales: localeIds,
    channels: channelsDTO,
    ...restInitialValues
  } = input.initialValues

  const channels = channelsDTO
    ? getMultiSelectTextChipsOptions(
        channelsDTO?.map(c => ({ ...c, channelURL: c?.url })),
        OptionsType.CHANNELS
      )
    : []

  const locales = localeIds
    ? input.context?.localesChipsOptions?.filter(o =>
        localeIds?.includes(o?.id)
      )
    : []

  const initialValues: PageEditDetailsModalForm['values'] = {
    pageType: '',
    pageName: '',
    url: '',
    locales: locales ?? [],
    channels: channels ?? [],
    ...restInitialValues,
  }

  const hasLocales = !!input.context?.localesChipsOptions?.length
  const validationSchema = Yup.object().shape({
    pageName: Yup.string().required(VALIDATION_ERRORS.EMPTY_PAGE_NAME),
    url: Yup.string().required(VALIDATION_ERRORS.EMPTY_URL),
    ...(hasLocales && {
      locales: Yup.array().min(1, VALIDATION_ERRORS.MIN_LOCALE_REQUIRED),
    }),
  })

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema,
    getComponents: (values: typeof initialValues) => {
      return [
        ...getLocalesAndChannelsFieldsFromContext(values, input.context),
        {
          type: 'PageTypeField',
          label: PAGE_MODAL_LABEL.PAGE_TYPE,
          name: 'pageType',
          value: values.pageType,
          dataTestId: 'input-new-page-type',
        },
        {
          type: 'Input',
          label: PAGE_MODAL_LABEL.PAGE_NAME,
          name: 'pageName',
          value: values.pageName,
          dataTestId: 'input-page-name',
        },
        {
          type: 'Input',
          label: PAGE_MODAL_LABEL.PAGE_URL,
          name: 'url',
          value: values.url,
          dataTestId: 'input-url',
        },
      ]
    },
  }
}
interface SeoMetaDataModalForm {
  values: {
    title: string
    description: string
    metadata: MetadataProps[]
    localizedSeoFields: LocalizedSeoFields
  }
}

interface SeoMetaDataModalFormInput extends ModalContext {
  initialValues?: Partial<SeoMetaDataModalForm['values']>
  mode: string
}

const generateSeoMetaDataModalForm = (input: SeoMetaDataModalFormInput) => {
  const {
    title = '',
    description = '',
    metadata,
    localizedSeoFields,
    ...restInitialValues
  } = input.initialValues ?? {}

  const initialValues: SeoMetaDataModalForm['values'] & {
    selectedLocale?: { id: string; name: string }
  } = {
    localizedSeoFields: localizedSeoFields ?? {},
    title: title,
    description: description,
    metadata: metadata ?? DEFAULT_METADATA,
    ...restInitialValues,
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(
      SEO_TITLE_MAX_LENGTH,
      VALIDATION_ERRORS.SEO_TITLE_LIMIT
    ),
  })

  return {
    name: MODAL_SECTIONS.SEO_AND_METADATA,
    initialValues,
    validationSchema,
    getComponents: (
      values: SeoMetaDataModalForm['values'] & {
        locales: LocaleValue[]
        selectedLocale: { id: string; name: string }
      },
      formikProps: FormikProps<typeof initialValues>
    ) => {
      const localesEnabled = some(values.locales)

      const defaultLocale = {
        id: values.locales?.[0]?.id,
        name: values.locales?.[0]?.label,
      }
      const selectedLocale = {
        id: values.selectedLocale?.id,
        name: values.selectedLocale?.name,
      }

      if (localesEnabled) {
        const locales = values.locales
        const isSelectedLocaleValid = locales?.some(
          l => l?.id === selectedLocale.id
        )
        if (!values.selectedLocale || !isSelectedLocaleValid) {
          formikProps.setFieldValue('selectedLocale', defaultLocale)
        }
      }

      const seoFieldAction = localesEnabled
        ? LocalizedSeoFieldAction({
            localizedSeoFields: values.localizedSeoFields,
            selectedLocale: selectedLocale,
          })
        : SeoFieldAction({ values })

      return [
        localesEnabled && {
          type: 'SingleSelect',
          label: LOCALES.localesSeoFieldLabel,
          name: 'selectedLocale',
          dataTestId: 'input-seo-locals-field',
          value: values.selectedLocale,
          dropdownOptions: values.locales,
          forceEnabled: true,
        },
        {
          type: 'Input',
          label: PAGE_MODAL_LABEL.SEO_TITLE,
          name: seoFieldAction.getSeoTitle().name,
          value: seoFieldAction.getSeoTitle().value,
          dataTestId: 'input-seo-title',
        },
        {
          type: 'Textarea',
          label: PAGE_MODAL_LABEL.SEO_DESCRIPTION,
          name: seoFieldAction.getDescription().name,
          value: seoFieldAction.getDescription().value,
          dataTestId: 'input-seo-description',
        },
        {
          type: 'SeoMetadataList',
          dataTestId: 'seo-metadata-container',
          seoFieldAction,
          localeCode: selectedLocale?.id,
          disabled: input.mode === MODAL_ACTION.VIEW,
        } as SeoMetadataListProps,
      ]
    },
  }
}

type MakePageTypeCopyModalInput = ModalCommonInput<
  {
    copyFormInitialValues: PageTypeCopyModalFormInput['initialValues']
    seoFormInitialValues: SeoMetaDataModalFormInput['initialValues']
  },
  PageCopyModalForm['values'] & SeoMetaDataModalForm['values']
>
export const generateMakePageCopyModal = (
  input: MakePageTypeCopyModalInput
): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'small',
    title: MAKE_A_COPY_LABEL,
    mode: MODAL_ACTION.COPY,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generatePageCopyModalForm({
        initialValues: input.initialValues?.copyFormInitialValues,
        context: input.context,
      }),
      generateSeoMetaDataModalForm({
        initialValues: input.initialValues?.seoFormInitialValues,
        context: input.context,
        mode: MODAL_ACTION.COPY,
      }),
    ],
  }
}

interface PageCopyModalForm {
  values: {
    pageType: string
    pageName: string
    pageVersions: string
    newPageName: string
    newPageURL: string
    channels: MultiSelectTextChipsOptions[]
    locales: MultiSelectTextChipsOptions[]
  }
}

interface PageTypeCopyModalFormInput extends ModalContext {
  initialValues?: Omit<
    Partial<PageCopyModalForm['values']>,
    'locales' | 'channels'
  > & {
    locales: string[]
    channels: ChannelGraphQLResponse[]
  }
}

const generatePageCopyModalForm = (input: PageTypeCopyModalFormInput) => {
  const {
    locales: localeIds,
    channels: channelsDTO,
    ...restInitialValues
  } = input.initialValues

  const channels = channelsDTO
    ? getMultiSelectTextChipsOptions(
        channelsDTO?.map(c => ({ ...c, channelURL: c?.url })),
        OptionsType.CHANNELS
      )
    : []

  const locales = localeIds
    ? input.context?.localesChipsOptions?.filter(o => localeIds?.includes(o.id))
    : []

  const initialValues: PageCopyModalForm['values'] = {
    pageType: '',
    pageName: '',
    pageVersions: '',
    newPageName: '',
    newPageURL: '',
    channels,
    locales,
    ...(restInitialValues ?? {}),
  }

  const hasLocales = input.context?.localesChipsOptions?.length
  const validationSchema = Yup.object().shape({
    newPageName: Yup.string().required(VALIDATION_ERRORS.EMPTY_NEW_PAGE_NAME),
    newPageURL: Yup.string().required(VALIDATION_ERRORS.EMPTY_PAGE_TYPE_URL),
    ...(hasLocales && {
      locales: Yup.array().min(1, VALIDATION_ERRORS.MIN_LOCALE_REQUIRED),
    }),
  })

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema,
    getComponents: (values: PageCopyModalForm['values']) => [
      ...getLocalesAndChannelsFieldsFromContext(values, input.context),
      {
        type: 'PageTypeField',
        label: PAGE_MODAL_LABEL.PAGE_TYPE,
        name: 'pageType',
        value: values.pageType,
        dataTestId: 'input-page-type',
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.PAGE_NAME,
        name: 'pageName',
        value: values.pageName,
        dataTestId: 'input-page-name',
        disabled: true,
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.COPY_PAGE_VERSIONS,
        name: 'pageVersions',
        value: values.pageVersions,
        dataTestId: 'input-page-versions',
        disabled: true,
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.COPY_PAGE_NAME,
        name: 'newPageName',
        value: values?.newPageName,
        dataTestId: 'input-newpage-name',
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.PAGE_URL,
        name: 'newPageURL',
        value: values?.newPageURL,
        dataTestId: 'input-url',
      },
    ],
  }
}

interface NewPageTypeModalForm {
  values: {
    name: string
    prefix: string
    id?: string
  }
}

interface NewPageTypeModalFormInput extends ModalContext {
  initialValues?: Partial<NewPageTypeModalForm['values']>
}

const generateNewPageTypeModalForm = (input: NewPageTypeModalFormInput) => {
  const initialValues: NewPageTypeModalForm['values'] = {
    name: '',
    prefix: '',
    ...(input.initialValues ?? {}),
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(VALIDATION_ERRORS.EMPTY_PAGE_TYPE_NAME),
    prefix: Yup.string().required(VALIDATION_ERRORS.EMPTY_PAGE_TYPE_URL),
  })

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema,
    getComponents: (values: NewPageTypeModalForm['values']) => [
      {
        type: 'Input',
        label: PAGE_TYPE_MODAL_LABEL.PAGE_TYPE_NAME,
        name: 'name',
        value: values.name,
        dataTestId: 'input-page-type-name',
      },
      {
        type: 'Input',
        label: PAGE_TYPE_MODAL_LABEL.PREFIX,
        name: 'prefix',
        value: values.prefix,
        dataTestId: 'input-page-type-prefix',
      },
    ],
  }
}

type NewPageVersionModalInput = ModalCommonInput<
  NewVersionModalFormInput['initialValues'],
  NewVersionModalForm['values']
>
export const generateCreateNewPageVersionModal = (
  input: NewPageVersionModalInput
): Modal => {
  const isVersionBeingCopied = input.initialValues?.versionName
  return {
    modalType: ModalType.Form,
    size: 'small',
    title: isVersionBeingCopied ? MAKE_A_COPY_LABEL : CREATE_NEW_VERSION_LABEL,
    mode: MODAL_ACTION.CREATE,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateNewVersionModalForm({
        initialValues: input.initialValues,
        context: input.context,
      }),
    ],
  }
}

type NewVersionModalForm = {
  values: {
    pageName: string
    versionName: string
    url: string
    description: string
  }
}

interface NewVersionModalFormInput extends ModalContext {
  initialValues?: Partial<NewVersionModalForm['values']>
}

const generateNewVersionModalForm = (input: NewVersionModalFormInput) => {
  const initialValues: NewVersionModalForm['values'] = {
    pageName: '',
    versionName: '',
    url: '',
    description: '',
    ...(input.initialValues ?? {}),
  }

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema: Yup.object().shape({
      versionName: Yup.string().required(VALIDATION_ERRORS.EMPTY_VERSION_NAME),
    }),
    getComponents: (values: NewVersionModalForm['values']) => [
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.PAGE_NAME,
        name: 'name',
        value: values?.pageName,
        dataTestId: 'input-page-type-name',
        disabled: true,
      },
      {
        type: 'Input',
        label: EDITOR_MODAL_LABEL.VERSION_NAME,
        name: 'versionName',
        value: values.versionName,
        dataTestId: 'input-version-name',
      },
      {
        type: 'Input',
        label: PAGE_MODAL_LABEL.PAGE_URL,
        name: 'url',
        value: values?.url,
        dataTestId: 'input-url',
        disabled: true,
      },
      {
        type: 'Input',
        label: EDITOR_MODAL_LABEL.DESCRIPTION,
        name: 'description',
        value: values?.description,
        dataTestId: 'input-description',
      },
    ],
  }
}

const getLocalesAndChannelsFieldsFromContext = (
  values: {
    locales: MultiSelectTextChipsOptions[]
    channels: MultiSelectTextChipsOptions[]
  },
  context: ModalContext['context']
) => {
  const localesExists = context.localesChipsOptions?.length
  const channelsExists = context.channelsChipsOptions?.length

  return [
    ...(localesExists
      ? [
          {
            type: 'MultiSelectTextChips',
            label: LOCALES.localesMultiSelectLabel,
            name: 'locales',
            dataTestId: 'pages-locales-field',
            value: values.locales,
            dropdownOptions: context.localesChipsOptions,
          },
        ]
      : []),
    ...(channelsExists
      ? [
          {
            type: 'MultiSelectTextChips',
            label: CHANNELS_FILTER_LABEL,
            name: 'channels',
            dataTestId: 'menu-channels-field',
            value: values.channels,
            dropdownOptions: context.channelsChipsOptions,
          },
        ]
      : []),
  ]
}
