import {
  ARROW_AREA_WIDTH,
  ARROW_ICON_SIZE,
  EACH_ICON_SIZE,
  FOCUS_STATE_PADDING,
  LABEL_MIN_SIZE,
  PLUS_ICON_SIZE,
} from './constants'
import styled, { keyframes } from 'styled-components'
import { LEFT_SIDE_BAR_WIDTH } from '../../AppLayout/constants'
import { theme as themeUi } from '@teamfabric/copilot-ui'
import { theme } from 'ds4-beta'
export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 1px solid ${themeUi.palette.ui.neutral.grey4};
  background-color: ${themeUi.palette.ui.neutral.grey5};
`

export const StyledItemWrapper = styled.div`
  display: grid;
  grid-template-columns: ${ARROW_AREA_WIDTH}% minmax(${LABEL_MIN_SIZE}px, 70%) repeat(2, ${EACH_ICON_SIZE}px)};
  align-items: center;
  padding: ${props => props.theme.space.space_4};
  padding-left: ${({ level }) => `${level * 1}rem`};
  background: ${themeUi.palette.brand.primary.white};
  cursor: pointer;
  border-top: 1px solid ${themeUi.palette.ui.neutral.grey4};
  border-left: ${FOCUS_STATE_PADDING}px solid transparent;
  border-left-color: ${({ level, isExpanded }) =>
    isExpanded && level === 1
      ? `${themeUi.palette.ui.states.active}`
      : 'transparent'};

  svg.icon-delete {
    display: none;
    fill: ${themeUi.palette.brand.primary.gray};
  }

  .component-context-menu {
    display: none;
  }

  &:hover {
    background: ${themeUi.palette.ui.neutral.grey5};
    opacity: 0.8;
    position: relative;
    z-index: 1;

    svg.icon-delete,
    .component-context-menu {
    display: block;
  }

  .custom-tooltip {
    text-transform: capitalize;
  }
`

export const StyledItemLabel = styled.div`
 ${theme.typography.body2.medium}
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  display: inline-block;
  align-items: center;
  max-width: ${({ level, theme }) =>
    `calc(${LEFT_SIDE_BAR_WIDTH}px - (${
      theme.space.space_4
    } + ${ARROW_ICON_SIZE}px + ${PLUS_ICON_SIZE}px + ${FOCUS_STATE_PADDING}px + (${
      level * 1
    }rem)))`};
  height: 100%;
  cursor: pointer;
`

export const StyledIconWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  svg {
    fill: ${themeUi.palette.brand.primary.gray};
  }
  &[data-testid='icon-plus']:hover svg {
    fill: ${themeUi.palette.brand.primary.charcoal};
  }
`

export const StyledItemLabelWrapper = styled.div`
  align-items: center;
  display: flex;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const StyledFadeIn = styled.div`
  animation: ${fadeIn} 0.5s ease-in;
`
