import { BACK_TO_MENUS, STATUS } from 'src/constants'
import { Link, useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import {
  StyledIconBack,
  StyledStatus,
  StyledStatusNameWrapper,
  StyledStatusWrapper,
  StyledTitle,
  StyledWrapperHeader,
} from './styles'
import { BROWSE_MENU_TREE_MODAL_ID } from 'data/browse-menu-tree-gql/types'
import { CONTENT_TYPE } from 'src/ds4/components/ContentEditorCTA/types'
import ContentEditorCTA from 'src/ds4/components/ContentEditorCTA'
import EditorOptions from 'src/ds4/modules/editor/components/editorPageTitle/EditorOptions'
import { HeaderProps } from './types'
import { MENU_V2_PATH, NEW_MENU_V2_PATH } from 'contexts/navigationLinksContext'
import VersionDetailsDialog from 'src/ds4/components/FormDialog/common/versions/VersionDetailsDialog'
import VersionPublishDialog from 'src/ds4/components/FormDialog/page/VersionPublishDialog'
import { VersionTypesEnum } from 'src/ds4/components/FormDialog/common/versions/VersionDetailsDialog/types'
import extract from 'lib/extract'
import { getLocales } from 'store/i18n/selectors'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import noop from 'lodash/noop'
import { useSelector } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'
import { useNewNavigation } from 'src/contexts/navigationLinksContext'
import { Badge, Box, Tooltip } from 'ds4-beta'
import { getBadgeStatusForVersion } from '../../utils/index'

const Header = ({
  menuId,
  menuVersionId,
  menuVariant,
  locales,
  selectedLocale,
  showModal,
  setMenu,
}: HeaderProps): JSX.Element => {
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const applicationLocales = useSelector(getLocales)
  const localesSet = new Set(locales)
  const { search } = useLocation()
  const { reschedule } = extract.queryParams(search)
  const [versionStatus, setVersionStatus] = useState<string>('')
  const { hasPublisherPermissions, hasEditorPermissions } = useUserPermissions()
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const [versionName, setVersionName] = useState<string>('')
  const [versionDescription, setVersionDescription] = useState<string>('')
  const [showModalByType, setShowModalByType] = useState<string>('')

  const isLiveStatus = () => {
    if (menuVariant?.status === STATUS.LIVE) return true
    try {
      const currentDate = new Date()
      return (
        menuVariant &&
        menuVariant.status === STATUS.SCHEDULED &&
        new Date(menuVariant.startDate) <= currentDate
      )
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const updateStatus = (status: string) => {
    setVersionStatus(status)
  }

  useEffect(() => {
    const isVersionLive = isLiveStatus()
    const variantStatus = isVersionLive ? STATUS.LIVE : menuVariant?.status
    setIsLive(isVersionLive)
    if (menuVariant) {
      setVersionName(menuVariant?.versionName)
      setVersionDescription(menuVariant?.description)
      setVersionStatus(variantStatus)
    }
  }, [menuVariant])

  useEffect(() => {
    const isValidReschedule =
      hasPublisherPermissions &&
      versionStatus === STATUS.SCHEDULED &&
      reschedule === 'true'

    if (isValidReschedule) {
      setShowScheduleModal(true)
    }
  }, [hasEditorPermissions, hasPublisherPermissions, reschedule, versionStatus])

  const menu = { id: menuId }
  const version = { id: menuVersionId }

  const openSelectedModal = () => {
    const parentElement = {
      id: menuId,
    }
    const versionElement = {
      id: menuVersionId,
      name: versionName,
      description: versionDescription,
    }

    if (showModalByType) {
      return (
        <VersionDetailsDialog
          isVisible
          handleClose={() => setShowModalByType('')}
          mode={showModalByType}
          parentElement={parentElement}
          versionElement={versionElement}
          contentType={VersionTypesEnum.MENUS}
          setVersionName={setVersionName}
          setVersionDescription={setVersionDescription}
          setVersionStatus={setVersionStatus}
          setMenu={setMenu}
        />
      )
    }
  }
  return (
    <>
      {showModalByType && openSelectedModal()}
      {showScheduleModal && (
        <VersionPublishDialog
          handleClose={() => {
            setShowScheduleModal(false)
          }}
          menu={menu}
          version={version}
          updateStatus={updateStatus}
        />
      )}
      <Box
        flex={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        margin={[6]}
      >
        <Box>
          <Box
            flex={{
              alignItems: 'center',
              justifyContent: 'start',
            }}
            gap={2}
            margin={{ bottom: 2 }}
          >
            <Box>
              <Tooltip id='version-name' text={versionName}>
                <Box as='span'>{versionName}</Box>
              </Tooltip>
            </Box>
            <Box as='span' data-testid='sidebar-components-editor-options'>
              <EditorOptions onEditorOptionClick={setShowModalByType} />
            </Box>
          </Box>
          <Badge
            label={versionStatus.toLowerCase()}
            variant='primary'
            status={getBadgeStatusForVersion(versionStatus)}
            asset='none'
            data-testid='version-status'
          />
        </Box>
        <ContentEditorCTA
          locales={applicationLocales?.filter(locale =>
            localesSet.has(locale.code)
          )}
          selectedLocale={selectedLocale}
          contentType={CONTENT_TYPE.MENU}
          isContentLive={isLive}
          lastUpdatedDateString={menuVariant?.updatedAt}
          shouldShowLockIcon={!hasEditAccess}
          saveButtonProps={{
            isVisible: false,
            isDisabled: false,
            hasTooltip: false,
            onClick: noop,
          }}
          publishButtonProps={{
            isVisible: hasPublisherPermissions,
            isDisabled: false,
            hasTooltip: false,
            onClick: () => {
              showModal(BROWSE_MENU_TREE_MODAL_ID.PUBLISH)
            },
          }}
          onScheduleOptionClicked={() => setShowScheduleModal(true)}
        />
      </Box>
    </>
  )
}

export default Header
