import Alert from 'src/ds4/components/Alert'
import { MODAL_SECTIONS } from 'src/constants'
import React from 'react'
import { VersionDetailForm } from './VersionDetailForm'
import handleGqlErrorMessage from 'lib/handleGqlErrorMessage'

const dialogModalSections = ({
  formValues,
  submission,
  validationErrors,
  showErrors,
  setFormValues,
  handleClose,
}) => ({
  'general-details': {
    key: 'general-details',
    title: MODAL_SECTIONS.GENERAL_DETAILS,
    render: () => {
      return (
        <>
          {submission.error && (
            <Alert data-testid='alert'>
              {handleGqlErrorMessage(submission.error)}
            </Alert>
          )}
          <VersionDetailForm
            formValues={formValues}
            onChange={v => setFormValues(v)}
            submitButtonText={'Save'}
            submitDisabled={
              submission.loading || Object.keys(validationErrors).length > 0
            }
            validationErrors={showErrors ? validationErrors : {}}
            onSubmit={submission.run}
            onCancel={handleClose}
          />
        </>
      )
    },
  },
})

export default dialogModalSections
