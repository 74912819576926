import { MESSAGES, PAGE_MODAL_LABEL, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import pageTypeService from 'services/api/pageType'
import { showToast } from 'components/ToastSnackbarContainer'
import { Dropdown, Skeleton } from 'ds4-beta'

const PageTypeField = props => {
  const { pageTypeId, setPageType } = props
  const [pageTypes, setPageTypes] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!pageTypes.length) {
      const QUERY_LIMIT = 1000
      pageTypeService
        .list(0, QUERY_LIMIT)
        .then(response => {
          const result = response.pageTypes
          if (result.length) {
            setPageTypes(result)
          }
          setIsLoaded(true)
        })
        .catch(e => {
          showToast({
            message: e.message,
            kind: TOAST_MESSAGE_TYPES.ALERT,
          })
        })
    }
  }, [pageTypes])
  const options = useMemo(() => {
    return pageTypes.map(pageType => ({
      ...pageType,
      id: pageType._id,
      label: `${pageType.name} ${pageType.urlPrefix}`,
    }))
  }, [pageTypes])

  const handlePageTypeIdChange = selectedPage => {
    setPageType(selectedPage)
  }
  const inputValue = useMemo(() => {
    const pageType = pageTypes.find(type => type._id === pageTypeId)
    if (pageType) {
      return pageType.name
    }
  }, [pageTypeId, pageTypes])

  const value = pageTypeId
    ? {
        id: pageTypeId,
        name: inputValue || '-',
      }
    : {}

  const emptyOptions = [{ name: MESSAGES.NO_PAGE_TYPES }]
  const emptyValue = {}
  return (
    <>
      {options?.length > 0 ? (
        <Dropdown
          width='100%'
          options={options}
          label={PAGE_MODAL_LABEL.PAGE_TYPE}
          value={value}
          dataTestid='page-type-field'
          disabled={props.disabled}
          onChange={handlePageTypeIdChange}
        />
      ) : (
        <>
          {isLoaded ? (
            <Dropdown
              width='100%'
              options={emptyOptions}
              label={PAGE_MODAL_LABEL.PAGE_TYPE}
              value={emptyValue}
              dataTestid='empty-page-type-field'
              disabled={props.disabled}
            />
          ) : (
            <Skeleton height={'36px'} width={'456px'} />
          )}
        </>
      )}
    </>
  )
}

PageTypeField.defaultProps = {
  pageTypeId: '',
  setPageType: noop,
  disabled: false,
}

PageTypeField.propTypes = {
  pageTypeId: PropTypes.string.isRequired,
  setPageType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default PageTypeField
