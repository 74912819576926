import { Box, Checkbox, Filter, Link } from 'ds4-beta'
import {
  StyledCheckboxContainer,
  StyledCheckboxGroupContainer,
  StyledWrapper,
} from './styles'
import { FilterByProps } from './types'
import React from 'react'

const FilterOptions = ({ multiSelectOptions, onChange }: FilterByProps) => {
  const isFilterApplied = Boolean(
    multiSelectOptions?.filter(option => option.isChecked)?.length
  )
  const handleOnChange = ({
    id,
    e,
  }: {
    id: string
    e: React.ChangeEvent<HTMLInputElement>
  }) => {
    const isChecked = e.target.checked
    const newOptions = multiSelectOptions.map(option => {
      if (option.id === id) {
        option.isChecked = isChecked
      }
      return option
    })
    onChange && onChange(newOptions.filter(option => option.isChecked))
  }

  const onResetFilters = () => {
    onChange && onChange([])
  }

  return (
    <>
      <StyledCheckboxGroupContainer>
        {multiSelectOptions.map(option => (
          <StyledCheckboxContainer key={option.id}>
            <Checkbox
              id={option.id}
              label={option.name}
              value={option.name}
              name={option.name}
              checked={option.isChecked}
              onChange={e => handleOnChange({ id: option.id, e })}
            />
          </StyledCheckboxContainer>
        ))}
      </StyledCheckboxGroupContainer>
      <Box
        flex={{ justifyContent: 'flex-end' }}
        padding={{ left: 3, right: 3, bottom: 3 }}
      >
        <Link
          onClick={onResetFilters}
          label='Reset all'
          dataTestid={'content-filter-module-reset-filters'}
          mode='standalone'
          disabled={!isFilterApplied}
        />
      </Box>
    </>
  )
}

const FilterBy: React.FC<FilterByProps> = props => {
  const { chipsName, multiSelectOptions } = props
  const filterCount = multiSelectOptions.filter(option => option.isChecked)
    ?.length

  const dataTestId = chipsName?.replace(/\s+/g, '-').toLowerCase()

  return (
    <StyledWrapper data-testid={`filter-by-${dataTestId}`}>
      <Filter
        label={chipsName}
        variant='primary'
        showFilterIcon
        renderResults={<FilterOptions {...props} />}
        filterCount={`${filterCount > 0 ? filterCount : ''}`}
      />
    </StyledWrapper>
  )
}

export default FilterBy
