import React, { memo, useCallback } from 'react'
import { Box, GridRow, GridCol, IconButton } from 'ds4-beta'
import PropTypes from 'prop-types'
import iframeSize from './iframeSize'
import isEqual from 'react-fast-compare'
import { noop } from 'lodash'
import { useNavigate } from '@reach/router'

const PreviewControls = props => {
  const { previewUrl, selectedDevice, handleOnFrameChange } = props
  const navigate = useNavigate()
  const handleOpenPagePreview = useCallback(() => {
    navigate(previewUrl)
  }, [navigate, previewUrl])

  const handleOnToggleDevice = useCallback(
    deviceName => {
      const iframe = iframeSize[deviceName]
      handleOnFrameChange(iframe)
    },
    [handleOnFrameChange]
  )
  return (
    <GridRow>
      <GridCol md={2} sm={12}></GridCol>
      <GridCol md={10} sm={12}>
        <Box
          margin={{ top: 4 }}
          flex={{ justifyContent: 'center' }}
          gap={2}
          dataTestid='preview-controls-wrapper'
        >
          <IconButton
            icon='Phone'
            onClick={useCallback(() => handleOnToggleDevice('mobile'), [
              handleOnToggleDevice,
            ])}
            data-testid='preview-controls-mobile'
            variant={selectedDevice === 'mobile' ? 'primary' : 'secondary'}
          />
          <IconButton
            icon='Tablet'
            onClick={useCallback(() => handleOnToggleDevice('tablet'), [
              handleOnToggleDevice,
            ])}
            data-testid='preview-controls-tablet'
            variant={selectedDevice === 'tablet' ? 'primary' : 'secondary'}
          />

          <IconButton
            icon='Desktop'
            onClick={useCallback(() => handleOnToggleDevice('desktop'), [
              handleOnToggleDevice,
            ])}
            dataTestid='preview-controls-desktop'
            variant={selectedDevice === 'desktop' ? 'primary' : 'secondary'}
          />
          <IconButton
            icon='View'
            onClick={handleOpenPagePreview}
            dataTestid='preview-controls-preview'
            variant='secondary'
          />
        </Box>
      </GridCol>
    </GridRow>
  )
}

PreviewControls.defaultProps = {
  selectedDevice: 'desktop',
  previewUrl: '/',
  handleOnFrameChange: noop,
}

PreviewControls.propTypes = {
  selectedDevice: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  handleOnFrameChange: PropTypes.func.isRequired,
}

export default memo(PreviewControls, isEqual)
