import {
  EMPTY_SELECTED_LOCALE,
  generateNewMetadata,
  getInitialSelectedLocale,
} from '../utils/utils'
import { LOCALES, PAGE_MODAL_LABEL } from 'src/constants'
import {
  LocalizedSeoFieldAction,
  SeoFieldAction,
} from '../utils/SeoFieldAction'
import { PageInitialValueProps, SeoFieldActionFunctionProps } from '../types'
import React, { useState } from 'react'
import {
  FlexGridCol,
  StyledAddButtonContainer,
  StyledContentFieldContainer,
  StyledGridRow,
  StyledSEOMetaDataLabel,
  StyledSEOMetaDataWrapper,
} from '../styles'
import { FormikProps } from 'formik'
import { I18nLocale } from 'store/i18n/types'
import { StyledFormField } from 'src/ds4/components/FormDialog/components/styles'
import VersionDetailHeader from 'src/ds4/components/FormDialog/components/VersionDetailHeader'
import { getModeConfigurationForHeader } from 'src/ds4/components/FormDialog/common/utils/getModeConfigurationForHeader'
import isEmpty from 'lodash/isEmpty'
import produce from 'immer'
import some from 'lodash/some'
import useDidMountEffect from 'hooks/useDidMountEffect'
import {
  Dropdown,
  GridCol,
  IconButton,
  Input,
  Textarea,
} from 'ds4-beta'
import { InputElementType } from 'ds4-beta/dist/components/BaseInputComponent'

const {
  SEO_TITLE,
  SEO_DESCRIPTION,
  METADATA,
  METADATA_CONTENT,
  METADATA_NAME,
} = PAGE_MODAL_LABEL

const ATTRIBUTES_MAX_LENGTH = 400
const DESCRIPTION_MAX_LENGTH = 400
const SEO_TITLE_MAX_LENGTH = 91
const TEXT_AREA_ROWS = 2

interface SeoAndMetadataProps {
  formik: FormikProps<PageInitialValueProps>
  mode: string
  handleClose: () => void
  locales: I18nLocale[]
}

export const SeoMetadataList = ({
  disabled = false,
  handleBlur,
  localeCode,
  seoFieldAction,
  setFieldValue,
}: {
  disabled: boolean
  handleBlur: (e: React.FocusEvent) => void
  localeCode?: string
  seoFieldAction: SeoFieldActionFunctionProps
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}): JSX.Element => {
  const [mouseHoverIndex, setMouseHoverIndex] = useState(0)
  const metadata = seoFieldAction.getMetadata()

  const handleMetaDataChange = ({
    value,
    recordIdx,
    field,
  }: {
    value: string
    recordIdx: number
    field: string
  }) => {
    const nextSEOMetaData = produce(metadata, draftState => {
      draftState[recordIdx][field] = value
    })

    setFieldValue(seoFieldAction.getMetadataFieldName(), nextSEOMetaData, true)
  }

  const handleAddSEOMetaData = () => {
    const createdMetadata = generateNewMetadata()
    const nextMetadata = produce(metadata, draftState => {
      draftState.unshift(createdMetadata)
    })

    setFieldValue(seoFieldAction.getMetadataFieldName(), nextMetadata, true)
  }

  const handleRemoveSEOMetaData = (idx: number) => {
    // Cannot remove last metadata entry
    if (seoFieldAction.getMetadata().length === 1) {
      return
    }
    const nextMetadata = produce(metadata, draftState => {
      draftState.splice(idx, 1)
    })

    setFieldValue(seoFieldAction.getMetadataFieldName(), nextMetadata, true)
  }

  return (
    <>
      <StyledSEOMetaDataLabel>{METADATA}</StyledSEOMetaDataLabel>
      {seoFieldAction.getMetadata()?.map((record, idx) => {
        return (
          <StyledSEOMetaDataWrapper
            key={record.id}
            id={`seo-meta-data-wrapper-${idx}`}
            data-testid='seo-meta-data-wrapper'
            onMouseLeave={() => {
              setMouseHoverIndex(0)
            }}
            onMouseEnter={() => {
              setMouseHoverIndex(idx)
            }}
          >
            <StyledGridRow>
              <GridCol>
                <Input
                  label={METADATA_NAME}
                  width={"196px"}
                  inputProps={{
                    disabled,
                    value: record.name,
                    dataTestid: `metadata-name-${idx}`,
                    id: `metadata-name-${idx}`,
                    onBlur: handleBlur,
                    onChange: (event: React.ChangeEvent<InputElementType>) => {
                      handleMetaDataChange({
                        value: event.target.value,
                        recordIdx: idx,
                        field: 'name',
                      })
                    },
                  }}
                />
              </GridCol>
              <StyledContentFieldContainer>
                <Input
                  label={METADATA_CONTENT}
                  width={"196px"}
                  inputProps={{
                    disabled,
                    value: record.content,
                    dataTestid: `metadata-content-${idx}`,
                    id: `metadata-content-${idx}`,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      handleMetaDataChange({
                        value: e.target.value,
                        recordIdx: idx,
                        field: 'content',
                      }),
                  }}
                />
              </StyledContentFieldContainer>
              <FlexGridCol>
                {!disabled && idx === 0 && (
                  <StyledAddButtonContainer>
                    <IconButton
                      icon='Add'
                      variant='secondary'
                      dataTestid='metadata-plus-icon'
                      onClick={handleAddSEOMetaData}
                    />
                  </StyledAddButtonContainer>
                )}
                {!disabled && idx > 0 && mouseHoverIndex === idx && (
                  <StyledAddButtonContainer>
                    <IconButton
                      icon='Trash'
                      variant='tertiary'
                      dataTestid={`metadata-delete-icon-${idx}`}
                      onClick={() => handleRemoveSEOMetaData(idx)}
                    />
                  </StyledAddButtonContainer>
                )}
              </FlexGridCol>
            </StyledGridRow>
          </StyledSEOMetaDataWrapper>
        )
      })}
    </>
  )
}

const SeoAndMetadataTab = ({
  formik,
  mode,
  handleClose,
  locales,
}: SeoAndMetadataProps): JSX.Element => {
  const { localizedSeoFields, locales: inputLocales } = formik.values
  const hasAssignedLocales = some(locales) && some(inputLocales)

  const [selectedLocale, setSelectedLocale] = useState(
    hasAssignedLocales
      ? getInitialSelectedLocale(inputLocales)
      : EMPTY_SELECTED_LOCALE
  )

  useDidMountEffect(() => {
    setSelectedLocale(getInitialSelectedLocale(inputLocales))
  }, [inputLocales])

  const seoFieldAction = hasAssignedLocales
    ? LocalizedSeoFieldAction({ localizedSeoFields, selectedLocale })
    : SeoFieldAction({ values: formik.values })

  const localeCode = selectedLocale.name

  return (
    <>
      <VersionDetailHeader
        {...getModeConfigurationForHeader({
          mode,
          onSubmitHandler: formik.handleSubmit,
          onCloseHandler: handleClose,
          submitDisabled: !isEmpty(formik.errors),
        })}
      />
      <form onSubmit={formik.handleSubmit}>
        <div data-testid='seo-section-content'>
          {hasAssignedLocales && (
            <StyledFormField>
              <Dropdown
                onChange={(option: any) => {
                  console.log(option)
                  setSelectedLocale(option)
                }}
                options={inputLocales.map(loc => ({
                  id: loc.id,
                  name: loc.id,
                  label: loc.id,
                }))}
                label={LOCALES.localesSeoFieldLabel}
                value={selectedLocale}
                dataTestid='seo-locales-field'
              />
            </StyledFormField>
          )}
          <StyledFormField>
            <Input
              label={SEO_TITLE}
              inputProps={{
                disabled: false,
                value: seoFieldAction.getSeoTitle().value,
                onChange: formik.handleChange,
                dataTestid: 'seo-title-field',
                id: seoFieldAction.getSeoTitle().name,
                onBlur: formik.handleBlur,
              }}
            />
            <small style={{ textAlign: 'right' }}>
              {SEO_TITLE_MAX_LENGTH - seoFieldAction.getSeoTitle().value.length}
            </small>
          </StyledFormField>
          <StyledFormField>
            <Textarea
              key={selectedLocale.id}
              label={SEO_DESCRIPTION}
              limit={DESCRIPTION_MAX_LENGTH}
              rows={TEXT_AREA_ROWS}
              inputProps={{
                value: seoFieldAction.getDescription().value,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                id: seoFieldAction.getDescription().name,
                dataTestid: 'seo-description-field',
              }}
            />
          </StyledFormField>
          <SeoMetadataList
            handleBlur={formik.handleBlur}
            seoFieldAction={seoFieldAction}
            setFieldValue={formik.setFieldValue}
            localeCode={localeCode}
            disabled={false}
          />
        </div>
      </form>
    </>
  )
}

export default SeoAndMetadataTab
