import React, { useEffect, useState } from 'react'
import { Box, Search, SearchVariant } from 'ds4-beta'
import { ContentSearchModuleProps } from './types'
import { useDebounce } from 'hooks/useDebounce'
import { DEBOUNCE_DURATION_MS } from 'src/constants'

const ContentSearchModule = ({ onSearch }: ContentSearchModuleProps) => {
  if (!onSearch) return null

  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, DEBOUNCE_DURATION_MS)

  useEffect(() => {
    onSearch(debouncedQuery)
  }, [debouncedQuery])

  return (
    <Box>
      <Search
        id='search-field'
        dataTestid='search-field'
        variant={SearchVariant.PAGE}
        onChange={setQuery}
        onClear={() => setQuery('')}
        controlled={{ open: false }}
      />
    </Box>
  )
}

export default ContentSearchModule
