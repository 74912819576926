import 'styles/global.css'
import 'tippy.js/dist/tippy.css'
import {
  BASEPATH,
  NEW_NAV_BASEPATH,
  NavigationLinksProvider,
} from 'contexts/navigationLinksContext'
import { CopilotUIProvider } from 'ds4-beta'
import React, { useCallback, useEffect, useState } from 'react'
import { addNewCookie, getAccountId } from 'lib/cookieUtils'
import { isUnleashEnvSetup, unleashConfig } from 'src/business-layer-client'
import { ApolloProvider } from '@apollo/client'
import { CONFIG_RESET } from 'store/configuration/actions'
import DS3App from './DS3App'
import { FF_NAMES } from './constants'
import FlagProvider from '@unleash/proxy-client-react'
import { Router } from '@reach/router'
import { ThemeProvider } from 'styled-components'
import { UserDataContext } from 'contexts/userDataContext'
import { UserPermissionsProvider } from 'src/contexts/userPermissions'
import ViewWithSideBar from 'src/ds4/pages/viewWithSideBar'
import { XM_VIEWER_ROLE } from './rbac-constants'
import client from './src/graphql-client'
import { documentScriptConfig } from 'src/config'
import { enableMapSet } from 'immer'
import { rbac } from '@teamfabric/copilot-utilities'
import store from 'store'
import { unleashClient } from './src/business-layer-client'
import ds3theme from 'styles/theme'
import { GlobalStyle as DS3GlobalStyle } from '@teamfabric/copilot-ui'

const DS4App = () => {
  const [userData, setUserData] = useState({})
  const [noXmAccess, setNoXmAccess] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [featureFlags, setFeatureFlags] = useState({
    i18n: false,
    rbac: false,
    navNameChange: false,
    ds4: false,
  })

  enableMapSet()

  const updateFeatureFlagValues = useCallback(() => {
    setFeatureFlags({
      i18n: unleashClient.isEnabled(FF_NAMES.unleashFFs.I18N),
      rbac: unleashClient.isEnabled(FF_NAMES.unleashFFs.USE_RBAC),
      navNameChange: unleashClient.isEnabled(
        FF_NAMES.unleashFFs.NAVIGATION_NAME_CHANGE
      ),
      ds4: unleashClient.isEnabled(FF_NAMES.unleashFFs.DS4),
    })
  }, [setFeatureFlags])

  useEffect(() => {
    const redirectToNewRoute =
      featureFlags.navNameChange && window.location.pathname.includes('xpm')
    if (redirectToNewRoute) {
      window.location.replace(
        window.location.origin +
          window.location.pathname.replace(BASEPATH, NEW_NAV_BASEPATH)
      )
    }
  }, [featureFlags.navNameChange, window.location.pathname])

  useEffect(() => {
    const onReady = () => {
      updateFeatureFlagValues()
      setIsLoading(false)
    }

    unleashClient.on('ready', onReady)
    unleashClient.on('update', onReady)

    unleashClient.on('error', () => {
      console.error('Error with unleash client')
      setIsLoading(false)
    })

    unleashClient.updateContext({ userId: getAccountId() })
    unleashClient.start().then(() => {
      onReady()
    })

    return () => {
      unleashClient.stop()
    }
  }, [updateFeatureFlagValues])

  useEffect(() => {
    addNewCookie('XM_UI_VERSION', documentScriptConfig.UI_VERSION)

    if (!isUnleashEnvSetup) {
      console.error('Error retrieving unleash environment variables')
    }

    return () => {
      setUserData({})
      store.dispatch({ type: CONFIG_RESET })
    }
  }, [featureFlags.i18n])

  useEffect(() => {
    const { hasAccess: canViewRole } = rbac.checkPermission({
      allowedPermissions: XM_VIEWER_ROLE,
    })

    setNoXmAccess(!canViewRole && featureFlags.rbac)
  }, [featureFlags.rbac])

  if (isLoading) {
    return <div> Loading </div>
  }

  if (noXmAccess) {
    return <div>No Access</div>
  }

  if (featureFlags?.ds4) {
    return (
      <FlagProvider config={unleashConfig}>
        <UserPermissionsProvider>
          <NavigationLinksProvider>
            <ApolloProvider client={client}>
              <CopilotUIProvider>
                <DS3GlobalStyle />
                <ThemeProvider theme={ds3theme}>
                  {/* <ThemeProvider theme={{ theme }}> */}
                  <UserDataContext.Provider value={userData}>
                    <Routes
                      i18n={featureFlags.i18n}
                      navNameChange={featureFlags.navNameChange}
                    />
                  </UserDataContext.Provider>
                  {/* </ThemeProvider> */}
                </ThemeProvider>
              </CopilotUIProvider>
            </ApolloProvider>
          </NavigationLinksProvider>
        </UserPermissionsProvider>
      </FlagProvider>
    )
  }

  return <DS3App />
}

const Routes = ({ i18n, navNameChange }) => {
  return (
    <Router basepath={NEW_NAV_BASEPATH}>
      <ViewWithSideBar path='*' />
    </Router>
  )
}

export default DS4App
