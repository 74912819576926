import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StyledWrapper } from 'components/type-components/styles'
import { Textarea } from 'ds4-beta'
import { VALUE_PROP_DEFAULTS } from '../constants'
import { getActiveLanguage } from 'store/i18n/selectors'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'

const MultiLineStringType = props => {
  const { descriptor, paths, value, onChange, hasEditAccess } = props
  const activeLang = useSelector(getActiveLanguage)

  const handleOnChange = useCallback(
    e => {
      onChange({ paths, value: e.target.value })
    },
    [paths, onChange]
  )

  const name = useMemo(() => paths.join('.'), [paths])

  if (!Object.keys(descriptor).length) return null

  return (
    <StyledWrapper data-testid='multi-line-string-type-wrapper'>
      <Textarea
        inputProps={{
          value: value,
          onChange: handleOnChange,
          dataTestid: 'multi-line-string-type-textarea',
        }}
        label={descriptor.label}
        disabled={!hasEditAccess}
        // localeCode={activeLang}
      />
    </StyledWrapper>
  )
}

MultiLineStringType.defaultProps = {
  descriptor: {},
  value: VALUE_PROP_DEFAULTS.MultiLineString,
}

MultiLineStringType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
}

export default memo(MultiLineStringType, isEqual)
