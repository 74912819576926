import styled from 'styled-components'
import { theme } from 'ds4-beta'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const getButtonTypeBackground = buttonType =>
  buttonType === 'text-only'
    ? 'transparent'
    : themeUi.palette.brand.primary.white


export const StyledDtContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 6px 0;
  position: relative;
`

export const StyledDtLabel = styled.div`
  ${props => props.theme.typography.labelSmall};
  font-family: ${props => props.theme.typography.fontFamily.sans};
  color: ${themeUi.palette.brand.primary.gray};
  margin-bottom: 8px;
`

export const StyledComponentButton = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${({ buttonType }) => getButtonTypeBackground(buttonType)};
  position: relative;
  cursor: pointer;
  border: 1px solid
    ${({ buttonType }) =>
      buttonType === 'text-only'
        ? 'transparent'
        : themeUi.palette.ui.neutral.grey4};
`

export const StyledButton = styled.div`
  ${props => props.theme.typography.label};
  background-color: ${({ buttonType }) => getButtonTypeBackground(buttonType)};
  color: ${({ buttonType }) =>
    buttonType === 'text-only'
      ? '#58687a'
      : themeUi.palette.brand.primary.charcoal};
  font-size: ${props =>
    props.buttonType === 'text-only'
      ? '12px'
      : props.theme.typography.fontSize.sm};
  font-weight: ${props => props.theme.typography.fontWeight.semibold};
  font-family: ${props => props.theme.typography.fontFamily.sans};
  line-height: 16px;
  letter-spacing: 0.2px;
  padding-left: 0.5rem;
`

export const StyledPlusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const StyledWrapper = styled.div`
  display: block;
  width: 100%;
  margin-bottom: ${theme.space[4]};
`
export const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  input: focus{ 
    border:0;
    outline: none;
  }
`

export const StyledErrorMessage = styled.div`
  ${theme.typography.body1.regular}
  color: ${theme.color.red[300]};
  padding: 8px;
`
export const StyledLabel = styled.div``
