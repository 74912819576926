import styled from 'styled-components'

export const StyledWrapper = styled.div``
export const StyledCheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  min-width: 220px;
`
export const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  margin-left: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`
