import styled from 'styled-components'
import { theme } from 'ds4-beta'

export const StyledUpdatedAtWrapper = styled.div`
  ${theme.typography.body2.regular}
`
export const StyledFilterContainer = styled.div`
  padding-right: 10px;
`
export const StyledContentEditorCTAContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }
`

export const StyledDropdownContent = styled.ul`
  position: absolute;
  top: 40px;
  right: 0;
  width: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid ${theme.color.grey[300]};
  border-radius: 4px;
  background: ${theme.color.grey[0]};
`

export const StyledDropdownItem = styled.li`
  padding: 0.8rem 1.2rem;
  ${theme.typography.body1.regular}
  cursor: pointer;
`

export const StyledButtonWithIconWrapper = styled.div<{
  isContentLive?: boolean
}>`
  position: relative;
  button {
    div {
      margin-right: ${({ isContentLive }) =>
        !isContentLive && `${theme.space[4]}`};
    }
  }

  svg {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
`
