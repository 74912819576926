import { HandleUploadProps } from '../types'
import { Modal } from 'ds4-beta'
import React from 'react'
import { SETTINGS } from 'src/constants'
import { handleUpload } from '../utils'

interface ConfirmUploadProps extends HandleUploadProps {
  handleModalClose: () => void
}

const ConfirmUploadModal = ({
  handleModalClose,
  storedDescriptors,
  descriptorsToUpdate,
  descriptorsToCreate,
  createUniversalDescriptor,
  updateUniversalDescriptor,
}: ConfirmUploadProps): JSX.Element => {
  return (
    <Modal
      onBackdropClick={handleModalClose}
      onClose={handleModalClose}
      isVisible={true}
      headerText={SETTINGS.CONFIRM_UPLOAD_DIALOG.HEADING}
      dataTestid='modal'
      footerButtons={[
        {
          onClick: handleModalClose,
          text: SETTINGS.CONFIRM_UPLOAD_DIALOG.CANCEL,
          variant: 'secondary',
        },
        {
          onClick: () => {
            handleModalClose()
            void handleUpload({
              storedDescriptors,
              descriptorsToCreate,
              descriptorsToUpdate,
              createUniversalDescriptor,
              updateUniversalDescriptor,
            })
          },
          text: SETTINGS.CONFIRM_UPLOAD_DIALOG.SUBMIT,
          variant: 'primary',
        },
      ]}
      size='small'
    >
      <>
        <p>
          <strong>
            {storedDescriptors.length - descriptorsToUpdate.length}
          </strong>{' '}
          {SETTINGS.CONFIRM_UPLOAD_DIALOG.TO_UPLOAD}
        </p>
        <p>
          <strong>{descriptorsToUpdate.length}</strong>{' '}
          {SETTINGS.CONFIRM_UPLOAD_DIALOG.TO_UPDATE}
        </p>
      </>
    </Modal>
  )
}

export default ConfirmUploadModal
