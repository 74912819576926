import styled, { css } from 'styled-components'
import { theme } from 'ds4-beta'
interface StyledInputFieldProps {
  isDisabled: boolean
}

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: ${theme.space[4]};

  & > div {
    border: 1px solid ${theme.color.grey[200]};
    padding: ${theme.space[4]};
  }
`

export const StyledInputField = styled.div<StyledInputFieldProps>`  
  .textarea_parent > div,
  .input__wrapper {
    border: ${({ isDisabled }) => isDisabled && `none !important;`};
  }
  
   label, textarea{
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        color: ${theme.color.grey[400]};
    }
`}
`

export const StyledHeader = styled.p`
  ${theme.typography.h5}
  margin-top: 0;
  margin-bottom: ${theme.space[4]};
`
