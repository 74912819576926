import React, { useState } from 'react'
import { Modal, Box, Input, ButtonProps } from 'ds4-beta'
import { AddSpecialNodeModalProps } from './types'
import { LABELS, PLACEHOLDERS } from './constants'
import { NODE_TYPE } from '../../types'

const AddNodeModal = ({
  onCancel: onClose,
  show,
  onYesClicked,
  isLoading,
  type,
}: AddSpecialNodeModalProps) => {
  const [label, setLabel] = useState('')

  const handleKeyPress = e => {
    if (e.key === 'Enter' && !isLoading) {
      onYesClicked?.(label, () => setLabel(''))
    }
  }

  const footerButtons = [
    {
      onClick: onClose,
      text: 'Cancel',
      variant: 'tertiary',
    },
    {
      onClick: () => onYesClicked?.(label, () => setLabel('')),
      text: type === NODE_TYPE.NODE ? LABELS.ADD_NODE : '',
      icon: 'Add',
      variant: 'primary',
      isDisabled: !label,
      isLoading,
    },
  ] as ButtonProps[]

  return (
    <Modal
      size='small'
      isVisible={show}
      dataTestid='add-node-modal'
      headerText={LABELS.HEADER_NODE}
      footerButtons={footerButtons}
      onBackdropClick={onClose}
      onClose={onClose}
    >
      <Box>
        <Input
          label={type === NODE_TYPE.NODE ? LABELS.INPUT_NODE_LABEL : ''}
          inputProps={{
            value: label,
            onChange: e => setLabel(e.target.value),
            onKeyPress: e => handleKeyPress(e),
            placeholder: type === NODE_TYPE.NODE ? PLACEHOLDERS.NODE : '',
          }}
          required
        />
      </Box>
    </Modal>
  )
}

export default AddNodeModal
