import { Box, InlineBanner, Modal } from 'ds4-beta'
import React from 'react'
import { SETTINGS } from 'src/constants'
import { StyledSummary } from './styles'
interface DeleteProps {
  handleModalClose: () => void
  handleDelete: () => Promise<void>
  title?: string
}

const ConfirmDeleteModal = ({
  handleModalClose,
  handleDelete,
  title,
}: DeleteProps): JSX.Element => {
  return (
    <Modal
      onBackdropClick={handleModalClose}
      onClose={handleModalClose}
      headerText={`Are you sure you want to delete ${title}? `}
      isVisible={true}
      dataTestid='modal'
      footerButtons={[
        {
          onClick: handleModalClose,
          text: SETTINGS.CONFIRM_DELETE_DIALOG.CANCEL,
          dataTestid: 'delete-descritpor-cancel',
          variant: 'tertiary_dark',
        },
        {
          onClick: () => {
            handleModalClose()
            void handleDelete()
          },
          text: SETTINGS.CONFIRM_DELETE_DIALOG.SUBMIT,
          variant: 'destructive',
          dataTestid: 'delete-descritpor-button',
        },
      ]}
      size='small'
    >
      <>
        <Box margin={{ bottom: 4 }}>
          <InlineBanner
            label='You can’t take back this action.'
            variant='error'
          ></InlineBanner>
        </Box>
        <StyledSummary>{`Are you sure you want to delete ${title}? You will not be able to restore it.`}</StyledSummary>
      </>
    </Modal>
  )
}

export default ConfirmDeleteModal
