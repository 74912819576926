import { Box, Modal, Tab, TabItem } from 'ds4-beta'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line sonarjs/cognitive-complexity
const ModalSections = ({ isVisible, onClose, ...props }) => {
  const { modalSections, title, selectedSection } = props

  const [modalSection, setModalSection] = useState(
    selectedSection || Object.keys(modalSections)[0]
  )
  useEffect(() => {
    setModalSection(selectedSection)
  }, [selectedSection])

  const renderModalContent = () => {
    return (
      <>
        <Tab>
          {Object.values(modalSections).map(section => {
            return (
              <TabItem label={section.title} key={section.key}>
                <Box padding={{ top: 4 }}>
                  {Object.values(modalSections).map(section => (
                    <div
                      key={section.key}
                      style={{
                        display:
                          section.key === modalSection ? 'initial' : 'none',
                      }}
                    >
                      {section.render()}
                    </div>
                  ))}
                </Box>
              </TabItem>
            )
          })}
        </Tab>
      </>
    )
  }
  return (
    <Modal
      isVisible={isVisible}
      onBackdropClick={onClose}
      onClose={onClose}
      headerText={title}
      footerButtons={[]}
      size='small'
    >
      {renderModalContent()}
    </Modal>
  )
}

ModalSections.defaultProps = {
  title: 'Modal',
  selectedSection: null,
}

ModalSections.propTypes = {
  title: PropTypes.string.isRequired,
  selectedSection: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  modalSections: PropTypes.shape({
    'general-details': PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      render: PropTypes.func,
    }),
  }),
}

export default ModalSections
