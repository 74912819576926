import React from 'react'
import { CANCEL_TEXT, SAVE_TEXT } from 'src/constants'
import { ApplyAttributeViewProps } from './types'
import { Box, Button, Input } from 'ds4-beta'

const AttributeForm: React.FC<ApplyAttributeViewProps> = ({
  onApply,
  onCancel,
  attribute,
  setAttribute,
  applyCTADisabled,
}) => (
  <Box flex={{ flexDirection: 'column' }} gap={4}>
    <Input
      label='Parameter'
      required
      inputProps={{
        dataTestid: 'attribute-parameter-input',
        value: attribute?.kind || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setAttribute({
            ...attribute,
            kind: e?.target?.value,
          })
        },
      }}
    />
    <Input
      label='Value'
      required
      inputProps={{
        dataTestid: 'attribute-value-input',
        value: attribute?.value || '',
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setAttribute({
            ...attribute,
            value: e?.target?.value,
          })
        },
      }}
    />
    <Box flex={{ flexDirection: 'row' }} gap={2}>
      <Button
        variant='secondary'
        onClick={onCancel}
        data-testid='add-parameter-cancel'
        text={CANCEL_TEXT}
      />
      <Button
        variant='primary'
        onClick={onApply}
        text={SAVE_TEXT}
        data-testid='add-parameter-apply'
        isDisabled={applyCTADisabled}
      />
    </Box>
  </Box>
)

export default AttributeForm
