import React, { useCallback, useMemo, useState } from 'react'
import {
  StyledAttributeItemLabelWrapper,
  StyledItemTypeComponent,
  StyledItemTypeExpandView,
  StyledList,
  StyledWrapper,
} from './styles'
import AnyType from 'src/ds4/components/type-components/AnyType'
import AttributeItemLabel from './AttributeItemLabel'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { splitAttributesByTypes } from './utils'

const AttributeList = props => {
  const { selectedComponent, descriptor } = props
  const [expandedProperty, setExpandedProperty] = useState('')

  const splitAttributes = useMemo(
    () => splitAttributesByTypes(descriptor?.attributes),
    [descriptor?.attributes]
  )

  // get attribute of array type and non array type
  const [arrayTypeAttributeKeys, headingAttributeKeys] = splitAttributes

  const onClickItem = useCallback(
    key => {
      if (expandedProperty === key) {
        setExpandedProperty('') // unset expanded property
      } else {
        setExpandedProperty(key)
      }
    },
    [expandedProperty]
  )

  const headingLabel = 'Heading'
  const isHeadingExpanded = expandedProperty === '__HEADING__'

  return (
    <StyledWrapper>
      <StyledList className='sidebar-attribute-list'>
        {Array.isArray(headingAttributeKeys) &&
          headingAttributeKeys.length > 0 && (
            <StyledAttributeItemLabelWrapper>
              <AttributeItemLabel
                isExpanded={isHeadingExpanded}
                label={headingLabel}
                handleOnLabelClick={() => onClickItem('__HEADING__')}
                shouldApplyBorderBottomZero={
                  arrayTypeAttributeKeys.length !== 0
                }
              />
              {isHeadingExpanded && (
                <StyledItemTypeExpandView data-testid='item-type-expand-view'>
                  {Object.keys(selectedComponent.params)
                    .filter(
                      key =>
                        !arrayTypeAttributeKeys.includes(key) &&
                        key !== 'components'
                    )
                    .map(key => {
                      return (
                        <StyledItemTypeComponent key={key}>
                          <AnyType
                            type={
                              descriptor?.attributes?.[key]?.type ?? 'String'
                            }
                            descriptor={descriptor?.attributes[key] ?? {}}
                            paths={props.paths?.concat('params', key)}
                            value={props.value?.params?.[key]}
                            onChange={props.onChange}
                          />
                        </StyledItemTypeComponent>
                      )
                    })}
                </StyledItemTypeExpandView>
              )}
            </StyledAttributeItemLabelWrapper>
          )}
        {Array.isArray(selectedComponent.params.components) &&
          selectedComponent.params.components.map((component, idx) => {
            const componentDescriptor = props.descriptors?.find(
              element => element.id === component.id
            )

            return (
              <AnyType
                key={component.key}
                type={componentDescriptor.type}
                descriptor={componentDescriptor}
                paths={props.paths?.concat(
                  'params',
                  'components',
                  idx,
                  'params'
                )}
                value={props.value?.params?.components?.[idx]?.params}
                onChange={props.onChange}
              />
            )
          })}
        {Object.keys(selectedComponent.params)
          .filter(key => arrayTypeAttributeKeys.includes(key))
          .map(key => {
            const type = descriptor?.attributes?.[key]?.type ?? 'String'

            return (
              <AnyType
                key={key}
                type={type}
                level={1}
                descriptor={descriptor?.attributes[key] ?? {}}
                expandedProperty={expandedProperty}
                setExpandedProperty={setExpandedProperty}
                paths={props.paths?.concat('params', key)}
                value={props.value?.params[key]}
                onChange={props.onChange}
              />
            )
          })}
      </StyledList>
    </StyledWrapper>
  )
}

AttributeList.defaultProps = {
  onChange: noop,
}

AttributeList.propTypes = {
  selectedComponent: PropTypes.object.isRequired,
  descriptor: PropTypes.object.isRequired,
  descriptors: PropTypes.array.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AttributeList
