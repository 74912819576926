import React from 'react'
import { ADD_LOCALIZED_TITLE, MANAGE_NODE_SIDEBAR_LABEL } from 'src/constants'
import { FormikProps } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { Card, Box, theme } from 'ds4-beta'
import { FORM_FIELD_NAMES, ManageNodeFormValues } from './types'
import { CommonInputFieldProps } from 'src/ds4/components/FormDialog/common/versions/VersionDetailsDialog/types'
import { DEFAULT_LOCALE } from 'src/ds4/modules/browse-menu-tree-gql/constants'
import { getFormComponents } from 'src/ds4/components/ContentModalModule/utils'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'
import { StyledFieldLabel, StyledInputReadOnly } from './styles'
const { LINK, ROOT_NODE, NODE_TITLE } = MANAGE_NODE_SIDEBAR_LABEL

type NodeInformationSectionTypes = {
  title: string
  locales: Array<string>
  selectedLocale: string
}
const NodeInformationSection: React.FC<
  FormikProps<ManageNodeFormValues> & NodeInformationSectionTypes
> = props => {
  const { values, title, locales, selectedLocale } = props
  const isLocalizationEnabled = !isEmpty(locales)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const formInputFields: Array<CommonInputFieldProps> = [
    {
      type: 'Input',
      label: LINK,
      value: values[FORM_FIELD_NAMES.url],
      dataTestId: FORM_FIELD_NAMES.url,
      name: FORM_FIELD_NAMES.url,
      disabled: !hasEditAccess,
      width: '100%',
      required: false,
    },
  ]

  if (!isLocalizationEnabled) {
    formInputFields.unshift({
      type: 'Input',
      label: NODE_TITLE,
      value: values[FORM_FIELD_NAMES.nodeName][DEFAULT_LOCALE],
      dataTestId: `${DEFAULT_LOCALE}-node-name-input`,
      name: `${FORM_FIELD_NAMES.nodeName}.${DEFAULT_LOCALE}`,
      disabled: !hasEditAccess,
      width: '100%',
      required: false,
    })
  }

  formInputFields.unshift({
    type: 'Input',
    label: title,
    value: values[FORM_FIELD_NAMES.rootNodeName][selectedLocale],
    dataTestId: FORM_FIELD_NAMES.rootNodeName,
    name: FORM_FIELD_NAMES.rootNodeName,
    width: '100%',
    disabled: true,
    required: false,
  })

  return (
    <Box
      flex={{ flexDirection: 'column' }}
      gap={4}
      padding={{ top: 5 }}
      border={{
        width: 1,
        color: theme.color.grey[100],
        sides: ['top'],
      }}
    >
      <Box gap={1} flex={{ flexDirection: 'column' }}>
        <StyledFieldLabel>{ROOT_NODE}</StyledFieldLabel>
        <StyledInputReadOnly>
          {values[FORM_FIELD_NAMES.rootNodeName][selectedLocale]}
        </StyledInputReadOnly>
      </Box>
      {isLocalizationEnabled && (
        <Card
          header={ADD_LOCALIZED_TITLE}
          body={
            <>
              {getFormComponents({
                components: locales?.map(locale => ({
                  type: 'Input',
                  label: NODE_TITLE,
                  value: values[FORM_FIELD_NAMES.nodeName][locale],
                  dataTestId: `${locale}-node-name-input`,
                  name: `${FORM_FIELD_NAMES.nodeName}.${locale}`,
                  disabled: !hasEditAccess,
                  locale: locale,
                  localeCode: locale,
                  required: false,
                })),
                mode: null,
                ...props,
              })}
            </>
          }
        />
      )}
      {getFormComponents({
        components: formInputFields,
        mode: null,
        ...props,
      })}
    </Box>
  )
}

export default NodeInformationSection
