import { Badge, Box, Tooltip } from 'ds4-beta'
import { noop, size } from 'lodash'
import EditorOptions from './EditorOptions'
import PropTypes from 'prop-types'
import React from 'react'
import { getBadgeStatusForVersion } from './utils'

const EditorPageTitle = ({
  versionStatus,
  versionName,
  selectedRoute,
  onEditorOptionClick,
}) => {
  // loading completed when selectedRoute has been populated
  const isEditorLoading = !size(selectedRoute)

  return (
    <Box>
      <Box
        flex={{
          alignItems: 'center',
          justifyContent: 'start',
        }}
        gap={2}
        margin={{ bottom: 2 }}
      >
        {!isEditorLoading && versionName && (
          <Box>
            <Tooltip id='version-name' text={versionName}>
              <Box as='span'>{versionName}</Box>
            </Tooltip>
          </Box>
        )}
        {!isEditorLoading && (
          <Box
            as='span'
            className='editorOptions'
            data-testid='sidebar-components-editor-options'
          >
            <EditorOptions onEditorOptionClick={onEditorOptionClick} />
          </Box>
        )}
      </Box>
      {versionStatus && (
        <Badge
          label={versionStatus.toLowerCase()}
          variant='primary'
          status={getBadgeStatusForVersion(versionStatus)}
          asset='none'
          data-testid='version-status'
        />
      )}
    </Box>
  )
}

EditorPageTitle.defaultProps = {
  versionStatus: '',
  versionName: '',
  selectedRoute: {},
  onEditorOptionClick: noop,
  isGCEditorPagePath: false,
}

EditorPageTitle.propTypes = {
  versionStatus: PropTypes.string,
  versionName: PropTypes.string,
  selectedRoute: PropTypes.object.isRequired,
  onEditorOptionClick: PropTypes.func.isRequired,
  isGCEditorPagePath: PropTypes.bool.isRequired,
}

export default EditorPageTitle
