import { DEFAULT_TOAST_DURATION, TOAST_MESSAGE_TYPES } from 'src/constants'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import theme from 'styles/theme'
import { useToast } from 'ds4-beta'

const ToastSnackbar = props => {
  const { isDismissable, visible, onDismiss, message, kind, toastId } = props
  const showToast = useToast()

  useEffect(() => {
    if (visible) {
      showToast({
        label: message,
        isDismissable,
        variant: kind === TOAST_MESSAGE_TYPES.ALERT ? 'alert' : 'default',
        id: toastId,
        onDismiss,
      })
    }
  }, [visible, message, isDismissable, kind, toastId, onDismiss, showToast])

  useEffect(() => {
    const toast = document.getElementById(toastId)
    const timeoutId = setTimeout(() => {
      toast?.remove()
      clearTimeout(timeoutId)
    }, DEFAULT_TOAST_DURATION)

    if (kind === TOAST_MESSAGE_TYPES.ALERT) {
      console.error(message)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [toastId, kind, message])

  return null
}

ToastSnackbar.propTypes = {
  isDismissable: PropTypes.bool,
  isFloating: PropTypes.bool,
  isHighEmphasis: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  message: PropTypes.string.isRequired,
  position: PropTypes.string,
  width: PropTypes.string,
  kind: PropTypes.string.isRequired,
  toastId: PropTypes.string.isRequired,
}

ToastSnackbar.defaultProps = {
  visible: false,
  isDismissable: true,
  onDismiss: noop,
  isFloating: true,
  isHighEmphasis: true,
  width: theme.snackbar.width,
  position: 'top-center',
  kind: 'message',
  toastId: '',
}

export default ToastSnackbar
