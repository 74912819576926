import AllComponentsMode from 'src/ds4/modules/settings/utils/AllComponentsMode'
import ComponentViewMode from 'src/ds4/modules/settings/utils/ComponentViewMode'
import { DescriptorProps } from 'store/types'
import { SETTINGS } from 'src/constants'
import { isValidComponentId } from '../descriptor-details/utils'

class AddComponentMode extends ComponentViewMode {
  retrieveId() {
    return null
  }

  retrieveOnNavigateBack(setMode: (mode: ComponentViewMode) => void) {
    return () => {
      setMode(AllComponentsMode)
    }
  }

  isEditMode() {
    return true
  }

  showDescriptorLabelError(
    descriptorLabelLength: number,
    labelChanged: boolean
  ) {
    return labelChanged && !descriptorLabelLength
  }

  retrieveHeader() {
    return SETTINGS.HEADER.ADD_COMPONENT
  }

  setModeAfterSave(setMode: (mode: ComponentViewMode) => void) {
    setMode(AllComponentsMode)
  }

  showDescriptorIdError(id: string, idChanged: boolean) {
    return id?.length ? !isValidComponentId(id) : idChanged && id.length === 0
  }

  shouldHideSubmitButton(
    singleDescriptor: DescriptorProps,
    idChanged: boolean
  ) {
    return (
      singleDescriptor?.label.trim() === '' ||
      singleDescriptor?.id === '' ||
      this.showDescriptorIdError(singleDescriptor?.id, idChanged)
    )
  }

  shouldShowDeleteButton() {
    return false
  }
}

export default new AddComponentMode()
