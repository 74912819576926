import Spinner from 'components/Spinner'
import styled from 'styled-components'

export const StyledSpinner = styled(Spinner)`
  position: fixed;
  left: 50%;
  top: 50%;
`

export const StyledContainer = styled.div`
  position: relative;
`

export const StyledTreeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;
  box-sizing: content-box;
`
