import React, { useRef } from 'react'
import {
  MANAGE_NODE_SIDEBAR_LABEL,
  CANCEL_TEXT,
  OK,
  SAVE_TEXT,
  VIEW_DETAILS_LABEL,
} from 'src/constants'
import {
  FORM_FIELD_NAMES,
  ManageNodeFormValues,
  ManageNodeViewProps,
} from './types'
import AssetSection from './AssetSection'
import AttributesSection from './AttributesSection'
import { Formik } from 'formik'
import NodeInformationSection from './NodeInformationSection'
import { Box, Drawer, ButtonProps } from 'ds4-beta'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const validateManageNodeValues = (
  values: ManageNodeFormValues
): Record<string, string> => {
  const errors = {}
  const urlValue = values[FORM_FIELD_NAMES.url]
  if (urlValue) {
    const invalidURLCharsRegex = /[\[\]{}\|\\"%~#<>]/g
    if (urlValue.includes(' ')) {
      errors[FORM_FIELD_NAMES.url] = MANAGE_NODE_SIDEBAR_LABEL.ERRORS.NO_SPACES
    } else if (urlValue.match(invalidURLCharsRegex)) {
      errors[FORM_FIELD_NAMES.url] =
        MANAGE_NODE_SIDEBAR_LABEL.ERRORS.INVALID_CHARS
    }
  }
  return errors
}

const ManageNodeView: React.FC<ManageNodeViewProps> = ({
  node,
  visible = false,
  onSave,
  onCancel,
  locales,
  selectedLocale,
}) => {
  const formikRef = useRef(null)
  const itemCategory = node?.section ? 'Section' : 'Node'
  const title = MANAGE_NODE_SIDEBAR_LABEL.getTitle(itemCategory?.toLowerCase())
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const headerTitle = hasEditAccess ? title : VIEW_DETAILS_LABEL
  const closeBtnText = hasEditAccess ? CANCEL_TEXT : OK

  const headerButtons = [
    {
      dataTestid: 'drawer-cancel-btn',
      onClick: onCancel,
      text: closeBtnText,
      variant: 'tertiary',
    },
  ] as ButtonProps[]

  if (hasEditAccess) {
    headerButtons.push({
      dataTestid: 'drawer-save-btn',
      text: SAVE_TEXT,
      variant: 'primary',
      type: 'submit',
      onClick: formikRef?.current?.submitForm,
      isLoading: formikRef?.current?.isSubmitting,
    })
  }

  return (
    <Drawer
      title={headerTitle}
      headerButtons={headerButtons}
      dataTestId='manage-node-drawer'
      isVisible={visible}
      onBackdropClick={onCancel}
      onEscapeKeyPress={onCancel}
      backdrop
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          [FORM_FIELD_NAMES.url]: node?.url,
          [FORM_FIELD_NAMES.rootNodeName]: node?.rootNodeName,
          [FORM_FIELD_NAMES.nodeName]: node?.name,
          [FORM_FIELD_NAMES.attributes]: node?.attributes,
          [FORM_FIELD_NAMES.images]: node?.images,
          [FORM_FIELD_NAMES.nodeId]: node?._id,
        }}
        onSubmit={onSave}
        validate={validateManageNodeValues}
      >
        {formikProps => {
          const { handleSubmit } = formikProps
          return (
            <form onSubmit={handleSubmit}>
              <Box flex={{ flexDirection: 'column' }} gap={5}>
                <NodeInformationSection
                  title={`${itemCategory} selected`}
                  locales={locales}
                  selectedLocale={selectedLocale}
                  {...formikProps}
                />
                <AssetSection />
                <AttributesSection {...formikProps} />
              </Box>
            </form>
          )
        }}
      </Formik>
    </Drawer>
  )
}

export default ManageNodeView
