import {
  Counter,
  HandleUploadProps,
  InvalidAttributesProps,
  InvalidContextProps,
  MutationType,
  OnFileInputChangeProps,
  ReadDescriptorProps,
  ToCreateAndToUpdateType,
} from './types'
import {
  MAX_FILE_COUNT,
  MESSAGES,
  SETTINGS,
  TOAST_MESSAGE_TYPES,
  TOAST_REMOVAL_TIMING,
  VALIDATION_ERRORS,
} from 'src/constants'
import { DescriptorProps } from 'store/types'
import { JsonValue } from 'src/types'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import { isValidUploadType } from 'lib/fileTypes'
import { removeToast } from 'components/ToastSnackbarContainer/utils'
import { showToast } from 'components/ToastSnackbarContainer'
import size from 'lodash/size'

const MAX_ALLOWED_DEPTH = 8

// Called by File API, it takes file(s) from user
// then checks file count and type
// If the file satisfies those checks, passes it to readDescriptor
export const onFileInputChange = (props: OnFileInputChangeProps): void => {
  const { file, setStoredDescriptors, setInvalidDataContext } = props
  if (file.length > MAX_FILE_COUNT) {
    void showToast({
      message: VALIDATION_ERRORS.UPLOAD_TOO_MANY_FILES,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
    return null
  }
  if (
    file[0] &&
    isValidUploadType(file[0], SETTINGS.HEADER.ACCEPT_FILE_TYPES)
  ) {
    readDescriptor({
      descriptorFile: file[0],
      setStoredDescriptors,
      setInvalidDataContext,
    })
  } else {
    // Case: not json file
    void showToast({
      message: VALIDATION_ERRORS.INVALID_JSON,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

// Reads given file then parse its content into object if it satisfies JSON format
// then saves the object to a state setter
const readDescriptor = (props: ReadDescriptorProps): void => {
  const { descriptorFile, setStoredDescriptors, setInvalidDataContext } = props
  const reader = new FileReader()
  reader.readAsText(descriptorFile)
  reader.onload = event => {
    try {
      const parsed = JSON.parse(
        event.target.result as string
      ) as DescriptorProps[]

      if (size(parsed)) {
        setStoredDescriptors(parsed)
      } else {
        // Case: empty array/object
        const ctx = getUploadErrorContext(SETTINGS.UPLOAD_ERROR.FORMAT.KIND)
        setInvalidDataContext(ctx)
      }
    } catch (err) {
      // Case: failed to parse json
      console.error(err)
      const ctx = getUploadErrorContext(SETTINGS.UPLOAD_ERROR.FORMAT.KIND)
      setInvalidDataContext(ctx)
    }
  }
}

// Makes request for create or update then returns success/error counts
export const handleMutation = async (
  data: DescriptorProps[],
  mutate: MutationType
): Promise<Counter> => {
  const counter = {
    success: 0,
    error: 0,
  }
  if (size(data)) {
    for (const descriptor of data) {
      try {
        await mutate({
          variables: { input: descriptor },
        })
        counter.success++
      } catch (err) {
        console.error(err)
        counter.error++
      }
    }
  }
  return counter
}

// Renders toast message using data passed
// Runs only when necessary by checking the success/error count
export const handleMessage = (
  count: number,
  message: { msgGetter: (c: number) => string; kind: string }
): void => {
  if (count >= 1) {
    void showToast({
      message: message.msgGetter(count),
      kind: message.kind,
    })
  }
}

// Compares descriptors from database against descriptors from user input
// then create a list of indices of duplicate descriptors
// then returns the list
const getIndicesOfDuplicates = (dataFromDb, dataFromUser): number[] => {
  return dataFromDb
    .reduce((acc, itemFromDb) => {
      const index = dataFromUser.findIndex(itemFromUser => {
        return itemFromUser.id === itemFromDb.id
      }) as number
      acc.push(index)
      return acc
    }, [])
    .filter(item => item >= 0)
}

// Creates two lists of descriptors for create and update
// by using the list of indices created by getIndicesOfDuplicates
// then returns the lists
const updateList = (
  dups: number[],
  dataFromUser: DescriptorProps[],
  { toUpdate, toCreate }: ToCreateAndToUpdateType
): ToCreateAndToUpdateType => {
  for (let i = 0; i < dataFromUser.length; i++) {
    if (dups.includes(i)) {
      toUpdate.push(dataFromUser[i])
    } else {
      toCreate.push(dataFromUser[i])
    }
  }

  return { toUpdate, toCreate }
}

// Returns two lists of descriptors (create/update)
// by dictating two functions (getIndicesOfDuplicates/updateList)
const getUpdatedList = (
  _dataFromDb: DescriptorProps[],
  _dataFromUser: DescriptorProps[],
  _lists: ToCreateAndToUpdateType
): ToCreateAndToUpdateType => {
  const duplicates = getIndicesOfDuplicates(_dataFromDb, _dataFromUser)
  return updateList(duplicates, _dataFromUser, _lists)
}

// Returns lists of descriptors (create/update)
// while handling the case when there's no data in database
// This is the entry function called by main file (index.tsx)
export const getListToCreateAndToUpdate = (
  dataFromDb: DescriptorProps[],
  dataFromUser: DescriptorProps[]
): ToCreateAndToUpdateType => {
  const lists = {
    toUpdate: [],
    toCreate: [],
  }

  return size(dataFromDb)
    ? getUpdatedList(dataFromDb, dataFromUser, lists)
    : { ...lists, toCreate: dataFromUser }
}

// Checks if uploaded descriptors have any duplicate ids
export const getDescriptorsWithDuplicateIDs = (
  descriptors: DescriptorProps[]
): Set<string> => {
  const lookupMentionsOfUDId = descriptors.reduce((a, e) => {
    a[e.id] = ++a[e.id] || 0
    return a
  }, {})

  return new Set(
    descriptors.filter(e => lookupMentionsOfUDId[e.id]).map(e => e.id)
  )
}

export const handleUpload = async ({
  storedDescriptors,
  descriptorsToCreate,
  descriptorsToUpdate,
  createUniversalDescriptor,
  updateUniversalDescriptor,
}: HandleUploadProps): Promise<void> => {
  if (size(storedDescriptors)) {
    void showToast({
      message: MESSAGES.DESCRIPTORS_UPLOAD_START,
      kind: TOAST_MESSAGE_TYPES.SUCCESS,
    })

    const createCount = await handleMutation(
      descriptorsToCreate,
      createUniversalDescriptor
    )
    const updateCount = await handleMutation(
      descriptorsToUpdate,
      updateUniversalDescriptor
    )
    const successCount = createCount.success + updateCount.success
    const errorCount = createCount.error + updateCount.error

    removeToast(0, TOAST_REMOVAL_TIMING)

    handleMessage(successCount, {
      msgGetter: MESSAGES.getUploadDescriptorsSuccess,
      kind: TOAST_MESSAGE_TYPES.SUCCESS,
    })
    handleMessage(errorCount, {
      msgGetter: MESSAGES.getUploadDescriptorsError,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

const validateFormat = (item: DescriptorProps): boolean => {
  switch (true) {
    case Array.isArray(item):
    case typeof item !== 'object':
    case !size(item):
      return true
    default:
      return false
  }
}

// Populates missingAttributes with missing attributes
const populateMissingAttributes = (
  item: DescriptorProps,
  missingAttributes: Set<string>
) => {
  for (const attr of SETTINGS.ATTRIBUTES.REQUIRED) {
    if (!Object.keys(item).includes(attr)) {
      missingAttributes.add(attr)
    }
  }
}

const isInvalidType = (key, value, list) => {
  return key === 'type' && !list.includes(value)
}

const isInvalidBoolean = (key, value, listOfBoolean) => {
  if (listOfBoolean.includes(key)) {
    return typeof value !== 'boolean'
  }
  return false
}

// Recursively saves each invalid item to invalidAttributes list
const handleAttributes = (
  obj: JsonValue,
  validTypes: string[],
  invalidAttributes: InvalidAttributesProps
) => {
  for (const [key, value] of Object.entries(obj)) {
    switch (true) {
      case isPlainObject(value):
        handleAttributes(value as JsonValue, validTypes, invalidAttributes)
        break
      case isInvalidType(key, value, validTypes):
        invalidAttributes.types.add(JSON.stringify(value))
        break
      case isInvalidBoolean(key, value, SETTINGS.ATTRIBUTES.BOOLEAN):
        invalidAttributes.attributes.add(MESSAGES.getInvalidBooleanMessage(key))
        break
      default:
    }
  }
}

const isNullOrEmpty = (attr, value, list) => {
  return list.includes(attr) && typeof value !== 'boolean' && !value
}

const isObjectWithProps = (attr, value, isObject, hasSize) => {
  return Boolean(attr === 'attributes' && isObject(value) && hasSize(value))
}

// Populates invalidAttributes with invalid attributes
const populateInvalidAttributes = (
  item: DescriptorProps,
  invalidAttributes: InvalidAttributesProps
) => {
  const { REQUIRED, ALLOWED } = SETTINGS.ATTRIBUTES
  const validAttributes = [...REQUIRED, ...ALLOWED]
  const userDefinedAttributes = Object.keys(item)

  for (const attr of userDefinedAttributes) {
    const value = item[attr] as JsonValue

    switch (true) {
      case !validAttributes.includes(attr):
        invalidAttributes.attributes.add(
          MESSAGES.getInvalidAttributeMessage(attr)
        )
        break
      case isNullOrEmpty(attr, value, REQUIRED):
        invalidAttributes.attributes.add(
          MESSAGES.getEmptyAttributeMessage(attr)
        )
        break
      case isObjectWithProps(attr, value, isPlainObject, size):
        handleAttributes(value, SETTINGS.VALID_TYPES, invalidAttributes)
        break
      case isInvalidBoolean(attr, value, SETTINGS.ATTRIBUTES.BOOLEAN):
        invalidAttributes.attributes.add(
          MESSAGES.getInvalidBooleanMessage(attr)
        )
        break
      default:
    }
  }
}

// Recursively traverse attributes object and find the amount of nesting
const getNestedDepth = (item, currDepth: number, maxDepth: number) => {
  if (currDepth > MAX_ALLOWED_DEPTH) {
    return currDepth
  }

  for (const key in item) {
    if (isPlainObject(item[key]) && !isEmpty(item[key])) {
      if (key === 'children') {
        currDepth++
      }

      const tempMax = getNestedDepth(item[key], currDepth, maxDepth)
      maxDepth = Math.max(maxDepth, tempMax)
    }
  }

  return Math.max(maxDepth, currDepth)
}

const populateMaxDepthAttributes = (
  item: DescriptorProps,
  maxDepthAttributes: Set<string>
) => {
  const maxDepth = getNestedDepth(item.attributes, 1, 1)
  if (maxDepth > MAX_ALLOWED_DEPTH) {
    maxDepthAttributes.add(item.label)
  }
}

const getUploadErrorContext = (
  kind: string,
  violations?: string[]
): InvalidContextProps => {
  const {
    FORMAT,
    REQUIRED_ATTR,
    INVALID_ATTR,
    INVALID_ATTR_TYPE,
    MAX_ATTRIBUTES_DEPTH,
  } = SETTINGS.UPLOAD_ERROR
  const context = {
    [FORMAT.KIND]: {
      title: FORMAT.CONTEXT.TITLE,
      description: FORMAT.CONTEXT.DESCRIPTION,
      footerText: FORMAT.CONTEXT.FOOTER,
    },
    [REQUIRED_ATTR.KIND]: {
      title: REQUIRED_ATTR.CONTEXT.TITLE,
      description: REQUIRED_ATTR.CONTEXT.DESCRIPTION,
      violations,
      footerText: REQUIRED_ATTR.CONTEXT.FOOTER,
    },
    [INVALID_ATTR.KIND]: {
      title: INVALID_ATTR.CONTEXT.TITLE,
      description: INVALID_ATTR.CONTEXT.DESCRIPTION,
      violations,
      footerText: INVALID_ATTR.CONTEXT.FOOTER,
    },
    [INVALID_ATTR_TYPE.KIND]: {
      title: INVALID_ATTR_TYPE.CONTEXT.TITLE,
      description: INVALID_ATTR_TYPE.CONTEXT.DESCRIPTION,
      violations,
      footerText: INVALID_ATTR_TYPE.CONTEXT.FOOTER,
    },
    [MAX_ATTRIBUTES_DEPTH.KIND]: {
      title: MAX_ATTRIBUTES_DEPTH.CONTEXT.TITLE,
      description: MAX_ATTRIBUTES_DEPTH.CONTEXT.DESCRIPTION,
      violations,
      footerText: MAX_ATTRIBUTES_DEPTH.CONTEXT.FOOTER,
    },
  }

  return context[kind]
}

// Creates a function that holds context, and that function handles the context for the invalid list dialog
const createContextHandler = (kind: string, invalids: Set<string>) => {
  const ctx = getUploadErrorContext(kind, Array.from(invalids))

  return (setInvalidDataContext, invalidList) => {
    setInvalidDataContext(ctx)
    invalidList.clear()
  }
}

export const validateUploadData = (
  storedDescriptors: DescriptorProps[],
  setInvalidDataContext: (content: InvalidContextProps) => void
): boolean => {
  const {
    FORMAT,
    REQUIRED_ATTR,
    INVALID_ATTR_TYPE,
    INVALID_ATTR,
    MAX_ATTRIBUTES_DEPTH,
  } = SETTINGS.UPLOAD_ERROR
  const missingAttributes = new Set<string>()
  const maxDepthAttributes = new Set<string>()
  const invalidAttributes = {
    attributes: new Set<string>(),
    types: new Set<string>(),
  }

  if (!Array.isArray(storedDescriptors)) {
    const ctx = getUploadErrorContext(FORMAT.KIND)
    setInvalidDataContext(ctx)
    return false
  } else {
    for (const item of storedDescriptors) {
      if (validateFormat(item)) {
        const ctx = getUploadErrorContext(FORMAT.KIND)
        setInvalidDataContext(ctx)
        return false
      }
      populateMissingAttributes(item, missingAttributes)
      populateInvalidAttributes(item, invalidAttributes)
      populateMaxDepthAttributes(item, maxDepthAttributes)
    }

    if (missingAttributes.size) {
      const handleInvalidDataContext = createContextHandler(
        REQUIRED_ATTR.KIND,
        missingAttributes
      )
      handleInvalidDataContext(setInvalidDataContext, missingAttributes)
      return false
    }

    if (maxDepthAttributes.size) {
      const handleInvalidDataContext = createContextHandler(
        MAX_ATTRIBUTES_DEPTH.KIND,
        maxDepthAttributes
      )
      handleInvalidDataContext(setInvalidDataContext, maxDepthAttributes)
      return false
    }

    if (invalidAttributes.attributes.size) {
      const handleInvalidDataContext = createContextHandler(
        INVALID_ATTR.KIND,
        invalidAttributes.attributes
      )
      handleInvalidDataContext(
        setInvalidDataContext,
        invalidAttributes.attributes
      )
      return false
    }

    if (invalidAttributes.types.size) {
      const handleInvalidDataContext = createContextHandler(
        INVALID_ATTR_TYPE.KIND,
        invalidAttributes.types
      )
      handleInvalidDataContext(setInvalidDataContext, invalidAttributes.types)
      return false
    }

    return true
  }
}
