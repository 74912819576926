import {
  ALL_COMPONENTS,
  DESCRIPTOR_DETAILS,
  MESSAGES,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import {
  CREATE_UNIVERSAL_DESCRIPTOR,
  GET_UNIVERSAL_DESCRIPTORS,
  GET_UNIVERSAL_DESCRIPTOR_BY_ID,
  UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID,
} from 'services/graphql/universalDescriptors'
import {
  DescriptorByIdData,
  DescriptorByIdVars,
  DescriptorDetailsProps,
} from './types'
import { InvalidContextProps, emptyData } from '../types'
import React, { useEffect, useState } from 'react'
import {
  StyledContainer,
  StyledDescriptorContainer,
  StyledDescriptorWrapper,
} from './styles'
import { handleParseToJson, setInitialData } from './utils'
import { useLazyQuery, useMutation } from '@apollo/client'
import AddComponentMode from '../utils/AddComponentMode'
import DescriptorContent from './components/descriptor-content'
import DescriptorHeader from './components/descriptor-header'
import { DescriptorProps } from 'store/types'
import DuplicateDescriptorsModal from '../modals/DuplicateDescriptorsModal'
import EditComponentMode from '../utils/EditComponentMode'
import InvalidUploadDataModal from '../modals/InvalidUploadDataModal'
import Spinner from 'components/Spinner'
import ViewComponentMode from 'modules/settings/utils/ViewComponentMode'
import isError from 'lodash/isError'
import omit from 'lodash/omit'
import { validateUploadData } from '../utils'
import { Box, ButtonProps, PageHeader, useToast } from 'ds4-beta'

// eslint-disable-next-line sonarjs/cognitive-complexity
const DescriptorDetails: React.FunctionComponent<DescriptorDetailsProps> = props => {
  const {
    mode,
    setMode,
    id,
    onNavigateBack,
    descriptorIds,
    showDeleteModal,
  } = props

  const [updateUniversalDescriptor] = useMutation<DescriptorProps>(
    UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID
  )
  const {
    CTA: { CANCEL, SAVE, EDIT, DELETE },
  } = DESCRIPTOR_DETAILS
  const showToast = useToast()
  const [createUniversalDescriptor] = useMutation(CREATE_UNIVERSAL_DESCRIPTOR, {
    update(cache, { data: updatedData }) {
      const {
        universalDescriptors,
      }: { universalDescriptors: DescriptorProps[] } = cache.readQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
      })
      cache.writeQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
        data: {
          universalDescriptors: [
            ...universalDescriptors,
            updatedData.createUniversalDescriptor,
          ],
        },
      })
    },
  })
  const [
    singleDescriptor,
    setSingleDescriptor,
  ] = useState<DescriptorProps | null>(null)

  const [labelChanged, setLabelChanged] = useState(false)
  const [idChanged, setIdChanged] = useState(false)

  const [
    invalidDataContext,
    setInvalidDataContext,
  ] = useState<InvalidContextProps | null>(null)

  const [isDuplicateIdModalVisible, setIsDuplicateIdModalVisible] = useState(
    false
  )
  const handleModalClose = () => {
    setInvalidDataContext(null)
    setIsDuplicateIdModalVisible(false)
  }

  const [getDescriptor, { data, loading, error }] = useLazyQuery<
    DescriptorByIdData,
    DescriptorByIdVars
  >(GET_UNIVERSAL_DESCRIPTOR_BY_ID)

  window.updateBreadcrumb([
    {
      label: `home`,
      url: '/',
    },
    {
      label: `experiences`,
      url: '/experiences/settings',
      onLabelClick: () => onNavigateBack(),
    },
    {
      label: `settings`,
      url: '/experiences/settings',
      onLabelClick: () => onNavigateBack(),
    },
    {
      label: mode.retrieveHeader(data?.universalDescriptorById?.label) || '',
      disabled: true,
    },
  ])

  useEffect(() => {
    const fetchDescriptor = async () => {
      await getDescriptor({ variables: { id } })
    }

    id ? void fetchDescriptor() : setInitialData(emptyData, setSingleDescriptor)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    const cleanedSingleDescriptor = omit(singleDescriptor, ['__typename'])
    const result = handleParseToJson(
      cleanedSingleDescriptor.attributes as string
    )

    if (isError(result.error)) {
      void showToast({
        id: 'descriptor-json-error',
        label: DESCRIPTOR_DETAILS.MESSAGES.JSON_PARSE_ERROR,
        variant: 'alert',
      })
      return
    }

    const descriptorToValidate = {
      ...cleanedSingleDescriptor,
      attributes: result.attributes,
    }

    const isUploadDataValid = validateUploadData(
      [descriptorToValidate],
      setInvalidDataContext
    )

    if (!isUploadDataValid) {
      return
    }

    if (
      mode === AddComponentMode &&
      descriptorIds &&
      descriptorIds.includes(cleanedSingleDescriptor.id)
    ) {
      setIsDuplicateIdModalVisible(true)
      return
    }

    try {
      const input = {
        ...cleanedSingleDescriptor,
        description: cleanedSingleDescriptor.description ?? '',
        attributes: result.attributes,
      }

      if (mode === EditComponentMode) {
        await updateUniversalDescriptor({
          variables: {
            input,
          },
        })
      } else {
        await createUniversalDescriptor({
          variables: {
            input,
          },
        })
      }

      void showToast({
        id: 'descriptor-success',
        label: DESCRIPTOR_DETAILS.MESSAGES.SUCCESS,
        variant: 'default',
      })
      mode.setModeAfterSave(setMode)
    } catch (err) {
      console.error(err)
      void showToast({
        id: 'descriptor-erro',
        label: DESCRIPTOR_DETAILS.MESSAGES.ERROR,
        variant: 'alert',
      })
    }
  }

  const handleCancel = () => {
    if (id) {
      setMode(ViewComponentMode)
      setInitialData(data, setSingleDescriptor)
    } else {
      onNavigateBack()
    }
  }

  useEffect(() => {
    if (data?.universalDescriptorById) {
      setInitialData(data, setSingleDescriptor)
    }
  }, [data])

  if (error) {
    void showToast({
      id: 'descriptor-alert-fetching-error',
      label: MESSAGES.getFetchingError(`"${id}"`),
      variant: 'alert',
    })
    onNavigateBack()
  }

  if (loading) {
    return <Spinner variant='fullScreen' />
  }

  const renderHeaderButtons = () => {
    const buttons = []
    if (mode.isEditMode()) {
      buttons.push({
        text: CANCEL,
        onClick: handleCancel,
        dataTestid: 'descriptor-cta-cancel',
        variant: 'tertiary',
      })
      if (mode.shouldShowDeleteButton()) {
        buttons.push({
          text: DELETE,
          onClick: showDeleteModal,
          dataTestid: 'descriptor-cta-delete',
          variant: 'secondary',
        })
      }
      buttons.push({
        text: SAVE,
        onClick: handleSubmit,
        dataTestid: 'descriptor-cta-save',
        variant: 'primary',
      })
    } else {
      buttons.push({
        text: EDIT,
        onClick: () => setMode(EditComponentMode),
        dataTestid: 'descriptor-cta-edit',
        variant: 'primary',
      })
    }
    return buttons
  }

  return (
    <StyledDescriptorContainer>
      <StyledContainer>
        <StyledDescriptorWrapper>
          <Box margin={{ bottom: 4 }}>
            <PageHeader
              h1Text={mode.retrieveHeader(data?.universalDescriptorById?.label)}
              primaryButtons={renderHeaderButtons() as ButtonProps[]}
            />
          </Box>
          {singleDescriptor && (
            <DescriptorContent
              mode={mode}
              descriptor={singleDescriptor}
              setSingleDescriptor={setSingleDescriptor}
              isEditing={mode.isEditMode()}
              setLabelChanged={setLabelChanged}
              labelChanged={labelChanged}
              setIdChanged={setIdChanged}
              idChanged={idChanged}
            />
          )}
        </StyledDescriptorWrapper>
      </StyledContainer>

      {invalidDataContext && (
        <InvalidUploadDataModal
          onClose={handleModalClose}
          context={invalidDataContext}
        />
      )}
      {isDuplicateIdModalVisible && (
        <DuplicateDescriptorsModal
          onClose={handleModalClose}
          duplicateIds={new Set([singleDescriptor?.id])}
        />
      )}
    </StyledDescriptorContainer>
  )
}

export default DescriptorDetails
