import {
  ContentTypeFunctionProps,
  FormSubmitActionProps,
  MetadataProps,
  ModeActionFunctionProps,
  PageInitialValueProps,
  SeoFieldsProps,
} from '../types'
import {
  EMPTY_STRING,
  MESSAGES,
  MODAL_ACTION,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import { LocaleValue } from 'components/LocalesField/types'
import { PageSEOFieldsMetadata } from 'src/graphql-proxy/transformations/page/types'
import extract from 'lib/extract'
import isEmpty from 'lodash/isEmpty'
import { nanoid } from 'nanoid'
import { navigate } from '@reach/router'
import { showToast } from 'components/ToastSnackbarContainer'
import { getBasePath } from 'src/contexts/navigationLinksContext'

export const DEFAULT_METADATA = [
  {
    id: nanoid(),
    name: '',
    content: '',
    label: '',
  },
]
export const EMPTY_SELECTED_LOCALE = {
  id: EMPTY_STRING,
  name: EMPTY_STRING,
  label: EMPTY_STRING,
}

export const hasSEOFields = ({
  title,
  description,
  metadata,
}: SeoFieldsProps): boolean => {
  return (
    !isEmpty(title) ||
    !isEmpty(description) ||
    !isEmpty(metadata[metadata.length - 1].name)
  )
}

interface FormSubmitProps extends PageInitialValueProps {
  contentElement: ContentTypeFunctionProps
  modeAction: ModeActionFunctionProps
  multiChannel: boolean
  localesEnabled: boolean
  handleClose: () => void
  onFetchList: () => void
  navNameChange: boolean
}

export const handleFormSubmit = async ({
  newPageName = '',
  newPageUrl = '',
  versionName,
  locales,
  localesEnabled,
  localizedSeoFields,
  title,
  description,
  metadata,
  channels,
  name,
  url,
  pageTypeId,
  contentElement,
  modeAction,
  multiChannel,
  handleClose,
  onFetchList,
  navNameChange,
}: FormSubmitProps): Promise<void> => {
  try {
    const seoFields = {
      title,
      description,
      metadata,
    }

    const data: FormSubmitActionProps = {
      contentType: contentElement.getContentType(),
      parentId: contentElement.getParentId(),
      multiChannel,
      localesEnabled,
      channels,
      name,
      url,
      newPageName,
      newPageUrl,
      versionName,
      locales,
      localizedSeoFields,
      pageTypeId,
      seoFields,
    }

    const response = await modeAction.submit(data)

    void showToast({
      message:
        modeAction.getMode() === MODAL_ACTION.EDIT
          ? MESSAGES.getSavedSuccess(response.page.name)
          : MESSAGES.getCreatedSuccess(response.page.name),
      kind: TOAST_MESSAGE_TYPES.SUCCESS,
    })

    handleClose()

    if (modeAction.getMode() === MODAL_ACTION.COPY) {
      onFetchList()
    } else if (modeAction.getMode() === MODAL_ACTION.CREATE) {
      const basepath = getBasePath(navNameChange)
      const redirectTo = `${basepath}/editor?pageId=${response.page.pageId}&versionId=${response.page.versions[0].versionId}`
      await navigate(redirectTo)
    } else if (modeAction.getMode() === MODAL_ACTION.EDIT) {
      location.reload() // @TODO: refactor to not refresh the whole page
    }
  } catch (err) {
    console.error(err)
    void showToast({
      message: extract.errorString(err) as string,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

export const getInitialSelectedLocale = (
  assignedLocales: LocaleValue[]
): {
  id: string
  name: string
  label: string
} => ({
  id: assignedLocales[0]?.label,
  name: assignedLocales[0]?.label,
  label: assignedLocales[0]?.label,
})

export const generateNewMetadata = (): {
  id: string
  name: string
  content: string
} => {
  return { id: nanoid(), name: '', content: '' }
}

export const metadataDTOToFormInput = (
  metadata?: PageSEOFieldsMetadata[]
): MetadataProps[] | undefined => {
  return metadata
    ? metadata?.map(d => ({
        id: d?._id ?? nanoid(),
        name: d?.name ?? '',
        label: d?.name ?? '',
        content: d?.content ?? '',
      }))
    : undefined
}
