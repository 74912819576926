export const INITIAL_PAGE = 1

export const PIPELINE_WIDTH = 20

export const DEFAULT_ICON_SIZE = 16

export const OVERLAP = 'overlap'

export const DRAGGING_SECTION_CHILD = 'dragging-section-child'

export const DEFAULT_TIME = '2099-01-01T00:00:00.000+00:00'

export const NODE_WIDTH = '276px'

export enum PIPELINE_DATA_ATTR_KEYS {
  pipelineId = 'data-pipelineid',
  parentNodeId = 'data-parent',
}

export const PIPELINE_DATA_ATTR_VALUES = {
  getPipelineId: ({
    showPipeline,
    depth,
  }: {
    showPipeline: boolean
    depth?: number
  }): string =>
    showPipeline && depth >= 0 ? `pipeline-depth-${depth}` : 'no-pipeline',
}

export const SUBNODE_ORDER_INCREMENT = 0.01

export const DEFAULT_LOCALE = 'default'

export const REFETCH_MENU_LIST_WAIT_TIME = 1000

type BadgeStatus = {
  version: 'DRAFT' | 'ENDED' | 'LIVE'
  variant: 'alert' | 'default' | 'success'
}

export const VERSION_BADGE_STATUS: BadgeStatus[] = [
  {
    version: 'LIVE',
    variant: 'success',
  },
  {
    version: 'DRAFT',
    variant: 'alert',
  },
  {
    version: 'ENDED',
    variant: 'default',
  },
]
