import React from 'react'
import { Link, Box, Tag } from 'ds4-beta'
import FilterBy from 'src/ds4/components/FilterBy'
import isEmpty from 'lodash/isEmpty'
import { ContentFilterProps } from './types'

export const FILTER_MODULE_ID = 'content-filter-module'

const ContentFilterModule: React.FC<ContentFilterProps> = props => {
  const { entries } = props
  const isFilterApplied =
    entries.filter(
      entry =>
        entry.multiSelectOptions.filter(option => option.isChecked).length > 0
    ).length > 0

  const onResetFilters = () => {
    entries?.forEach(entry => {
      entry.onChange([])
    })
  }

  const removeAttributeCondition = (optionId: string) =>
    entries?.forEach(entry => {
      entry?.onChange(
        entry?.multiSelectOptions?.filter(
          option => option?.isChecked && option?.id !== optionId
        )
      )
    })

  return (
    <Box
      id={FILTER_MODULE_ID}
      dataTestid={FILTER_MODULE_ID}
      gap={2}
      flex={{ flexDirection: 'column' }}
    >
      <Box flex={{ flexDirection: 'row' }} gap={1}>
        {!isEmpty(entries) &&
          entries.map(entry => (
            <FilterBy
              key={entry.id}
              chipsName={entry.label}
              multiSelectOptions={entry.multiSelectOptions}
              onChange={entry.onChange}
            />
          ))}
        {isFilterApplied && (
          <Link
            onClick={onResetFilters}
            label='Reset Filters'
            dataTestid={'content-filter-module-reset-filters'}
          />
        )}
      </Box>
      {isFilterApplied ? (
        <Box flex={{ flexDirection: 'row', flexWrap: 'wrap' }} gap={1}>
          {entries?.map(entry =>
            entry?.multiSelectOptions?.map(option => {
              const label = `${entry?.label}: ${option?.name}`
              if (option?.isChecked)
                return (
                  <Tag
                    key={label}
                    id={option?.id}
                    isRemovable
                    onRemove={event => {
                      event.stopPropagation()
                      removeAttributeCondition(option?.id)
                    }}
                    primaryLabel={label}
                  />
                )
            })
          )}
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(ContentFilterModule)
