import { MenuTreeNode, Node } from '../types'
import { TreeColumnProps, ListItemType } from 'ds4-beta'
import { isUndefined } from 'lodash'
import { UniqueIdentifier } from '@dnd-kit/core'
import { arrayMove as dndKitArrayMove } from '@dnd-kit/sortable'
import { DEFAULT_LOCALE, VERSION_BADGE_STATUS } from '../constants'

export const organizeDataIntoHierarchy = (data: MenuTreeNode[]) => {
  const hierarchy: { [key: string]: MenuTreeNode } = {}

  data.forEach(item => {
    hierarchy[item?._id] = { ...item, children: [] }
  })

  data.forEach(item => {
    if (hierarchy[item?.parent]) {
      hierarchy[item.parent].children.push(hierarchy[item?._id])
      delete hierarchy[item?._id]
    }
  })

  const buildTree = (nodes: MenuTreeNode[]) =>
    nodes.map(node => {
      if (node?.children?.length > 0) {
        node.children = buildTree(node?.children)
      }
      return node
    })

  return buildTree(Object.values(hierarchy))
}

export const generateMenuItems = (
  menuHierarchy: MenuTreeNode[],
  selectedLocale: string
): ListItemType[] => {
  return menuHierarchy?.reverse()?.map((node: Node) => {
    return {
      ...node,
      id: node?._id,
      label: node?.name?.[selectedLocale],
      idEditing: node?.idEditing || false,
      suffix: node?.disabled ? 'inactive' : 'active',
      shouldFetchNodes:
        (node?.section && node?.children?.length === 0) || !node?.section,
      childLoading: node?.section && node?.children?.length === 0,
      ...(node?.children?.length > 0 && {
        children: generateMenuItems(node?.children, selectedLocale),
      }),
    }
  })
}

const updateNodeStatusRecursively = (
  item: ListItemType,
  nodeData: Node,
  isEditable: boolean
) => {
  if (item?.id === nodeData?.id) {
    return { ...item, isEditing: isEditable }
  } else if (item.children) {
    const updatedChildren = item?.children?.map(child =>
      updateNodeStatusRecursively(child, nodeData, isEditable)
    )
    return { ...item, children: updatedChildren }
  } else {
    return item
  }
}

export const updateEditableNode = (
  tree: TreeColumnProps[],
  nodeData: Node,
  isEditable: boolean
) => {
  return tree?.map((column: TreeColumnProps) => {
    const updatedItems = column?.items?.map((item: ListItemType) =>
      updateNodeStatusRecursively(item, nodeData, isEditable)
    )
    return { ...column, items: updatedItems }
  })
}

export const findColumn = (
  treeViewData: TreeColumnProps[],
  id: string | UniqueIdentifier
) => {
  let column: number = -1
  let indexFoundAt = 0
  const position: Array<number> = []
  treeViewData.forEach((data, index) => {
    data.items.forEach((item, index2) => {
      if (item.id === id) {
        column = index
        indexFoundAt = index2
      }
      if (item.children) {
        const results: Array<number> = []
        if (findRecursiveChildren(item.children, id, results)) {
          column = index
        }
        if (results.length > 0) {
          const resultOrdered = [...results.reverse()]
          position.push(index2, ...resultOrdered)
        }
      }
    })
  })

  return { column, position, index: indexFoundAt }
}

export const findRecursiveChildren = (
  children: ListItemType[],
  id: string | UniqueIdentifier,
  result: Array<number>
) => {
  return children.some((item, index) => {
    if (item.id === id) {
      result.push(index)
      return true
    }
    if (item.children) {
      const a = findRecursiveChildren(item.children, id, result)
      if (a) {
        result.push(index)
        return true
      }
    }
  })
}

export const addAtIndexGeneric = (array: any, index: number, item: any) => {
  if (!array) return
  return [...array.slice(0, index), item, ...array.slice(index)]
}

export const findNodeIndexByDepth = (
  column: MenuTreeNode[],
  nodeId: string
) => {
  let orderOfTheOriginNode = -1
  column?.forEach((node: Node, index: number) => {
    if (node?._id === nodeId) orderOfTheOriginNode = index
  })

  return orderOfTheOriginNode
}

export const addInMultiArray = (
  list: ListItemType[] | undefined,
  id: number | UniqueIdentifier,
  itemToInsert: ListItemType
) => {
  if (!list) return
  list?.forEach(item => {
    const foundIndex = item.children?.findIndex(it => it.id === id)
    if (!isUndefined(foundIndex) && foundIndex > -1) {
      const modifiedArray = addAtIndexGeneric(
        item.children,
        foundIndex,
        itemToInsert
      )
      item.children = modifiedArray
    }

    addInMultiArray(item?.children, id, itemToInsert)
  })
}

export const removeAtIndexGeneric = (array: any, index: number) => {
  if (!array) return
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export const removeAtIndex = (array: any, index: number) => {
  if (isUndefined(array) || isUndefined(array?.items)) return
  return {
    ...array,
    items: [...array.items.slice(0, index), ...array.items.slice(index + 1)],
  }
}
export const arrayMove = (array: any, oldIndex: number, newIndex: any) => {
  return dndKitArrayMove(array, oldIndex, newIndex)
}
export const insertAtIndex = (array: any, index: number, item: any) => {
  if (isUndefined(array) || isUndefined(array?.items)) return
  return {
    ...array,
    items: [...array.items.slice(0, index), item, ...array.items.slice(index)],
  }
}

export const removeFromMultiArray = (
  list: ListItemType[] | undefined,
  id: number
) => {
  if (!list) return
  list?.forEach(item => {
    const foundIndex = item.children?.findIndex(it => it.id === id)
    if (!isUndefined(foundIndex) && foundIndex > -1) {
      const modifiedArray = removeAtIndexGeneric(item.children, foundIndex)
      item.children = modifiedArray
    } else {
      removeFromMultiArray(item?.children, id)
    }
  })
}

export const moveBetweenContainers = (
  treeView: TreeColumnProps[],
  activeContainer: number,
  activeIndex: number,
  overContainer: number,
  overIndex: number,
  item: any
): TreeColumnProps[] => {
  const treeViewCopy = [...treeView]
  treeViewCopy[activeContainer] = removeAtIndex(
    treeView[activeContainer],
    activeIndex
  )
  treeViewCopy[overContainer] = insertAtIndex(
    treeView[overContainer],
    overIndex,
    item
  )
  return treeViewCopy
}

export const getBadgeStatusForVersion = (
  inputVersion: string
): 'alert' | 'default' | null => {
  const upperCaseVersion = inputVersion.toUpperCase()
  const versionObj = VERSION_BADGE_STATUS.find(
    v => v.version === upperCaseVersion
  )
  return versionObj ? versionObj.variant : null
}

export const getLastUpdatedDateLabel = (formattedDate: string): string =>
  `Last updated ${formattedDate}`
