import React, { useState } from 'react'
import AnyType from 'src/ds4/components/type-components/AnyType'
import AttributeItemLabel from 'src/ds4/components/AttributeList/AttributeItemLabel'
import { ComponentTypeWrapper } from './styles'
import { EDITOR_IS_DELETING } from 'src/ds4/modules/editor/actions'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
export default function ComponentType(props) {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)
  const [expandedProperty, setExpandedProperty] = useState('')

  if (!Object.keys(props.descriptor).length) return null

  return (
    <ComponentTypeWrapper data-testid='component-type-wrapper'>
      <AttributeItemLabel
        label={props.descriptor.label}
        isExpanded={isExpanded}
        isDeletable={props.isDeleteable}
        handleOnLabelClick={() => setIsExpanded(e => !e)}
        handleOnDeleteClick={e => {
          e.stopPropagation()

          const lastIdxOfComponent = props.paths.lastIndexOf('components')
          const paths = props.paths.slice(0, lastIdxOfComponent + 1)
          const deleteIndex = props.paths[lastIdxOfComponent + 1]

          dispatch(
            EDITOR_IS_DELETING({
              isDeletingComponent: true,
              deletePayload: {
                paths,
                value: null,
                deleteIndex,
                isParentComponent: false,
                label: props.descriptor.label,
              },
            })
          )
        }}
        hasEditAccess={props.hasEditAccess}
      />

      {isExpanded &&
        Object.keys(props.descriptor.attributes).map(attribute => {
          return (
            <AnyType
              key={attribute}
              id={props.descriptor.id}
              descriptor={props.descriptor.attributes[attribute]}
              paths={props.paths.concat(attribute)}
              value={props.value[attribute]}
              onChange={props.onChange}
              expandedProperty={expandedProperty}
              setExpandedProperty={setExpandedProperty}
            />
          )
        })}
    </ComponentTypeWrapper>
  )
}

ComponentType.defaultProps = {
  descriptor: {},
  isDeleteable: true,
}

ComponentType.propTypes = {
  descriptor: PropTypes.object,
  paths: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isDeleteable: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
}
