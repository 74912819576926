import React, { useEffect, useState } from 'react'
import {
  APPLY_MODES,
  createDefaultAttribute,
  shouldDisableApplyCTA,
} from './utils'
import { nanoid } from 'nanoid'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { Card, Button, Box, theme } from 'ds4-beta'
import { MANAGE_NODE_SIDEBAR_LABEL } from 'src/constants'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'
import { AttributesSectionProps, ParamsProps } from './types'
import { StyledInputReadOnly, StyledFieldLabel } from './styles'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import AttributeForm from './AttributeForm'
import AttributeMenu from './AttributeMenu'

const AttributesSection: React.FC<AttributesSectionProps> = props => {
  const { values, setValues } = props
  const [attributeToEdit, setAttributeToEdit] = useState<ParamsProps>(null)
  const [attributeToAdd, setAttributeToAdd] = useState<ParamsProps>(null)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)

  useEffect(() => {
    if (values?.attributes?.length) {
      setValues(values => ({
        ...values,
        attributes: values?.attributes?.map(attr => ({
          ...attr,
          _id: nanoid(),
        })),
      }))
    }
  }, [])

  useEffect(() => {
    if (!values?.attributes) {
      setValues({
        ...values,
        attributes: [],
      })
    }
  }, [values, setValues])

  useEffect(() => {
    if (attributeToEdit) {
      setAttributeToAdd(null)
    } else if (attributeToAdd) {
      setAttributeToEdit(null)
    }
  }, [attributeToAdd, attributeToEdit])

  return (
    <Card
      header={MANAGE_NODE_SIDEBAR_LABEL.ATTRIBUTES}
      showCollapse
      body={
        <Box flex={{ flexDirection: 'column' }} gap={4}>
          {attributeToAdd && (
            <AttributeForm
              onApply={() => {
                const newAttributes = values?.attributes?.slice()
                newAttributes?.push({
                  ...attributeToAdd,
                  _id: nanoid(),
                })
                setValues({
                  ...values,
                  attributes: newAttributes,
                })
                setAttributeToAdd(createDefaultAttribute())
                setAttributeToAdd(null)
              }}
              onCancel={() => setAttributeToAdd(null)}
              attribute={attributeToAdd}
              setAttribute={setAttributeToAdd}
              applyCTADisabled={shouldDisableApplyCTA(
                attributeToAdd,
                values.attributes,
                APPLY_MODES.ADD_MODE
              )}
            />
          )}
          {attributeToEdit && (
            <AttributeForm
              onApply={() => {
                const newAttributes = values?.attributes?.map(attr => {
                  if (attr._id === attributeToEdit?._id) {
                    return attributeToEdit
                  }
                  return attr
                })
                setValues({
                  ...values,
                  attributes: newAttributes,
                })
                setAttributeToEdit(null)
              }}
              onCancel={() => setAttributeToEdit(null)}
              attribute={attributeToEdit}
              setAttribute={setAttributeToEdit}
              applyCTADisabled={shouldDisableApplyCTA(
                attributeToEdit,
                values.attributes,
                APPLY_MODES.EDIT_MODE
              )}
            />
          )}

          {!isEmpty(values?.attributes) &&
            values?.attributes?.map((attribute, index) => {
              const { _id, kind, value } = attribute
              const isLastItem = index === values?.attributes?.length - 1
              return (
                <Box
                  gap={1}
                  flex={{ flexDirection: 'column' }}
                  key={`attribute-${kind}`}
                  dataTestid={`attribute-${kind}`}
                  padding={isLastItem ? undefined : { bottom: 4 }}
                  border={
                    isLastItem
                      ? undefined
                      : {
                          width: 1,
                          color: theme.color.grey[200],
                          sides: ['bottom'],
                        }
                  }
                >
                  <StyledFieldLabel>{value}</StyledFieldLabel>
                  <Box gap={2} flex={{ flexDirection: 'row' }}>
                    <Box width={'100%'}>
                      <StyledInputReadOnly>{kind}</StyledInputReadOnly>
                    </Box>
                    {hasEditAccess && (
                      <AttributeMenu
                        handleOnClickEdit={() => {
                          setAttributeToEdit({ ...attribute })
                        }}
                        handleOnClickDelete={() => {
                          const newAttributes = values?.attributes?.filter(
                            attr => attr?._id !== _id
                          )
                          setValues({
                            ...values,
                            attributes: newAttributes,
                          })
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )
            })}

          {hasEditAccess && (
            <Box>
              <Button
                dataTestid='manage-node-link-cta'
                text='Add attribute'
                variant='tertiary'
                icon='Add'
                isDisabled={Boolean(attributeToEdit || attributeToAdd)}
                onClick={() => setAttributeToAdd(createDefaultAttribute())}
              />
            </Box>
          )}
        </Box>
      }
    />
  )
}

export default AttributesSection
