import { Flyout, FlyoutContent, FlyoutTrigger, InteractiveIcon } from 'ds4-beta'
import { MODAL_ACTION, EDITOR_OPTIONS } from 'src/constants'
import React, { useCallback } from 'react'
import List from 'components/List'
import PropTypes from 'prop-types'
import { StyledListItem } from '../SideBarComponents/styles'
import { useUserPermissions } from 'contexts/userPermissions'

const EditorOptions = ({ onEditorOptionClick }) => {
  const { hasEditorPermissions } = useUserPermissions()

  const onClickViewModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.VIEW)
  }, [onEditorOptionClick])

  const onClickEditModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.EDIT)
  }, [onEditorOptionClick])

  const onClickCreateModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.CREATE)
  }, [onEditorOptionClick])

  const onClickCopyModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.COPY)
  }, [onEditorOptionClick])

  return (
    <>
      <Flyout width='200px' height='176px'>
        <FlyoutTrigger>
          <InteractiveIcon iconName='MoreActions' />
        </FlyoutTrigger>
        <FlyoutContent>
          <List>
            <StyledListItem
              onClick={onClickViewModal}
              data-testid='editor-options-view-version-details'
            >
              <div className='flexi'>
                <span className='label'>{EDITOR_OPTIONS.VIEW}</span>
              </div>
            </StyledListItem>
            {hasEditorPermissions && (
              <>
                <StyledListItem
                  onClick={onClickEditModal}
                  data-testid='editor-options-edit-version-details'
                >
                  <div className='flexi'>
                    <span className='label'>{EDITOR_OPTIONS.EDIT}</span>
                  </div>
                </StyledListItem>
                <StyledListItem
                  onClick={onClickCreateModal}
                  data-testid='editor-options-create-new-version'
                >
                  <div className='flexi'>
                    <span className='label'>{EDITOR_OPTIONS.CREATE}</span>
                  </div>
                </StyledListItem>
                <StyledListItem
                  onClick={onClickCopyModal}
                  data-testid='editor-options-make-a-copy'
                >
                  <div className='flexi'>
                    <span className='label'>{EDITOR_OPTIONS.DUPLICATE}</span>
                  </div>
                </StyledListItem>
              </>
            )}
          </List>
        </FlyoutContent>
      </Flyout>
    </>
  )
}

EditorOptions.propTypes = {
  onEditorOptionClick: PropTypes.func.isRequired,
}

export default EditorOptions
