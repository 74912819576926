import { OPTIONAL_DESCRIPTION_LABEL } from 'src/constants'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledFormField } from './styles'
import { Textarea } from 'ds4-beta'
import { noop } from 'lodash'

const VersionDetailTextarea = props => {
  const { onChange, value, viewMode } = props

  return (
    <StyledFormField viewMode={viewMode}>
      <br />
      <Textarea
        width='100%'
        className='multiline'
        label={OPTIONAL_DESCRIPTION_LABEL}
        disabled={viewMode}
        textareaProps={{
          'limit': 400,
          'rows': 2,
          'value': value,
          'onChange': onChange,
          'name': 'name',
          'data-testid': 'description-field',
        }}
      />
    </StyledFormField>
  )
}

VersionDetailTextarea.defaultProps = {
  value: '',
  viewMode: false,
  onChange: noop,
}

VersionDetailTextarea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  viewMode: PropTypes.bool,
}

export default VersionDetailTextarea
