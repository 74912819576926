import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  BROWSE_MENU_TREE_MODAL_ID,
  BrowseMenuTreeModuleContext,
} from 'src/ds4/data/browse-menu-tree-gql/types'
import {
  CREATE_MENU_NODE,
  DELETE_MENU_NODE,
  LIST_MENUS,
  MENU_NODES_AS_LIST,
  MENU_VARIANT_AND_NODE_LIST,
  MENU_VARIANT_BY_ID,
  UPDATE_MENU_GRAPH,
  UPDATE_MENU_NODE,
} from 'services/graphql'
import {
  UpdateMenuNodeResponse,
  CreateMenuNodeResponse,
  DeleteMenuNodeResponse,
  HandleCreateProps,
  MenuNodesAsList,
  MenuNodesAsListResponse,
  MenuTreeNode,
  MenuVariantAndNodesListResponse,
  MenuVariantById,
  MenuVariantByIdResponse,
  NODE_TYPE,
  Names,
  UpdateMenuGraphResponse,
  Node,
} from './types'
import {
  DEFAULT_LOCALE,
  REFETCH_MENU_LIST_WAIT_TIME,
  SUBNODE_ORDER_INCREMENT,
} from './constants'
import {
  EDITOR_RESET,
  EDITOR_SET_VERSION_STATUS,
} from 'src/ds4/modules/editor/actions'
import {
  BROWSE_MENU_TREE_DEFAULT_NAME as DEFAULT_LABELS,
  EMPTY_STRING,
  MESSAGES,
  STATUS,
  BROWSE_MENU_TREE_CONTEXT_MENU_ITEMS as OPTIONS,
} from 'src/constants'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StyledContainer, StyledSpinner } from './styles'
import { getActiveLanguage, getLocales } from 'store/i18n/selectors'
import {
  getMenuTreeAfterDragAndDrop,
  getNodesToAppend,
  removeNodeFromTree,
  toggleNodeVisibility,
  toggleNodeVisibilityInGraph,
} from 'src/ds4/data/browse-menu-tree-gql/stateTransform'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { CHANGE_ACTIVE_LANGUAGE } from 'store/i18n/actions'
import ContentModalModule from 'src/ds4/components/ContentModalModule'
import Header from './components/Header'
import { ListMenuResponse } from 'services/graphql/types'
import { MenuNode } from 'src/modules/browse-menu-tree-gql/types'
import { MenuVersionCreateGraphQLResponse } from 'src/graphql-proxy/transformations/menu-version/types'
import { constructInitialMenuTree } from './utils/constructInitialMenuTree'
import extract from 'lib/extract'
import { getCurrentMenuLocales } from 'src/ds4/data/browse-menu-tree-gql/utils'
import { handleMutation } from 'src/ds4/data/services'
import httpStatusCodes from 'http-status-codes'
import { isDropInvalid } from './utils/dragEnd'
import { menuNodesAsList as menuNodesAsListService } from 'src/ds4/data/browse-menu-tree-gql/service'
import rfdc from 'rfdc'
import { useBrowseMenuTreeModalOperations } from 'src/ds4/data/browse-menu-tree-gql/modal-operations'
import { useBrowseMenuTreeModalOptions } from 'src/ds4/data/browse-menu-tree-gql/modal-options'
import { useLocation } from '@reach/router'
import { useUserPermissions } from 'contexts/userPermissions'
import {
  GridCol,
  GridRow,
  TreeView,
  useToast,
  ListItemType,
  TreeColumnProps,
} from 'ds4-beta'
import {
  arrayMove,
  findColumn,
  removeAtIndex,
  insertAtIndex,
  addInMultiArray,
  generateMenuItems,
  updateEditableNode,
  findNodeIndexByDepth,
  removeFromMultiArray,
  moveBetweenContainers,
  organizeDataIntoHierarchy,
} from './utils'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import AddNodeModal from './components/AddNodeModal'
import { AddSpecialNodeModalProps } from './components/AddNodeModal/types'
import DeleteConfirmationModal from './components/DeleteConfirmationModal'
import { DeleteConfirmationModalProps } from './components/DeleteConfirmationModal/types'
import ManageNodeView from './components/ManageNodeView'
import {
  FORM_FIELD_NAMES,
  ManageNodeFormValues,
} from './components/ManageNodeView/types'
import { transformManageNodeFormVariables } from 'src/ds4/data/browse-menu-tree-gql/utils'
import { isNull, isEmpty } from 'lodash'
import { Box } from 'ds4-beta'

const deepClone = rfdc()

const BrowseMenuTree = (): JSX.Element => {
  const [addSpecialNode, setAddSpecialNode] = useState<
    AddSpecialNodeModalProps
  >(null)
  const [menuTreeState, setMenuTreeState] = useState<TreeColumnProps[]>([])
  const [deleteModalInfo, setDeleteModalInfo] = useState<
    DeleteConfirmationModalProps
  >(null)
  const [menuTree, setMenuTree] = useState<MenuTreeNode[][]>([])
  const [activeNodes, setActiveNodes] = useState<Node[]>([])
  const [mangeDrawerInfo, setMangeDrawerInfo] = useState<Node>(null)
  const [dragDisabled, setDragDisabled] = useState(false)
  const [serverSyncKey, setServerSyncKey] = useState<number>(null)
  const [showLoader, setShowLoader] = useState(false)
  const [menuVariant, setMenuVariant] = useState<MenuVariantById>(null)
  const [locales, setLocales] = useState<string[]>([])
  const [selectedLocale, setSelectedLocale] = useState(DEFAULT_LOCALE)
  const [visibleModalId, setVisibleModalId] = useState<string>(null)
  const activeLang = useSelector(getActiveLanguage)
  const applicationLocales = useSelector(getLocales)
  const userPermissions = useUserPermissions()
  const [menuNodes, setMenuNodes] = useState<MenuNodesAsList[]>(null)
  const showToast = useToast()
  const dispatch = useDispatch()
  window.updateBreadcrumb([
    {
      label: `home`,
      url: '/',
    },
    {
      label: `experiences`,
      url: '/experiences/pages',
    },
    {
      label: `browse menus`,
      url: '/experiences/browse-menu',
    },
    {
      label: `browse menu trees`,
      url: '/experiences/browse-menu-trees',
      disabled: true,
    },
  ])
  const { search } = useLocation()
  const { menuId, versionId } = extract.queryParams(search)

  const showModal = useCallback(
    (modalId: BROWSE_MENU_TREE_MODAL_ID) => setVisibleModalId(modalId),
    [setVisibleModalId]
  )

  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)

  const moduleContext: BrowseMenuTreeModuleContext = useMemo(
    () => ({
      menuId: menuId,
      versionId: versionId,
      closeModal: () => setVisibleModalId(null),
      updatedMenuVariant: updatedMenuVariant => {
        const { status, updatedAt, startDate } = updatedMenuVariant
        setMenuVariant({
          ...menuVariant,
          status,
          updatedAt,
          startDate,
        })
      },
    }),
    [menuId, setVisibleModalId, versionId, menuVariant]
  )

  const { modalOptions } = useBrowseMenuTreeModalOptions({
    moduleContext,
    operations: useBrowseMenuTreeModalOperations({ moduleContext }),
  })

  useEffect(() => {
    if (locales.length) {
      if (locales.includes(activeLang)) {
        setSelectedLocale(activeLang)
      } else {
        const firstLocale = locales[0]
        setSelectedLocale(firstLocale)
        dispatch((CHANGE_ACTIVE_LANGUAGE(firstLocale) as unknown) as AnyAction)
      }
    }
  }, [locales, activeLang, dispatch])

  const handleErrorResponse = (error: ApolloError) => {
    if (
      error?.graphQLErrors?.[0]?.extensions?.errorCode ===
      httpStatusCodes.CONFLICT
    ) {
      setVisibleModalId(BROWSE_MENU_TREE_MODAL_ID.GRAPH_CONFLICT)
    }
  }

  useEffect(() => {
    const status = menuVariant?.status
    const hasOnlyViewerPermissions =
      userPermissions.hasViewerPermissions &&
      !userPermissions.hasEditorPermissions &&
      !userPermissions.hasPublisherPermissions

    const isEditorOnLiveOrScheduledVariant =
      !userPermissions.hasPublisherPermissions &&
      userPermissions.hasEditorPermissions &&
      (status === STATUS.SCHEDULED || status === STATUS.LIVE)

    const cannotDragNodes =
      hasOnlyViewerPermissions || isEditorOnLiveOrScheduledVariant
    setDragDisabled(cannotDragNodes)
  }, [menuVariant])

  const updateNodeInMenuTree = (newNode: MenuNode, depth: number) => {
    // Step 1: Find the updated node element in menuTree state
    // Step 2: Update node with new attributes confirmed by backend
    // Step 3: Update state

    setMenuTree(previousTree => {
      const updatedNode = previousTree[depth].find(
        node => node._id === newNode.id
      )

      const { attributes, images, url, name } = newNode
      updatedNode.images = images
      updatedNode.attributes = attributes
      updatedNode.url = url
      updatedNode.name = name

      // If this is a root node, we will need to update the root node names of all of its children
      if (depth === 0) {
        updatedNode.rootNodeName = name

        for (let i = 1; i < previousTree.length; ++i) {
          previousTree[i].forEach(node => {
            node.rootNodeName = name
          })
        }
      }

      return deepClone(previousTree)
    })
  }

  const handleOnClickCard = async ({
    node,
    depth,
    shouldShowChildren,
    shouldFetchNodes,
  }: {
    node: MenuTreeNode
    depth: number
    shouldShowChildren: boolean
    shouldFetchNodes?: boolean
  }) => {
    const { _id: nodeId, section: isSection, order: clickedNodeOrder } = node
    // Set active nodes, which renders the correct pipelines and blue bordered nodes
    const activeNodesClone = deepClone(activeNodes.slice(0, depth))
    activeNodesClone.push(node as Node)
    setActiveNodes(activeNodesClone)

    isSection && setMenuTree(prev => prev.slice(0, depth + 1))

    if (shouldShowChildren) {
      /* If we don't need to fetch nodes, then the nodes are already available
      due to a previous query. For example, if a section was previously expanded,
     the subnodes have already been fetched */
      if (!shouldFetchNodes) {
        return
      }
      setShowLoader(!isSection)
      const { menuNodesAsList } = await menuNodesAsListService({
        menuNodesAsListQuery,
        parentId: menuId,
        variantId: versionId,
        nodeId,
      })

      const {
        data: {
          menuVariantById: { graph },
        },
      } = await menuVariantByIdQuery({
        variables: {
          parentId: menuId,
          variantId: versionId,
        },
      })

      const nodesToAppend = getNodesToAppend({
        menuNodesAsList,
        menuTree,
        nodeId,
        depth,
        graph,
      })

      //if expanding section for the first time
      if (isSection) {
        setMenuTree(prev => {
          const updatedLevel = deepClone(prev[depth])
          nodesToAppend.forEach(subnode => {
            const subnodeIndex = subnode.order
            subnode.order =
              clickedNodeOrder + (subnodeIndex + 1) * SUBNODE_ORDER_INCREMENT
          })
          updatedLevel.splice(clickedNodeOrder + 1, 0, ...nodesToAppend)
          prev[depth] = updatedLevel
          return [...prev]
        })
      } else {
        const nextLevel = depth + 1
        const sliceIndex = nextLevel + 1

        setMenuTree(previousTree => {
          previousTree[nextLevel] = nodesToAppend
          return previousTree.slice(0, sliceIndex)
        })
      }
      setShowLoader(false)
      return
    }

    // We are collapsing a node that is currently active or we are collapsing a
    // section with an active subnode
    if (!isSection || activeNodes[depth]?.parent === nodeId) {
      setMenuTree(prevMenuTree => prevMenuTree.slice(0, depth + 1))

      //collapsing a section should keep the section card as the active card
      //collapsing a node should not keep the node card as the active card
      if (isSection) {
        setActiveNodes(prevActiveNodes => prevActiveNodes.slice(0, depth + 1))
      } else {
        setActiveNodes(prevActiveNodes => prevActiveNodes.slice(0, depth))
      }
    }
  }

  const [updateMenuNode] = useMutation<UpdateMenuNodeResponse>(UPDATE_MENU_NODE)

  const [createNodeMutation] = useMutation<CreateMenuNodeResponse>(
    CREATE_MENU_NODE
  )
  const [deleteNodesMutation] = useMutation<DeleteMenuNodeResponse>(
    DELETE_MENU_NODE
  )

  const [updateMenuGraphMutation] = useMutation<UpdateMenuGraphResponse>(
    UPDATE_MENU_GRAPH
  )

  const [menuVariantAndNodeListQuery] = useLazyQuery<
    MenuVariantAndNodesListResponse
  >(MENU_VARIANT_AND_NODE_LIST)

  const [menuNodesAsListQuery] = useLazyQuery<MenuNodesAsListResponse>(
    MENU_NODES_AS_LIST
  )

  const [menuVariantByIdQuery] = useLazyQuery<MenuVariantByIdResponse>(
    MENU_VARIANT_BY_ID
  )

  const [listMenusQuery] = useLazyQuery<ListMenuResponse>(LIST_MENUS)

  useEffect(() => {
    void fetchAndConstructInitialMenuTree()
    void fetchAndSetLocales()

    return () => {
      const userDefaultLocale = applicationLocales?.find(
        localeElement => localeElement.isDefault
      )?.code

      dispatch(
        (CHANGE_ACTIVE_LANGUAGE(userDefaultLocale) as unknown) as AnyAction
      )
    }
  }, [])

  const setMenu = (
    menuVariantById: MenuVersionCreateGraphQLResponse['createMenuVariant']
  ) => {
    setServerSyncKey(menuVariantById?.version)
    setMenuTree(
      constructInitialMenuTree({
        menuNodesAsList: menuNodes,
        menuVariantById,
        versionId: menuVariantById.id,
      })
    )
    setMenuVariant(menuVariantById)
  }

  const fetchAndSetLocales = async () => {
    const { data, refetch } = await listMenusQuery({
      variables: {
        input: {
          isArchived: false,
          filter: {
            searchTerm: '',
            channels: [],
            locales: [],
            status: [],
          },
        },
      },
    })
    const currentMenuLocales = getCurrentMenuLocales({ data, menuId })

    if (currentMenuLocales == null) {
      setShowLoader(true)
      setTimeout(() => {
        refetch()
          .then(({ data: refetchedData }) => {
            const refetchedCurrentMenuLocales = getCurrentMenuLocales({
              data: refetchedData,
              menuId,
            })
            refetchedCurrentMenuLocales &&
              setLocales(refetchedCurrentMenuLocales)
          })
          .catch(e => {
            console.error(e)
            showToast({
              id: 'error-toast',
              label: MESSAGES.ERROR_FETCHING_LOCALES,
              variant: 'error',
            })
          })
          .finally(() => {
            setShowLoader(false)
          })
      }, REFETCH_MENU_LIST_WAIT_TIME)
    } else {
      setLocales(currentMenuLocales)
    }
  }

  const fetchAndConstructInitialMenuTree = async () => {
    const {
      data: { menuNodesAsList, menuVariantById },
    } = await menuVariantAndNodeListQuery({
      variables: { variantId: versionId, parentId: menuId },
    })

    setMenuNodes(menuNodesAsList)
    setMenuVariant(menuVariantById)
    setServerSyncKey(menuVariantById.version)
    setMenuTree(
      constructInitialMenuTree({
        menuNodesAsList,
        menuVariantById,
        versionId,
      })
    )
    dispatch(EDITOR_SET_VERSION_STATUS(menuVariantById?.status))
  }

  const handleUpdateTreeNode = async ({
    node,
    depth,
  }: {
    node: Node
    depth: number
    nodeParentId: string
  }) => {
    const updatedName = node?.label
    if (!updatedName) {
      return
    }

    await handleMutation<UpdateMenuNodeResponse>({
      mutation: updateMenuNode,
      mutationOptions: {
        variables: {
          input: {
            variantId: versionId,
            nodeId: node.id,
            parentId: menuId,
            url: node?.url,
            name: { ...node?.name, [selectedLocale]: updatedName },
            attributes: node?.attributes?.map(({ kind, value }) => ({
              kind,
              value,
            })),
            images: node?.images,
          },
        },
      },
      onSuccess: response => {
        const {
          data: { updateNode },
        } = response
        setEditableNodeStatus(node, false)
        updateNodeInMenuTree(updateNode, depth)
        showToast({
          id: 'success-toast',
          label: MESSAGES.getUpdatedSuccess(updatedName),
          isDismissable: true,
        })
      },
      onError: () => {
        showToast({
          id: 'error-toast',
          variant: 'error',
          label: MESSAGES.getUpdatedError(updatedName),
          isDismissable: true,
        })
      },
    })
  }

  const handleManageUpdateNode = async (formValues: ManageNodeFormValues) => {
    const { menuId, versionId } = extract.queryParams(search)
    const { column: depth } = findColumn(menuTreeState, mangeDrawerInfo?.id)

    formValues[FORM_FIELD_NAMES.parentId] = menuId
    formValues[FORM_FIELD_NAMES.variantId] = versionId

    const transformedVariables = transformManageNodeFormVariables(formValues)

    await handleMutation<UpdateMenuNodeResponse>({
      mutation: updateMenuNode,
      mutationOptions: {
        variables: transformedVariables,
      },
      onSuccess: response => {
        setMangeDrawerInfo(null)
        const {
          data: { updateNode },
        } = response

        updateNodeInMenuTree(updateNode, depth)
        showToast({
          id: 'success-toast',
          label: MESSAGES.getUpdatedSuccess(
            formValues.nodeName[selectedLocale]
          ),
          isDismissable: true,
        })
      },
      onError: () => {
        showToast({
          id: 'error-toast',
          variant: 'error',
          label: MESSAGES.getUpdatedError(formValues.nodeName[selectedLocale]),
          isDismissable: true,
        })
      },
    })
  }

  const handleCreate = async ({
    depth,
    name,
    nodeParentId = versionId,
    nodeType,
    order,
  }: HandleCreateProps) => {
    const isSection = nodeType === NODE_TYPE.SECTION
    await handleMutation<CreateMenuNodeResponse>({
      mutation: createNodeMutation,
      mutationOptions: {
        variables: {
          input: {
            variantId: versionId,
            parentId: menuId,
            nodeParentId,
            section: isSection,
            locale: selectedLocale,
            name,
            version: serverSyncKey,
          },
        },
      },
      onSuccess: response => {
        const responseData = response?.data?.createNode
        const updatedGraph = responseData?.graph
        const addedNode = responseData?.updatedNode

        setServerSyncKey(responseData?.version)

        const isLocalizationEnabled = !isEmpty(locales)

        const nodeNames: Names = {}

        if (isLocalizationEnabled) {
          locales.forEach(
            localeCode =>
              (nodeNames[localeCode] =
                localeCode === selectedLocale ? name : EMPTY_STRING)
          )
        } else {
          nodeNames[DEFAULT_LOCALE] = name
        }

        const newNode: MenuTreeNode = {
          _id: addedNode.id,
          parent: nodeParentId,
          name: nodeNames,
          rootNodeName: depth === 0 ? nodeNames : activeNodes[0].name,
          section: isSection,
          url: addedNode.url,
          disabled: !updatedGraph[addedNode.id].isActive,
          order: order != null ? order : menuTree[depth].length,
          images: [],
          attributes: [],
        }

        setMenuTree(prev => {
          const updatedLevel = deepClone(prev[depth])
          updatedLevel.push(newNode)
          prev[depth] = updatedLevel
          return [...prev]
        })
        showToast({
          id: 'success-toast',
          label: MESSAGES.getCreatedSuccess(name),
          isDismissable: true,
        })
      },
      onError: error => {
        showToast({
          id: 'error-toast',
          variant: 'error',
          label: MESSAGES.getCreatedError(name),
          isDismissable: true,
        })
        handleErrorResponse(error)
      },
    })
  }

  const handleRemoveItem = async (
    { id: removalNodeId, name, section }: Node,
    depth: number
  ): Promise<void> => {
    const genericNodeLabel = section ? 'Section' : 'Node'

    await handleMutation<DeleteMenuNodeResponse>({
      mutation: deleteNodesMutation,
      mutationOptions: {
        variables: {
          input: {
            parentId: menuId,
            variantId: versionId,
            nodeId: removalNodeId,
          },
        },
      },
      onSuccess: response => {
        setDeleteModalInfo(null)
        setServerSyncKey(response.data.deleteNodes.version)
        setMenuTree(
          removeNodeFromTree({
            menuTree,
            depth,
            removalNodeId,
            activeNodes,
            setActiveNodes,
          })
        )
        showToast({
          id: 'success-toast',
          label: MESSAGES.getDeletedSuccess(
            name[selectedLocale] || genericNodeLabel
          ),
          isDismissable: true,
        })
      },
      onError: error => {
        showToast({
          id: 'error-toast',
          variant: 'error',
          label: MESSAGES.getDeletedError(
            name[selectedLocale] || genericNodeLabel
          ),
          isDismissable: true,
        })
        setDeleteModalInfo(null)
        handleErrorResponse(error)
      },
    })
  }

  const handleNodeVisibility = async (
    { _id, section: isSection }: MenuTreeNode,
    isActive: boolean,
    depth: number
  ) => {
    setShowLoader(true)
    //fetch current graph
    const {
      data: {
        menuVariantById: { graph, version },
      },
    } = await menuVariantByIdQuery({
      variables: {
        parentId: menuId,
        variantId: versionId,
      },
    })
    //generate updated graph and update graph BE
    const updatedGraph = toggleNodeVisibilityInGraph({
      graph,
      _id,
      toggleTo: !isActive,
    })
    await handleMutation({
      mutation: updateMenuGraphMutation,
      mutationOptions: {
        variables: {
          input: {
            parentId: menuId,
            variantId: versionId,
            graph: updatedGraph,
            version,
          },
        },
      },
      //update FE menuTree state
      onSuccess: response => {
        setShowLoader(false)
        setServerSyncKey(response?.data?.updateMenuGraph?.version)
        const updatedMenuTree = toggleNodeVisibility({
          depth,
          menuTree,
          _id,
          toggleTo: isActive,
          isSection,
        })
        setMenuTree(updatedMenuTree)
        const toggleActiveNodeIndex = activeNodes.findIndex(
          activeNode => activeNode._id === _id
        )
        if (toggleActiveNodeIndex >= 0) {
          setActiveNodes(prevActiveNodes => {
            const activeNodesClone = deepClone(prevActiveNodes)
            activeNodesClone.forEach((activeNode, index) => {
              if (index >= toggleActiveNodeIndex) activeNode.disabled = isActive
            })
            return activeNodesClone
          })
        }
      },
      onError: error => {
        setShowLoader(false)
        handleErrorResponse(error)
      },
    })
  }

  const onDragEnd = async (event, treeViewData, setTreeViewData) => {
    try {
      const { active, over } = event
      const overId = over?.id

      const {
        column: activeContainer,
        position: positionInActiveContainer,
        index: activeIndex,
      } = findColumn(treeViewData, active.id)

      const {
        column: overContainer,
        position: positionInOverContainer,
        index: overIndex,
      } = findColumn(treeViewData, overId)

      if (!overId || activeContainer !== overContainer) {
        return false
      }

      if (
        active?.id === over?.id ||
        (active?.data?.current?.section &&
          over?.data?.current?.parent !== active?.data?.current?.parent)
      ) {
        return false
      }

      if (
        overContainer === -1 ||
        activeContainer === -1 ||
        isNull(overContainer) ||
        isNull(activeContainer)
      ) {
        return false
      }

      const sourceOrder = findNodeIndexByDepth(
        [...menuTree[activeContainer]],
        active?.data?.current?.id
      )
      const overOrder = findNodeIndexByDepth(
        [...menuTree[activeContainer]],
        over?.data?.current?.id
      )

      onDragEndUpdateMenyTree({
        source: { droppableId: activeContainer, index: sourceOrder },
        destination: { droppableId: activeContainer, index: overOrder },
      })

      /**
       * This gets called when you are moving nodes from column to column but not within node
       *  with multiple children
       */
      if (
        positionInActiveContainer.length === 0 &&
        positionInOverContainer.length === 0
      ) {
        setTreeViewData(treeView => {
          if (activeContainer === overContainer) {
            const treeViewCopy = [...treeView]
            treeViewCopy[overContainer] = {
              ...treeViewCopy[overContainer],
              items: arrayMove(
                treeViewCopy[overContainer].items,
                activeIndex,
                overIndex
              ) as Array<ListItemType>,
            }
            return treeViewCopy
          } else {
            delete active.data?.current?.sortable
            return moveBetweenContainers(
              treeView,
              activeContainer,
              activeIndex,
              overContainer,
              overIndex,
              active.data?.current
            )
          }
        })
      }

      /**
       * This gets called when you are moving nodes from column which has parent node
       * to different column
       */

      if (
        positionInActiveContainer.length > 0 &&
        positionInOverContainer.length === 0
      ) {
        const activeItemId = active?.data?.current?.id
        const overIndex = over?.data?.current?.sortable?.index || 0
        const treeViewCopy = [...treeViewData]

        const column = treeViewCopy[positionInActiveContainer[0]]
        removeFromMultiArray(column, activeItemId)

        delete active.data?.current?.sortable
        treeViewCopy[overContainer] = insertAtIndex(
          treeViewCopy[overContainer],
          overIndex,
          active?.data?.current
        )

        setTreeViewData(treeViewCopy)
      }

      /**
       * This gets called when you are moving from column to place with nodes that has parent
       */

      if (
        positionInActiveContainer.length === 0 &&
        positionInOverContainer.length > 0
      ) {
        const treeViewCopy = [...treeViewData]

        const column = treeViewCopy[positionInOverContainer[0]].items
        delete active.data?.current?.sortable
        addInMultiArray(column, overId, active?.data?.current as ListItemType)

        treeViewCopy[activeContainer] = removeAtIndex(
          treeViewCopy[activeContainer],
          activeIndex
        )

        setTreeViewData(treeViewCopy)
      }

      return true
    } catch (error) {
      console.log({ error })
      return false
    }
  }

  const onDragEndUpdateMenyTree = async ({
    source: { droppableId: srcDroppableId, index: srcIndex },
    destination,
  }: {
    destination: { droppableId: number; index: number }
    source: { droppableId: number; index: number }
  }) => {
    if (!destination || srcDroppableId !== destination?.droppableId) {
      return
    }
    const { droppableId: destDroppableId, index: destIndex } = destination
    if (
      isDropInvalid({
        destination,
        destDroppableId,
        srcDroppableId,
        destIndex,
        srcIndex,
      })
    )
      return

    const menuTreeBeforeUpdate = deepClone(menuTree)
    const { updatedMenuTree, newEdges } = getMenuTreeAfterDragAndDrop({
      menuTree,
      destDroppableId,
      srcIndex,
      destIndex,
    })

    setMenuTree(updatedMenuTree)

    //start loader and BE calls
    try {
      setShowLoader(true)
      //fetch current graph
      const {
        data: {
          menuVariantById: { graph, version },
        },
      } = await menuVariantByIdQuery({
        variables: {
          parentId: menuId,
          variantId: versionId,
        },
      })

      //updated graph and set new graph
      const updatedGraph = deepClone(graph)
      for (const [nodeId, updatedEdges] of Object.entries(newEdges)) {
        if (!updatedGraph[nodeId]) {
          setVisibleModalId(BROWSE_MENU_TREE_MODAL_ID.GRAPH_CONFLICT)
          setMenuTree(menuTreeBeforeUpdate)
          return
        }
        updatedGraph[nodeId].edges = updatedEdges
      }

      await handleMutation({
        mutation: updateMenuGraphMutation,
        mutationOptions: {
          variables: {
            input: {
              parentId: menuId,
              variantId: versionId,
              graph: updatedGraph,
              version,
            },
          },
        },
        onSuccess: response => {
          const responseData = response?.data?.updateMenuGraph
          setServerSyncKey(responseData?.version)
        },
      })
    } catch (err) {
      showToast({
        id: 'error-message',
        label: extract?.errorString(err),
        variant: 'error',
        isDismissable: true,
      })
      setMenuTree(menuTreeBeforeUpdate)
    } finally {
      //stop loader
      setShowLoader(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(EDITOR_RESET())
    }
  }, [dispatch])

  const setEditableNodeStatus = (nodeData: Node, isEditable: boolean) => {
    setMenuTreeState(tree => updateEditableNode(tree, nodeData, isEditable))
  }

  const getcolumnMenuOptions = (depth: number) => [
    {
      label: 'Add new node',
      onClick: (column: TreeColumnProps) =>
        setAddSpecialNode({
          type: NODE_TYPE.NODE,
          onCancel: () => setAddSpecialNode(null),
          onYesClicked: async (label: string, clearInput) => {
            setAddSpecialNode(prev => ({ ...prev, isLoading: true }))
            await handleCreate({
              depth,
              name: label,
              nodeType: NODE_TYPE?.NODE,
              nodeParentId: column?.id?.toString(),
            }).finally(() => {
              setAddSpecialNode(null)
              clearInput()
            })
          },
          show: true,
        }),
    },
  ]

  const getMenuOptionsColumn = (depth: number) => [
    {
      id: 'manage-node',
      label: OPTIONS.getManageTitle(false ? 'Section' : 'Node'),
      onClick: (node: Node) => setMangeDrawerInfo(node),
    },
    {
      id: OPTIONS.RENAME,
      label: OPTIONS.RENAME,
      dataTestId: `option-${OPTIONS.RENAME}`,
      onClick: (node: Node) => setEditableNodeStatus(node, true),
    },
    {
      id: OPTIONS.HIDE,
      label: OPTIONS.HIDE,
      onClick: async (node: Node) =>
        handleNodeVisibility(node, !node?.disabled, depth),
    },
    {
      id: OPTIONS.REMOVE,
      label: OPTIONS.REMOVE,
      dataTestId: `option-${OPTIONS.REMOVE}`,
      onClick: (node: Node) =>
        setDeleteModalInfo({
          show: true,
          isSection: node?.section,
          onCancel: () => setDeleteModalInfo(null),
          onYesClicked: async () => {
            setDeleteModalInfo(prev => ({ ...prev, isLoading: true }))
            await handleRemoveItem(node, depth)
          },
        }),
    },
  ]

  const getMappedTreeDS4 = (menuTree: MenuTreeNode[][]): TreeColumnProps[] => {
    return menuTree?.map((level, depth: number) => {
      const levelHierarchy = organizeDataIntoHierarchy(level)
      const parentNode = depth >= 1 ? activeNodes[depth - 1] : null
      const levelName =
        parentNode?.name[selectedLocale] || DEFAULT_LABELS.getLevel(depth)

      return {
        id: level?.[0]?.parent || parentNode?.id,
        label: levelName,
        showAddItem: hasEditAccess,
        showMoreActions: hasEditAccess,
        columnMenuOptions: getcolumnMenuOptions(depth),
        menuOptions: getMenuOptionsColumn(depth),
        selected: activeNodes?.[depth]?.id,
        onItemAdd: async (newNode: Node, parentId: string) =>
          await handleCreate({
            depth,
            name: newNode?.label,
            nodeType: NODE_TYPE.SECTION,
            nodeParentId: parentId,
          }).then(() => true),
        onItemEdit: (node: Node, parentId: string) =>
          handleUpdateTreeNode({
            node,
            depth,
            nodeParentId: parentId,
          }),
        onItemClick: (node: Node) =>
          handleOnClickCard({
            node,
            depth,
            shouldFetchNodes: node?.shouldFetchNodes,
            shouldShowChildren: activeNodes[depth]?.id !== node._id,
          }),
        onItemEditCancel: (nodeData: Node) =>
          setEditableNodeStatus(nodeData, false),
        items: generateMenuItems(levelHierarchy, selectedLocale),
      }
    })
  }

  useEffect(() => {
    const updatedTree = getMappedTreeDS4(menuTree)
    setMenuTreeState(updatedTree)
  }, [menuTree, activeNodes, serverSyncKey, selectedLocale])

  return (
    <>
      <ContentModalModule
        visibleModalId={visibleModalId}
        modalOptions={modalOptions}
      />
      <StyledContainer>
        {showLoader && <StyledSpinner />}
        <Header
          menuId={menuId}
          menuVersionId={versionId}
          locales={locales}
          menuVariant={menuVariant}
          selectedLocale={selectedLocale}
          showModal={showModal}
          setMenu={setMenu}
        />
        <Box margin={{ left: 7, right: 7, bottom: 6 }}>
          <GridRow padding={false}>
            <GridCol>
              <TreeView
                data={menuTreeState || []}
                draggable={
                  !dragDisabled
                    ? {
                        onDragEnd: (event, sortableData, setSortableData) =>
                          onDragEnd(event, sortableData, setSortableData),
                      }
                    : undefined
                }
              />
            </GridCol>
          </GridRow>
        </Box>
        <AddNodeModal {...addSpecialNode} />
        <DeleteConfirmationModal
          isSection={deleteModalInfo?.isSection}
          onYesClicked={deleteModalInfo?.onYesClicked}
          show={deleteModalInfo?.show}
          isLoading={deleteModalInfo?.isLoading}
          onCancel={() => setDeleteModalInfo(null)}
        />
        {mangeDrawerInfo ? (
          <ManageNodeView
            node={mangeDrawerInfo}
            onCancel={() => setMangeDrawerInfo(null)}
            onSave={handleManageUpdateNode}
            visible={Boolean(mangeDrawerInfo)}
            locales={locales}
            selectedLocale={selectedLocale}
          />
        ) : null}
      </StyledContainer>
    </>
  )
}

export default BrowseMenuTree
