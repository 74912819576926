import React from 'react'
import { Modal, ButtonProps } from 'ds4-beta'
import { BROWSE_MENU_TREE_DELETE_MODAL } from 'src/constants'
import { DeleteConfirmationModalProps } from './types'

const DeleteConfirmationModal = ({
  show,
  isLoading,
  isSection,
  onCancel,
  onYesClicked,
}: DeleteConfirmationModalProps) => {
  const { getContent, getTitle } = BROWSE_MENU_TREE_DELETE_MODAL

  const headerText = getTitle(isSection ? 'Section' : 'Node')
  const description = getContent(isSection ? 'Section' : 'Node')

  const footerButtons = [
    {
      onClick: onCancel,
      text: 'Cancel',
      variant: 'tertiary',
    },
    {
      onClick: onYesClicked,
      text: 'Delete',
      icon: 'Trash',
      variant: 'destructive',
      isLoading,
    },
  ] as ButtonProps[]

  return (
    <Modal
      dataTestid='delete-modal'
      size='small'
      isVisible={show}
      headerText={headerText}
      description={description}
      footerButtons={footerButtons}
      onBackdropClick={onCancel}
      onClose={onCancel}
    />
  )
}

export default DeleteConfirmationModal
