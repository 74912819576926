import styled from 'styled-components'
import { theme } from 'ds4-beta'

export const StyledDate = styled.div`
  ${theme.typography.body1.regular}
`
export const StyledTime = styled.div`
  ${theme.typography.body2.regular}
  color: ${theme.color.grey[600]}
`

export const StyledTableText = styled.div`
  ${theme.typography.body1.regular};
`
