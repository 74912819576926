import { NAVIGATION } from 'src/constants'

export enum TABLE_COLUMN_OPTIONS {
  NAME = 'name',
  LOCALE = 'locale',
  CHANNEL = 'channel',
  STATUS = 'status',
  SCHEDULED_AT = 'scheduledAt',
  UPDATED_AT = 'updatedAt',
  PAGE_COUNT = 'pageCount',
  PREFIX = 'prefix',
  ACTIONS = ' ',
}

export const TABLE_COLUMN_TITLES = {
  [TABLE_COLUMN_OPTIONS.NAME]: 'Name',
  [TABLE_COLUMN_OPTIONS.LOCALE]: 'Locales',
  [TABLE_COLUMN_OPTIONS.CHANNEL]: 'Channels',
  [TABLE_COLUMN_OPTIONS.STATUS]: 'Status',
  [TABLE_COLUMN_OPTIONS.SCHEDULED_AT]: 'Scheduled',
  [TABLE_COLUMN_OPTIONS.UPDATED_AT]: 'Last update',
  [TABLE_COLUMN_OPTIONS.PAGE_COUNT]: 'No. of pages',
  [TABLE_COLUMN_OPTIONS.PREFIX]: 'Prefix',
  [TABLE_COLUMN_OPTIONS.ACTIONS]: ' ',
}

export const STATUS_MAPPING = {
  Draft: 'alert',
  Live: 'success',
  Active: 'success',
  Inactive: 'default',
  Archived: 'default',
}

export const ACTIONS_ICONS_MAPPING = {
  'Edit': 'Pencil',
  'Edit details': 'Pencil',
  'Edit draft': 'Pencil',
  'Delete': 'Trash',
  'New version': 'Heirarchy',
  'Make a copy': 'Copy',
  'View menu': 'View',
  'View page': 'View',
  'Preview': 'View',
  'Unarchive': 'Substract',
}

export const MORE_ACTIONS = {
  [NAVIGATION.PAGES]: ['Deactivate', 'Archive', 'Delete', 'Activate'],
  [NAVIGATION.GLOBAL_ELEMENTS]: ['Archive', 'Delete'],
}

export const NULL_ENTRY = '-'
