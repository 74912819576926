import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { StyledErrorMessage } from '../styles'
import getHasEditAccessBasedOnVersionStatus from 'lib/getHasEditAccessBasedOnVersionStatus'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import isPlainObject from 'lodash/isPlainObject'
import noop from 'lodash/noop'
import size from 'lodash/size'
import { typesById } from '../allTypes'
import { useSelector } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'

const AnyType = props => {
  const {
    paths,
    level,
    isDeleteable,
    expandedProperty,
    setExpandedProperty,
    descriptor,
    value,
    onChange,
  } = props
  const key = useMemo(() => paths.join('.'), [paths])

  const { hasEditorPermissions, hasPublisherPermissions } = useUserPermissions()
  const versionStatus = useSelector(getVersionStatus)
  const hasEditAccess = getHasEditAccessBasedOnVersionStatus(
    { hasEditorPermissions, hasPublisherPermissions },
    versionStatus
  )

  if (!isPlainObject(descriptor) || size(descriptor) === 0) return null

  const Cmp = typesById[descriptor.type]

  if (!Cmp) {
    return (
      <StyledErrorMessage>
        This {descriptor.type ?? 'type'} is not supported. Please update
        descriptors with valid type.
      </StyledErrorMessage>
    )
  }

  return (
    <>
      <Cmp
        key={key}
        {...(['Array', 'Component'].includes(descriptor.type) && {
          level,
          expandedProperty,
          setExpandedProperty,
        })}
        isDeleteable={isDeleteable}
        descriptor={descriptor}
        paths={paths}
        value={value}
        onChange={onChange}
        hasEditAccess={hasEditAccess}
      />
    </>
  )
}

AnyType.defaultProps = {
  level: 1,
  expandedProperty: '',
  isDeleteable: true,
  setExpandedProperty: noop,
}

AnyType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  paths: PropTypes.array.isRequired,
  isDeleteable: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  level: PropTypes.number,
  expandedProperty: PropTypes.string,
  setExpandedProperty: PropTypes.func,
}

export default AnyType
