import { ALLOW_NESTED_COMPONENTS, DESCRIPTOR_DETAILS } from 'src/constants'
import { DescriptorInputProps, EventTargetProps } from './types'
import { Box, Input, RadioGroup, Textarea } from 'ds4-beta'
import React from 'react'
import { StyledInputField } from './styles'
import noop from 'lodash/noop'

const {
  HEADING: { FORMAT, TYPE, ID, LABEL, DESCRIPTION, ATTRIBUTES },
  INPUT_TYPES: { TEXT, DROPDOWN, TEXTAREA },
} = DESCRIPTOR_DETAILS

const DescriptorInput: React.FunctionComponent<DescriptorInputProps> = ({
  kind,
  testId = '',
  innerTestId = '',
  label,
  value,
  onChange = noop,
  isDisabled,
  error = false,
  errorMessage = '',
  descriptor = {},
  setDescriptor = noop,
  options = [],
  required,
}) => {
  const name = {
    [FORMAT]: 'format',
    [TYPE]: 'type',
    [ID]: 'id',
    [LABEL]: 'label',
    [DESCRIPTION]: 'description',
    [ATTRIBUTES]: 'attributes',
  }

  const mapTargetAndValue = (target, label) => {
    return {
      name: name[label],
      value: target.value,
    }
  }

  const renderTextInput = () => (
    <Input
      inputProps={{
        value: '' + value,
        disabled: isDisabled,
        dataTestid: `descriptor-${name[label]}-field`,
        onChange: ({ target }) =>
          onChange(mapTargetAndValue(target, label), descriptor, setDescriptor),
      }}
      width='100%'
      label={label}
      message={error && errorMessage}
      messageType={error ? 'error' : 'normal'}
      required={required}
    />
  )
  const renderDropdownInput = () => {
    return (
      <RadioGroup
        onChange={({ target }) => {
          const t = {
            name: ALLOW_NESTED_COMPONENTS,
            value: Boolean(target.value),
          }
          onChange(t, descriptor, setDescriptor)
        }}
        label={label}
        value={'' + value?.name}
        name={ALLOW_NESTED_COMPONENTS}
        options={options.map((item, idx) => {
          return {
            id: '' + idx,
            label: item?.name,
            value: item?.name,
            disabled: isDisabled,
          }
        })}
      />
    )
  }

  const renderTextareaInput = () => {
    return (
      <Textarea
        label={label}
        inputProps={{
          value: '' + value,
          disabled: isDisabled,
          dataTestid: innerTestId,
          onChange: ({ target }: { target: EventTargetProps }) =>
            onChange(
              mapTargetAndValue(target, label),
              descriptor,
              setDescriptor
            ),
        }}
        width='100%'
        rows={label === 'Attributes' ? 8 : 3}
      />
    )
  }

  const descriptorInput = {
    [TEXT]: renderTextInput(),
    [DROPDOWN]: renderDropdownInput(),
    [TEXTAREA]: renderTextareaInput(),
  }

  return (
    <StyledInputField isDisabled={isDisabled}>
      <Box margin={{ top: 4, bottom: 4 }}>{descriptorInput[kind]}</Box>
    </StyledInputField>
  )
}

export default DescriptorInput
