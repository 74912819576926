type BadgeStatus = {
  version: 'DRAFT' | 'ENDED' | 'LIVE'
  variant: 'alert' | 'default' | 'success'
}

export const VERSION_BADGE_STATUS: BadgeStatus[] = [
  {
    version: 'LIVE',
    variant: 'success',
  },
  {
    version: 'DRAFT',
    variant: 'alert',
  },
  {
    version: 'ENDED',
    variant: 'default',
  },
]
