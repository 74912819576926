import React from 'react'
import { PaginationCaption } from './styles'
import { ContentTableProps } from './types'
import { isEmpty } from 'lodash'
import { NAVIGATION } from 'src/constants'

const PaginationCaptionTable = ({
  data,
  loading,
  paginationProps: { itemsPerPage, currentPage, totalItems },
  noResults: { searchTerm, contentType },
}: ContentTableProps) => {
  if (
    (!loading && searchTerm === '' && isEmpty(data)) ||
    loading ||
    contentType === NAVIGATION.MENU
  ) {
    return null
  }

  const startLimit = totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1
  const endLimit =
    totalItems === 0 ? 0 : Math.min(totalItems, currentPage * itemsPerPage)

  return (
    <PaginationCaption>
      Showing {startLimit} - {endLimit} of {totalItems}
    </PaginationCaption>
  )
}

export default PaginationCaptionTable
