import { generateTableColumns, getEmptyTableMessage } from './utils'
import { ContentTableProps } from './types'
import { NO_RECORDS_FOUND } from 'src/constants'
import React, { useMemo } from 'react'
import { PagesModuleTabs } from 'src/ds4/data/pages-list/types'
import { EmptyPage, GridCol, GridRow, PaginationProps, Table } from 'ds4-beta'
import isEmpty from 'lodash/isEmpty'

const ContentTableModule = React.memo<ContentTableProps>(
  ({
    data,
    columns,
    loading,
    paginationProps: {
      itemsPerPage,
      currentPage,
      totalItems,
      handlePagination,
    },
    noResults,
    moduleTab,
    hasCheckedFilters,
  }) => {
    if (isEmpty(columns)) {
      return null
    }
    const { searchTerm = '', isArchivedTab = false, contentType } = noResults

    const tableCompatibleColumns = generateTableColumns(columns)
    const emptyTableMessage = getEmptyTableMessage({
      contentType,
      isArchivedTab,
    })

    const paginationProps: PaginationProps = useMemo(
      () => ({
        perPage: itemsPerPage,
        totalRecords: totalItems,
        activePageNumber: currentPage,
        handlePagination,
      }),
      [totalItems, currentPage]
    )

    if (!loading && searchTerm === '' && isEmpty(data) && !hasCheckedFilters) {
      return (
        <EmptyPage
          dataTestid='empty-table-message'
          headerText={emptyTableMessage}
          description={NO_RECORDS_FOUND}
        />
      )
    }

    return (
      <GridRow padding={false}>
        <GridCol>
          <Table
            columns={tableCompatibleColumns}
            data={data}
            loading={loading}
            perPageRecords={itemsPerPage}
            showPagination={totalItems > itemsPerPage}
            customPaginationProps={paginationProps}
            canHaveChildren={moduleTab !== PagesModuleTabs.PAGE_TYPES}
          />
        </GridCol>
      </GridRow>
    )
  }
)

export default ContentTableModule
