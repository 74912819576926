import React, { FunctionComponent, useState } from 'react'
import { ContentModalOptionsProps } from './types'
import { Modal } from 'ds4-beta'

const AlertModal: FunctionComponent<ContentModalOptionsProps> = ({
  title,
  size,
  config,
}) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const { content, confirmButton, cancelButton } = config
  const handleConfirmClick = (e: Event) => {
    const promise = confirmButton.onClick(e) as Promise<unknown>
    const isPromise = !!promise?.then
    if (isPromise) {
      setIsConfirming(true)
      promise.finally(() => {
        setIsConfirming(false)
      })
    }
  }
  return (
    <Modal
      headerText={title}
      size={size}
      isVisible={true}
      onClose={cancelButton.onClick as any}
      onBackdropClick={cancelButton.onClick as any}
      footerButtons={[
        {
          onClick: cancelButton.onClick as any,
          variant: 'tertiary_dark',
          dataTestid: 'alert-modal-cancel-button',
          text: cancelButton.text,
        },
        {
          text: confirmButton.text,
          variant: 'primary',
          onClick: handleConfirmClick as any,
          isDisabled: isConfirming,
          dataTestid: 'alert-modal-confirm-button',
        },
      ]}
    >
      <div data-testid='content-list-module-alert-modal-content'>{content}</div>
    </Modal>
  )
}

export default AlertModal
