import styled, { keyframes } from 'styled-components'
import IconDelete from 'assets/icons/IconDelete'
import { StyledItemOptionsProps } from './types'
import { theme as themeUi } from '@teamfabric/copilot-ui'
import { theme } from 'ds4-beta'

export const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`

export const StyledComponentList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  user-select: none;
  animation: ${slideInLeft} 200ms ease-out;
  margin-bottom: ${theme.space[4]};
`

export const StyledComponentItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: ${theme.space[4]};
  background: ${theme.color.grey[0]};
  cursor: pointer;
  border: 1px solid ${theme.color.grey[100]};
  border-radius: ${theme.space[1]};
  margin-bottom: ${theme.space[2]};
  &.dragging-helper-class {
    list-style: none;
    cursor: grabbing;
    pointer-events: auto !important;
  }

  &:hover {
    & svg.icon-delete {
      display: grid;
    }
  }
`

export const StyledComponentItemOptions = styled.div<StyledItemOptionsProps>`
  display: grid;
  grid-template-columns: ${({ isGlobalComponentEditor }) =>
    isGlobalComponentEditor ? '24px' : `repeat(2, 24px)`};
`

// https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
export const StyledComponentItemLabel = styled.div`
  
  ${theme.typography.h5};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledIconDelete = styled(IconDelete)`
  display: none;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  fill: ${themeUi.palette.brand.primary.gray};
`
