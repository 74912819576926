import { Box, Icon, Tooltip, theme } from 'ds4-beta'
import React from 'react'

interface LockIconProps {
  tooltipMessage?: string
  message?: string
}

const LockIcon = ({
  tooltipMessage = 'Locked',
  message = 'Locked',
}: LockIconProps): JSX.Element => {
  return (
    <Box dataTestid='lock-icon'>
      <Tooltip
        id='edit-content-locked-tooltip'
        placement='bottom'
        text={tooltipMessage}
        dataTestid='edit-content-locked-tooltip'
      >
        <Box flex={{ justifyContent: 'start', alignItems: 'center' }} gap={1}>
          <Icon
            iconName='Lock'
            size={16}
            color={theme.color.grey[500] as string}
          />
          <Box as='h5' color={theme.color.grey[500]}>
            {message}
          </Box>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default LockIcon
