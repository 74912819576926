import { Button, Tooltip } from 'ds4-beta'
import { EditorStateProps, SelectedRouteProps } from 'store/types'
import {
  MESSAGES,
  NO_CHANNELS_ASSIGNED_TOOLTIP,
  PREVIEW_CHANNELS_LABEL,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledDropdown,
  StyledDropdownList,
  StyledDropdownListItem,
} from 'src/ds4/components/StyledDropdown/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Channels } from './types'
import { EDITOR_UPDATE_CHANNEL_URL } from 'src/ds4/modules/editor/actions'
import ValidUrl from 'valid-url'
import { getSelectedRoute } from 'src/ds4/modules/editor/selectors'
import isPlainObject from 'lodash/isPlainObject'
import { retrieveSelectedRouteFirstChannel } from 'src/ds4/modules/editor/utils'
import { showToast } from 'src/ds4/components/ToastSnackbarContainer'
import size from 'lodash/size'
import useClickOutside from 'hooks/useClickOutside'

const ChannelPreviewDropdown = (): JSX.Element => {
  const dropdownRef = useRef(null)
  const dispatch = useDispatch()
  const selectedRoute = useSelector<EditorStateProps, SelectedRouteProps>(
    getSelectedRoute
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string | null>(
    retrieveSelectedRouteFirstChannel(selectedRoute)?.label ?? null
  )
  const [channelsList, setChannelsList] = useState<Channels[]>([])
  const isDisabled = !size(channelsList) || !isPlainObject(channelsList[0])

  useEffect(() => {
    const channels =
      selectedRoute.page?.channels || selectedRoute.channels || []
    setChannelsList(channels)
    setSelected(retrieveSelectedRouteFirstChannel(selectedRoute)?.label ?? null)
  }, [selectedRoute])

  useClickOutside(dropdownRef, () => setIsOpen(false))

  const handleToggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const handleChannelSelect = (channel: Channels) => {
    setSelected(channel?.label)
    setIsOpen(false)

    const channelURL = channel?.channelURL || channel?.url
    if (!channelURL || !ValidUrl.isUri(channelURL)) {
      void showToast({
        message: MESSAGES.EMPTY_CHANNEL_URL_ERROR,
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    } else {
      dispatch(EDITOR_UPDATE_CHANNEL_URL(channelURL))
    }
  }

  return (
    <StyledDropdown ref={dropdownRef}>
      {isDisabled && (
        <Tooltip
          data-testid='no-assigned-channels-tooltip'
          text={NO_CHANNELS_ASSIGNED_TOOLTIP}
        >
          <Button
            icon={isOpen ? 'ArrowUp' : 'ArrowDown'}
            data-testid='preview-channels-button'
            text={selected ?? PREVIEW_CHANNELS_LABEL}
            variant='tertiary'
            onClick={handleToggleDropdown}
            isDisabled={isDisabled}
          />
        </Tooltip>
      )}
      {isOpen && (
        <StyledDropdownList>
          {channelsList.map((channel, idx) => (
            <StyledDropdownListItem
              key={channel.id}
              onClick={() => handleChannelSelect(channel)}
              data-testid={`preview-channel-item-${idx}`}
            >
              {channel.label}
            </StyledDropdownListItem>
          ))}
        </StyledDropdownList>
      )}
    </StyledDropdown>
  )
}

export default ChannelPreviewDropdown
