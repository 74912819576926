import React from 'react'
import { Box, Tab, TabItem } from 'ds4-beta'
import { ContentTabProps } from './types'

const ContentTabModule = React.memo<ContentTabProps>(
  ({ activeTabIndex, setActiveTabIndex, tabs }) =>
    tabs?.length > 0 && (
      <Box>
        <Tab
          customSetActiveIndex={setActiveTabIndex}
          customActiveIndex={activeTabIndex}
        >
          {tabs?.map(tab => (
            <TabItem key={tab?.id} label={tab?.title} dataTestid={tab?.id}>
              <></>
            </TabItem>
          ))}
        </Tab>
      </Box>
    )
)

export default ContentTabModule
