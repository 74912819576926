import { StyledForm, StyledFormField } from '../components/styles'
import { get, noop } from 'lodash'
import { EDITOR_MODAL_LABEL } from 'src/constants'
import { PropTypes } from 'prop-types'
import React from 'react'
import VersionDetailHeader from '../components/VersionDetailHeader'
import VersionDetailTextarea from '../components/VersionDetailTextarea'
import { Input } from 'ds4-beta'

export const VersionDetailForm = ({
  formValues,
  validationErrors,
  onChange,
  viewMode,
  onCancel,
  submitButtonText,
  submitDisabled,
  onSubmit,
}) => {
  const handleTextareaChange = e => {
    onChange({
      ...formValues,
      description: e.target.value,
    })
  }

  return (
    <>
      <StyledForm>
        <StyledFormField>
          <Input
            width='100%'
            className='primary'
            inputProps={{
              'name': 'pageName',
              'value': formValues.name,
              'data-testid': 'page-name-field',
              'disabled': true,
            }}
            isFloatedLabel
            label={EDITOR_MODAL_LABEL.PAGE_NAME}
          />
        </StyledFormField>
        <StyledFormField>
          <Input
            width='100%'
            label={EDITOR_MODAL_LABEL.VERSION_NAME}
            name='versionName'
            inputProps={{
              'value': formValues.versionName,
              'onChange': e => {
                onChange({
                  ...formValues,
                  versionName: e.target.value,
                })
              },
              'data-testid': 'version-name-field',
              'disabled': viewMode,
            }}
            error={!!get(validationErrors, 'versionName')}
            errorMessage={get(validationErrors, 'versionName')}
            isFloatedLabel
          />
        </StyledFormField>
        <StyledFormField>
          <Input
            width='100%'
            label={EDITOR_MODAL_LABEL.URL}
            name='url'
            className='url__label'
            inputProps={{
              value: formValues.pageUrl,
              onChange: e => {
                onChange({
                  ...formValues,
                  pageUrl: e.target.value,
                })
              },
              dataTestid: 'url-field',
              disabled: true,
            }}
            error={!!get(validationErrors, 'pageUrl')}
            errorMessage={get(validationErrors, 'pageUrl')}
            isFloatedLabel
          />
        </StyledFormField>
        <VersionDetailTextarea
          value={formValues.description}
          onChange={handleTextareaChange}
        />
      </StyledForm>
      <VersionDetailHeader
        viewMode={viewMode}
        submitButtonText={submitButtonText}
        submitDisabled={submitDisabled}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  )
}
VersionDetailForm.defaultProps = {
  formValues: {},
  validationErrors: {},
  onChange: noop,
  viewMode: false,
  onCancel: noop,
  submitButtonText: 'Submit',
  submitDisabled: false,
  onSubmit: noop,
}
VersionDetailForm.propTypes = {
  formValues: PropTypes.shape({
    name: PropTypes.string,
    versionName: PropTypes.string,
    description: PropTypes.string,
    channels: PropTypes.array,
    pageUrl: PropTypes.string,
  }),
  validationErrors: PropTypes.obj,
  onChange: PropTypes.func,
  viewMode: PropTypes.bool,
  onCancel: PropTypes.func,
  submitButtonText: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
}
