import { I18nLocale } from 'store/i18n/types'

export enum CONTENT_TYPE {
  PAGE,
  GLOBAL_ELEMENT,
  MENU,
}

export type CTAButtonProps = {
  isDisabled: boolean
  isVisible: boolean
  hasTooltip: boolean
  onClick: () => void
  isSavePrimary?: boolean
}
export interface ContentEditorCTAProps {
  contentType: CONTENT_TYPE
  isContentLive: boolean
  lastUpdatedDateString?: string
  onScheduleOptionClicked: () => void
  saveButtonProps: CTAButtonProps
  publishButtonProps: CTAButtonProps
  shouldShowLockIcon: boolean
  locales: I18nLocale[]
  selectedLocale: string
  multiChannel: boolean
}
