import styled, { keyframes } from 'styled-components'
import { theme } from 'ds4-beta'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  animation: ${slideInRight} 200ms ease-out;
`

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  background: ${theme.color.grey[0]};
  user-select: none;
  margin-bottom: ${theme.space[4]};
`

export const StyledItem = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
  padding: ${theme.space[4]};
  background: ${theme.color.grey[0]};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${theme.color.grey[100]};
  margin-bottom: ${theme.space[1]};
  position: relative;

  & svg.icon-delete {
    display: none;
  }

  &:hover {
    opacity: 1;
    & svg.icon-dots-vertical {
      display: grid;
    }
    & svg.icon-delete {
      display: block;
    }
  }
`

export const StyledItemTypeExpandView = styled.div`
  border: 1px solid ${theme.color.grey[100]};
  background-color: ${theme.color.grey[0]};
  border-radius: 4px;
  margin-top: 16px;
  padding: 16px;
`

export const StyledItemTypeComponent = styled.div`
  animation: ${fadeIn} 0.5s ease-in;
`

// https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
export const StyledLabel = styled.div`
  ${theme.typography.h5}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  display: block;
  align-items: center;
  height: 100%;
`
export const StyledAttributeItemLabelWrapper = styled.div`
  position: relative;
  z-index: 1;
`
