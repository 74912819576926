import React from 'react'
import { SETTINGS } from 'src/constants'

export const getColumns = () => {
  return [
    {
      accessor: SETTINGS.COLUMNS.NAME.ACCESSOR,
      title: SETTINGS.COLUMNS.NAME.TITLE,
      isSortable: true,
      width: 800,
    },
    {
      title: ' ',
      isSortable: false,
    },
  ]
}
