import {
  DEFAULT_TOAST_DURATION,
  FF_NAMES,
  MODAL_ACTION,
  STATUS,
  TOAST_MESSAGE_TYPES,
  editorPath,
  editorPreviewType,
  gcEditorPath,
  gcEditorPreviewType,
} from 'src/constants'
import {
  EDITOR_INITIALIZE,
  EDITOR_LOCALE_CHANGE,
  EDITOR_META_DATA_SAVE,
  EDITOR_RESET,
  EDITOR_SET_VERSION_STATUS,
} from 'src/ds4/modules/editor/actions'
import {
  EDITOR_TOAST_MESSAGES,
  VERSION_STATUS,
} from 'src/ds4/modules/editor/constants'
import EditorContentHeader, {
  EDITOR_TYPES,
} from 'src/ds4/components/EditorContentHeader'
import {
  FADE_OUT_TOAST_MESSAGE,
  SHOW_TOAST_MESSAGE,
} from 'src/ds4/modules/toast-messages/actions'
import {
  GET_GE_VERSION_BY_ID,
  UPDATE_GE_LOCALIZED_COMPONENTS,
} from 'services/graphql/globalElements'
import {
  GET_PAGE_VERSION_BY_ID,
  GET_UNIVERSAL_DESCRIPTORS,
  UPDATE_GLOBAL_COMPONENTS_AND_PUBLISH,
  UPDATE_PAGE_COMPONENTS_AND_PUBLISH,
  UPDATE_PAGE_VERSION_COMPONENTS,
  UPDATE_PAGE_VERSION_LOCALIZED_COMPONENTS,
} from 'services/graphql'
import {
  GE_PATH,
  NEW_GE_PATH,
  NEW_NAV_PAGES_PATH,
  PAGES_PATH,
} from 'contexts/navigationLinksContext'
import {
  GraphQLPageVersion,
  RESTPageVersion,
} from 'src/ds4/modules/editor/utils/pageVersion/PageVersion'
import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  extractLocaleInput,
  getParentElement,
  getVersionElement,
} from 'src/ds4/modules/editor/utils'
import { getActiveLanguage, getLocales } from 'store/i18n/selectors'
import {
  getCuratedComponents,
  getLocalizedCuratedComponents,
  getSaveTime,
  getSelectedDevice,
  getSelectedRoute,
} from 'src/ds4/modules/editor/selectors'
import {
  getBasePath,
  useNewNavigation,
} from 'src/contexts/navigationLinksContext'
import { GridCol, GridRow, useToast } from 'ds4-beta'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useLocation, useNavigate } from '@reach/router'
import { APOLLO_FETCH_POLICIES } from 'src/src/utils'
import CreateNewPageVersionDialog from 'src/ds4/components/FormDialog/page/CreateNewPageVersionDialog'
import EditorContent from 'src/ds4/modules/editor'
import PropTypes from 'prop-types'
import SideBarComponents from 'src/ds4/modules/editor/components/SideBarComponents'
import ToastSnackbar from 'src/ds4/components/ToastSnackbar'
import ValidUrl from 'valid-url'
import VersionDetailsDialog from 'src/ds4/components/FormDialog/common/versions/VersionDetailsDialog'
import VersionPublishDialog from 'src/ds4/components/FormDialog/page/VersionPublishDialog'
import { VersionTypesEnum } from 'src/ds4/components/FormDialog/common/versions/VersionDetailsDialog/types'
import addMinutes from 'date-fns/addMinutes'
import { bindActionCreators } from 'redux'
import componentService from 'services/api/component'
import extract from 'lib/extract'
import gcComponentService from 'services/api/gcComponent'
import { getChannels } from 'store/channels/selectors'
import { getConfiguration } from 'store/configuration/selectors'
import { getToastMessageState } from 'src/ds4/modules/toast-messages/selectors'
import { INIT_CHANNELS as initChannels } from 'store/channels/actions'
import isEmpty from 'lodash/isEmpty'
import pageService from 'services/api/page'
import size from 'lodash/size'
import some from 'lodash/some'
import { toastMessagePromise } from 'src/ds4/modules/toast-messages/utils'
import useLoadConfig from 'hooks/useLoadConfig'
import useUnleashContext from 'hooks/useUnleashContext'
import { useUserPermissions } from 'contexts/userPermissions'

const {
  unleashFFs: { USE_HYDRA, USE_GRAPHQL, I18N },
} = FF_NAMES

window.updateBreadcrumb([
  {
    label: `home`,
    url: '/',
  },
  {
    label: `experiences`,
    url: '/experiences/pages',
  },
  {
    label: `editor`,
    disabled: true,
  },
])
// @todo - can be removed once all context menu options are moved to VersionDetailsDialog
const getPageDetails = (data = {}) => ({
  name: data.getPageName() || '',
  pageId: data.getPageId() || '',
  pageUrl: data.getPageUrl() || '',
})

// @todo - can be removed once all context menu options are moved to VersionDetailsDialog
const getGEDetails = (data = {}) => ({
  id: data.globalComponentId,
  name: data.globalComponentId,
})

// @todo - can be removed once all context menu options are moved to VersionDetailsDialog
const getVersionDetails = (data = {}, isGlobal, pageSelectedRoute) => {
  if (isGlobal) {
    return {
      id: data.versionId || '',
      channels: data.channels || [],
      name: data.name || '',
      description: data.description || '',
    }
  }

  return {
    versionId: pageSelectedRoute.getVersionId() || '',
    id: pageSelectedRoute.getVersionId() || '',
    name: pageSelectedRoute.getVersionName() || '',
    description: pageSelectedRoute.getVersionDescription() || '',
  }
}

const getModalProps = (
  data,
  handleClose,
  isGlobal = false,
  pageSelectedRoute = null
) => ({
  ...(isGlobal
    ? {
        globalElement: getGEDetails(data),
      }
    : {
        page: getPageDetails(pageSelectedRoute),
      }),
  version: getVersionDetails(data, isGlobal, pageSelectedRoute),
  handleClose,
})

// eslint-disable-next-line sonarjs/cognitive-complexity
const Editor = props => {
  const {
    saveTime,
    onInitialFetch,
    selectedRoute,
    editorMetaDataSave,
    curatedComponents,
    selectedDevice,
    showToastMessage,
    hideToastMessage,
    resetEditor,
    localizedCuratedComponents,
    i18n,
    isGlobal = false,
  } = props
  const showToast = useToast()
  const navNameChange = useNewNavigation()
  const basepath = getBasePath(navNameChange)
  useLoadConfig()
  useUnleashContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const activeLang = useSelector(getActiveLanguage)
  const locales = useSelector(getLocales)
  const isEditorLoading = isEmpty(selectedRoute)
  const { pageId, gcId, versionId, reschedule } = extract.queryParams(search)
  const { duration, kind, message, visible } = useSelector(getToastMessageState)
  const { ecommerceAppUrl, loaded } = useSelector(getConfiguration)
  const [currUpdatedAt, setCurrUpdatedAt] = useState(null)
  const [showModalByType, setShowModalByType] = useState('')
  const [updatedVersionStatus, setUpdatedVersionStatus] = useState(null)
  const [getUniversalDescriptors] = useLazyQuery(GET_UNIVERSAL_DESCRIPTORS)
  const [getPageVersionById] = useLazyQuery(GET_PAGE_VERSION_BY_ID, {
    variables: {
      pageContentId: pageId,
      contentVariantId: versionId,
    },
    fetchPolicy: APOLLO_FETCH_POLICIES.NETWORK_ONLY,
  })
  const [getGeVersionById] = useLazyQuery(GET_GE_VERSION_BY_ID, {
    variables: {
      parentContentId: gcId,
      contentVariantId: versionId,
    },
    fetchPolicy: APOLLO_FETCH_POLICIES.NETWORK_ONLY,
  })
  const [updateGeLocalizedComponents] = useMutation(
    UPDATE_GE_LOCALIZED_COMPONENTS
  )
  const [updatePageVersionComponents] = useMutation(
    UPDATE_PAGE_VERSION_COMPONENTS
  )
  const [updatePageVersionLocalizedComponents] = useMutation(
    UPDATE_PAGE_VERSION_LOCALIZED_COMPONENTS
  )

  const [updatePageComponentsAndPublish] = useMutation(
    UPDATE_PAGE_COMPONENTS_AND_PUBLISH
  )

  const [updateGlobalElementsAndPublish] = useMutation(
    UPDATE_GLOBAL_COMPONENTS_AND_PUBLISH
  )

  const shouldConsumeUds = useFlag(USE_HYDRA)
  const isI18n = useFlag(I18N)
  const useGraphql = useFlag(USE_GRAPHQL)
  const shouldUseGraphQL = isI18n || useGraphql
  const { flagsReady } = useFlagsStatus()
  const { channels } = useSelector(getChannels)
  const multiChannel = some(channels)

  let pageSelectedRoute
  if (!isGlobal && !isEditorLoading) {
    pageSelectedRoute = shouldUseGraphQL
      ? GraphQLPageVersion(selectedRoute)
      : RESTPageVersion(selectedRoute)
  }

  // Get editor or ge-editor values
  let versionStatus, editorHeaderType, versionName, editorContentType, updatedAt
  if (isGlobal) {
    versionStatus = selectedRoute.status
    editorHeaderType = EDITOR_TYPES.GLOBAL_COMPONENT
    versionName = selectedRoute.name
    editorContentType = gcEditorPreviewType
    updatedAt = selectedRoute.updatedAt
  } else {
    versionStatus = pageSelectedRoute?.getVersionStatus()
    editorHeaderType = EDITOR_TYPES.PAGE
    versionName = pageSelectedRoute?.getVersionName()
    editorContentType = editorPreviewType
    updatedAt = pageSelectedRoute?.getLastUpdated()
  }
  const isParentActive = isGlobal ? true : pageSelectedRoute?.getIsPageActive()

  const { hasPublisherPermissions } = useUserPermissions()
  useEffect(() => {
    dispatch(EDITOR_SET_VERSION_STATUS(updatedVersionStatus ?? versionStatus))
  }, [versionStatus, updatedVersionStatus, dispatch])

  useEffect(() => {
    dispatch(EDITOR_LOCALE_CHANGE({ activeLang }))
  }, [activeLang, dispatch])

  useEffect(() => {
    if (loaded && !channels) {
      dispatch(initChannels())
    }
  }, [channels, dispatch, loaded])

  useEffect(() => {
    const isValidReschedule =
      hasPublisherPermissions &&
      versionStatus === STATUS.SCHEDULED &&
      reschedule === 'true'

    if (!isEditorLoading && isValidReschedule) {
      setShowModalByType(MODAL_ACTION.SCHEDULE)
    }
  }, [isEditorLoading, hasPublisherPermissions, reschedule, versionStatus])

  useEffect(() => {
    const hideOutDuration = duration ?? DEFAULT_TOAST_DURATION
    if (visible) {
      const timer = setTimeout(() => {
        hideToastMessage()
      }, hideOutDuration)
      return () => clearTimeout(timer)
    }
  }, [visible, hideToastMessage, duration])

  const getRedirectPath = (isGlobal, navNameChange) => {
    if (isGlobal) return navNameChange ? NEW_GE_PATH : GE_PATH
    return navNameChange ? NEW_NAV_PAGES_PATH : PAGES_PATH
  }

  useEffect(() => {
    if (!loaded || !ecommerceAppUrl || !flagsReady) {
      return
    }

    const redirectPath = getRedirectPath(isGlobal, navNameChange)
    const isInvalidId =
      (isGlobal ? isEmpty(gcId) : isEmpty(pageId)) || isEmpty(versionId)
    const isInvalidAppUrl = ecommerceAppUrl && !ValidUrl.isUri(ecommerceAppUrl)

    if (isInvalidId || isInvalidAppUrl) {
      let toastMessage
      if (isInvalidId) {
        toastMessage = isGlobal
          ? EDITOR_TOAST_MESSAGES.GLOBAL_ELEMENT_VERSION_NOT_AVAILABLE
          : EDITOR_TOAST_MESSAGES.PAGE_VERSION_NOT_AVAILABLE
      } else if (isInvalidAppUrl) {
        toastMessage = `${EDITOR_TOAST_MESSAGES.STOREFRONT_URL_NOT_VALID}: ${ecommerceAppUrl}`
      }

      const showToastMessagePromise = toastMessagePromise(
        toastMessage,
        TOAST_MESSAGE_TYPES.ALERT,
        showToastMessage
      )

      showToastMessagePromise.then(() => {
        navigate(redirectPath)
      })
    } else {
      onInitialFetch({
        pageId,
        gcId,
        versionId,
        ecommerceAppUrl,
        isGlobal,
        shouldConsumeUds,
        shouldUseGraphQL,
        hasLocales: some(locales),
        getUniversalDescriptors,
        getPageVersionById,
        getGeVersionById,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageId,
    gcId,
    versionId,
    ecommerceAppUrl,
    isGlobal,
    showToastMessage,
    loaded,
    flagsReady,
    locales,
    navNameChange,
  ])

  useEffect(() => {
    return () => {
      hideToastMessage()
      resetEditor()
    }
  }, [hideToastMessage, resetEditor])

  const onEditorOptionClick = clickedOption => setShowModalByType(clickedOption)

  const getPreviewUrl = basepath => {
    const urlPath = isGlobal ? gcEditorPath : editorPath
    const idQueryParam = isGlobal ? `gcId=${gcId}` : `pageId=${pageId}`

    return `${basepath}${urlPath}?${idQueryParam}&versionId=${versionId}&preview=true`
  }
  const updatedLocalizedComponents = localizedCuratedComponents?.map(item => ({
    ...item,
    components: item.components?.map(component => ({
      ...component,
      isSelected: false,
    })),
  }))
  const components = curatedComponents.filter(comp => !comp.isGlobal)
  const updatedComponents = components?.map(item => ({
    ...item,
    isSelected: false,
  }))
  const handleClose = useCallback(() => setShowModalByType(''), [])
  const handleOnSaveButtonClick = useCallback(async () => {
    try {
      if (isGlobal) {
        if (size(updatedLocalizedComponents)) {
          await updateGeLocalizedComponents({
            variables: {
              input: {
                parentContentId: gcId,
                contentVariantId: versionId,
                ...extractLocaleInput(updatedLocalizedComponents),
              },
            },
          })
        } else {
          await gcComponentService.save(
            { gcId, versionId },
            { components: updatedComponents }
          )
        }
      } else {
        if (size(updatedLocalizedComponents)) {
          await updatePageVersionLocalizedComponents({
            variables: {
              input: {
                parentContentId: pageId,
                contentVariantId: versionId,
                ...extractLocaleInput(updatedLocalizedComponents),
              },
            },
          })
        } else {
          await componentService.save(
            { pageId, versionId },
            { components: updatedComponents },
            shouldUseGraphQL,
            updatePageVersionComponents
          )
        }
      }
      setCurrUpdatedAt(new Date().toISOString())
      editorMetaDataSave({ saveTime: Date.now() })
    } catch (err) {
      const errorMessage = err?.response?.data ?? err.message ?? err
      showToast({
        label: errorMessage,
        variant: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }, [
    pageId,
    gcId,
    versionId,
    curatedComponents,
    editorMetaDataSave,
    showToastMessage,
    shouldUseGraphQL,
    updatePageVersionComponents,
    isGlobal,
    localizedCuratedComponents,
    updateGeLocalizedComponents,
    updatePageVersionLocalizedComponents,
    showToast,
  ])

  const handleOnScheduleButtonClick = useCallback(
    () => setShowModalByType(MODAL_ACTION.SCHEDULE),
    []
  )

  const updateStatus = status => {
    setUpdatedVersionStatus(status)
  }

  const handleOnPublishButtonClick = useCallback(async () => {
    try {
      const startDate = addMinutes(new Date(), 1).toISOString()
      const publishPayload = { startDate, startImmediate: true }
      updatedLocalizedComponents?.forEach(item => delete item?.__typename)
      if (isGlobal) {
        if (shouldUseGraphQL) {
          await updateGlobalElementsAndPublish({
            variables: {
              input: {
                contentId: gcId,
                variantId: versionId,
                ...(!i18n && { components: updatedComponents }),
                ...(i18n && {
                  localizedComponents: updatedLocalizedComponents,
                }),
              },
            },
          })
        } else {
          await gcComponentService.update({ gcId, versionId }, publishPayload)
        }
        ;({
          label: EDITOR_TOAST_MESSAGES.GLOBAL_ELEMENT_PUBLISH_SUCCESS,
        })
      } else {
        if (shouldUseGraphQL) {
          await updatePageComponentsAndPublish({
            variables: {
              input: {
                contentId: pageId,
                variantId: versionId,
                ...(!i18n && { components: updatedComponents }),
                ...(i18n && {
                  localizedComponents: updatedLocalizedComponents,
                }),
              },
            },
          })
        } else {
          await pageService.updateVersion({ pageId, versionId }, publishPayload)
        }
        showToast({
          label: EDITOR_TOAST_MESSAGES.PAGE_VERSION_PUBLISH_SUCCESS,
        })
      }
      updateStatus(VERSION_STATUS.LIVE)
    } catch (err) {
      const publishErrorDuration = 7000
      const errorMessage =
        typeof err === 'string' ? err : err?.response?.data ?? err.message
      showToast({
        label: errorMessage,
        variant: TOAST_MESSAGE_TYPES.ALERT,
        timeout: publishErrorDuration,
      })
    }
  }, [
    pageId,
    gcId,
    versionId,
    showToastMessage,
    isGlobal,
    shouldUseGraphQL,
    curatedComponents,
    i18n,
    updatePageComponentsAndPublish,
    updatedLocalizedComponents,
    showToast,
  ])

  const handleOnFrameChange = useCallback(
    data => editorMetaDataSave({ iframe: data }),
    [editorMetaDataSave]
  )

  const renderVersionDialog = () => {
    const parentElement = getParentElement(
      selectedRoute,
      isGlobal,
      pageSelectedRoute
    )
    const versionElement = getVersionElement(
      selectedRoute,
      isGlobal,
      pageSelectedRoute
    )
    const contentType = isGlobal
      ? VersionTypesEnum.GLOBAL_ELEMENTS
      : VersionTypesEnum.PAGES

    switch (showModalByType) {
      case MODAL_ACTION.VIEW:
      case MODAL_ACTION.EDIT:
      case MODAL_ACTION.COPY:
        return (
          <VersionDetailsDialog
            isVisible
            handleClose={handleClose}
            mode={showModalByType}
            parentElement={parentElement}
            versionElement={versionElement}
            contentType={contentType}
          />
        )
      case MODAL_ACTION.CREATE:
        return isGlobal ? (
          <VersionDetailsDialog
            isVisible
            handleClose={handleClose}
            mode={showModalByType}
            parentElement={parentElement}
            versionElement={null}
            contentType={contentType}
          />
        ) : (
          <CreateNewPageVersionDialog
            {...getModalProps(
              selectedRoute,
              handleClose,
              isGlobal,
              pageSelectedRoute
            )}
          />
        )
      case MODAL_ACTION.SCHEDULE:
        return (
          <VersionPublishDialog
            {...getModalProps(
              selectedRoute,
              handleClose,
              isGlobal,
              pageSelectedRoute
            )}
            updateStatus={updateStatus}
            handleOnSaveButtonClick={handleOnSaveButtonClick}
            updatedComponents={updatedComponents}
            updatedLocalizedComponents={updatedLocalizedComponents}
          />
        )
      default:
        return
    }
  }

  return (
    <>
      <ToastSnackbar visible={visible} message={message} kind={kind} />
      {showModalByType && renderVersionDialog()}
      <GridRow>
        <GridCol sm={12}>
          <EditorContentHeader
            saveTime={saveTime}
            versionName={versionName}
            versionStatus={updatedVersionStatus ?? versionStatus}
            updatedAt={currUpdatedAt ?? updatedAt}
            isParentActive={isParentActive}
            previewUrl={getPreviewUrl(basepath)}
            selectedDevice={selectedDevice}
            hasCuratedComponents={!isEmpty(curatedComponents)}
            handleOnSaveButtonClick={handleOnSaveButtonClick}
            handleOnScheduleButtonClick={handleOnScheduleButtonClick}
            handleOnPublishButtonClick={handleOnPublishButtonClick}
            handleOnFrameChange={handleOnFrameChange}
            type={editorHeaderType}
            multiChannel={multiChannel}
            isEditorLoading={isEditorLoading}
            locales={locales}
            reschedule={reschedule}
            onEditorOptionClick={onEditorOptionClick}
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol md={3} sm={12}>
          <SideBarComponents
            isEditorLoading={isEditorLoading}
            versionStatus={updatedVersionStatus ?? versionStatus}
            versionName={versionName}
          />
        </GridCol>
        <GridCol md={9} sm={12}>
          <EditorContent
            type={editorContentType}
            multiChannel={multiChannel}
            locales={locales}
            i18n={i18n}
          />
        </GridCol>
      </GridRow>
    </>
  )
}

Editor.defaultProps = {
  curatedComponents: [],
  isGlobal: false,
}

Editor.propTypes = {
  selectedRoute: PropTypes.object.isRequired,
  saveTime: PropTypes.number,
  selectedDevice: PropTypes.string.isRequired,
  curatedComponents: PropTypes.array.isRequired,
  onInitialFetch: PropTypes.func.isRequired,
  editorMetaDataSave: PropTypes.func.isRequired,
  showToastMessage: PropTypes.func.isRequired,
  hideToastMessage: PropTypes.func.isRequired,
  resetEditor: PropTypes.func.isRequired,
  i18n: PropTypes.bool,
  isGlobal: PropTypes.bool,
  localizedCuratedComponents: PropTypes.array,
}

const mapStateToProps = state => ({
  selectedRoute: getSelectedRoute(state),
  saveTime: getSaveTime(state),
  curatedComponents: getCuratedComponents(state),
  localizedCuratedComponents: getLocalizedCuratedComponents(state),
  selectedDevice: getSelectedDevice(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onInitialFetch: EDITOR_INITIALIZE,
      editorMetaDataSave: EDITOR_META_DATA_SAVE,
      showToastMessage: SHOW_TOAST_MESSAGE,
      hideToastMessage: FADE_OUT_TOAST_MESSAGE,
      resetEditor: EDITOR_RESET,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Editor)
