import { VERSION_BADGE_STATUS } from './constants'

export const getBadgeStatusForVersion = (
  inputVersion: string
): 'alert' | 'default' | null => {
  const upperCaseVersion = inputVersion.toUpperCase()
  const versionObj = VERSION_BADGE_STATUS.find(
    v => v.version === upperCaseVersion
  )
  return versionObj ? versionObj.variant : null
}

export const getLastUpdatedDateLabel = (formattedDate: string): string =>
  `Last updated ${formattedDate}`
