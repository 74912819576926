import { DEFAULT_TEXTAREA_VALUES, MODAL_ACTION } from 'src/constants'
import { AnyObjectSchema } from 'yup'
import { CommonInputFieldProps } from 'src/ds4/components/FormDialog/common/versions/VersionDetailsDialog/types'
import { FormikProps } from 'formik'
import PageTypeField from '../PageTypeField'
import React, { ChangeEvent } from 'react'
import { SeoFieldActionFunctionProps } from 'src/ds4/components/FormDialog/common/content/ContentDetailsDialog/types'
import { SeoMetadataList } from 'src/ds4/components/FormDialog/common/content/ContentDetailsDialog/tabs/SeoAndMetadataTab'
import noop from 'lodash/noop'
import {
  Box,
  Dropdown,
  DropdownOptionProps,
  Input,
  MultiSelect,
  Textarea,
} from 'ds4-beta'
import { InputElementType } from 'ds4-beta/dist/components/BaseInputComponent'

export type SeoMetadataListProps = {
  type: string
  dataTestId: string
  disabled?: boolean
  seoFieldAction: SeoFieldActionFunctionProps
  localeCode?: string
  label?: never
  name?: never
  value?: never
  dropdownOptions?: never
  autoFocus?: never
  placeholder?: never
  forceEnabled?: never
  className?: never
  customOnChange?: never
  locale?: never
  width?: string
  required?: boolean
}

export type FormComponentListProps = (
  | CommonInputFieldProps
  | SeoMetadataListProps
)[]

// TODO: Replace 'any' with an explicit type once Value type is identified (see types.ts)
interface GetFormComponentsProps extends FormikProps<any> {
  components: FormComponentListProps
  mode: string
}

export const getFormComponents = ({
  components,
  handleBlur,
  handleChange,
  setFieldTouched,
  setFieldValue,
  errors,
  touched,
  mode,
}: GetFormComponentsProps): JSX.Element => {
  return (
    <Box flex={{ flexDirection: 'column' }} gap={4}>
      {components.map(component => {
        const {
          type,
          label,
          name,
          dataTestId,
          value,
          disabled = false,
          autoFocus = false,
          placeholder = '',
          className = '',
          locale = '',
          localeCode = '',
          customOnChange = noop,
          seoFieldAction,
          width = '100%',
          required = true,
        } = component
        const isDisabledByMode = !!(mode === MODAL_ACTION.VIEW
          ? true
          : disabled)
        switch (type) {
          case 'Input':
            return (
              <Input
                width={width}
                label={label}
                suffix={locale}
                required={required}
                inputProps={{
                  id: name,
                  value: value as string,
                  placeholder: placeholder,
                  onChange: (event: ChangeEvent<InputElementType>) => {
                    setFieldTouched(name, true, false)
                    handleChange(event)
                  },
                  dataTestid: dataTestId,
                  onBlur: handleBlur,
                  disabled: isDisabledByMode,
                }}
                message={
                  errors[name] && touched[name] ? (errors[name] as string) : ''
                }
                messageType={errors[name] && touched[name] ? 'error' : 'normal'}
                key={dataTestId}
              />
            )
          case 'SingleSelect':
            return (
              <Dropdown
                width={"456px"}
                variant='large'
                label={label}
                placeholder={name}
                id={name}
                value={value}
                onChange={(option: DropdownOptionProps) => {
                  setFieldValue(name, option.id, true)
                  setFieldTouched(name, true, false)
                  customOnChange(option.id, setFieldValue)
                }}
                message={
                  errors[name] && touched[name] ? (errors[name] as string) : ''
                }
                messageType={errors[name] && touched[name] ? 'error' : 'normal'}
                disabled={component.forceEnabled ? false : isDisabledByMode}
                options={component.dropdownOptions?.map(
                  (o: Record<string, string>) => ({
                    id: o?.id,
                    label: o?.label,
                  })
                )}
                {...{ 'data-testid': dataTestId }}
              />
            )
          case 'Textarea':
            return (
              <Textarea
                width='100%'
                label={label}
                // id={name}
                rows={DEFAULT_TEXTAREA_VALUES.ROWS}
                limit={DEFAULT_TEXTAREA_VALUES.LIMIT}
                message={
                  errors[name] && touched[name] ? (errors[name] as string) : ''
                }
                messageType={errors[name] && touched[name] ? 'error' : 'normal'}
                inputProps={{
                  value: value as string,
                  onBlur: handleBlur,
                  dataTestid: dataTestId,
                  onChange: handleChange,
                  disabled: isDisabledByMode,
                }}
              />
            )
          case 'MultiSelectTextChips': {
            const optionsToShow = component.dropdownOptions?.map(
              (item: DropdownOptionProps) => ({
                id: item.id,
                label: item.label,
                name: item.name,
              })
            )
            return isDisabledByMode ? (
              <Input
                width='100%'
                label={label}
                inputProps={{
                  id: name,
                  value: (value as Array<{ label: string }>)
                    ?.map(v => v.label)
                    .join(', '),
                  dataTestid: dataTestId,
                  onBlur: handleBlur,
                  disabled: true,
                  placeholder,
                }}
              />
            ) : (
              // TODO: Once CPU-6402 is complete, this field component can inherit the 'disabled' attribute
              <MultiSelect
                width='100%'
                label={label}
                value={value}
                id={name}
                onClear={() => {
                  setFieldValue(name, [], true)
                  setFieldTouched(name, true, false)
                  customOnChange([], setFieldValue)
                }}
                placeholder={`Select a ${name}`}
                options={optionsToShow}
                onChange={options => {
                  setFieldValue(name, options, true)
                  setFieldTouched(name, true, false)
                  customOnChange(options, setFieldValue)
                }}
                message={
                  errors[name] && touched[name] ? (errors[name] as string) : ''
                }
                messageType={errors[name] && touched[name] ? 'error' : 'normal'}
              />
            )
          }
          case 'PageTypeField':
            return (
              <PageTypeField
                pageTypeId={value as string}
                setPageType={selected => {
                  setFieldValue(name, selected._id, true)
                  setFieldTouched(name, true, false)
                }}
                disabled={isDisabledByMode}
              />
            )
          case 'SeoMetadataList':
            return (
              <SeoMetadataList
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                seoFieldAction={seoFieldAction}
                disabled={disabled}
                localeCode={localeCode}
              />
            )
          default:
            return null
        }
      })}
    </Box>
  )
}

export function mergeYupSchemas(schemas: AnyObjectSchema[]): AnyObjectSchema {
  const filteredSchemas = schemas?.length
    ? schemas.filter(s => !!s?.concat)
    : []

  const [first, ...rest] = filteredSchemas
  return rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  )
}
