import {
  ContentTableItemProps,
  TableOpFunctionProps,
  TableOpFunctionReturnType,
} from './types'
import { useCallback, useEffect, useState } from 'react'
import { DEFAULT_PAGINATION } from 'src/constants'
import { Filters } from 'components/Filters/types'
import { PaginationProps } from 'hooks/usePagination/types'
import { generateTableEntries } from './utils'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { DataProps } from 'ds4-beta'
export enum ACTIONS {
  DELETE_VERSION = 'DELETE_VERSION',
  DELETE = 'DELETE',
  NEW_VERSION = 'NEW_VERSION',
  REFETCH = 'REFETCH',
}

export const REFETCH_TIMEOUT_MS = 1000

const getTableAndPaginationData = ({
  currentPageData,
  prevPageData,
  nextPageData,
  pagination,
  action,
}: {
  currentPageData: DataProps[]
  prevPageData: DataProps[]
  nextPageData: DataProps[]
  pagination: PaginationProps
  action: ACTIONS
}): TableOpFunctionReturnType => {
  const isPageDataAvailable = !isEmpty(currentPageData)
  const hasPrevPageData = !isEmpty(prevPageData)
  const hasNextPageData = !isEmpty(nextPageData)

  const isTableEmpty =
    !isPageDataAvailable && !hasPrevPageData && !hasNextPageData

  if (isTableEmpty) {
    return [null, null, null, pagination]
  }

  let { currentPage, totalItems } = pagination
  let tableData = currentPageData

  if (action === ACTIONS.DELETE) {
    totalItems -= 1

    /* When we delete an item from the current page, update the table by shifting
    content from the next page into the current page, if available */
    if (hasNextPageData) {
      tableData = [...(currentPageData || []), nextPageData.shift()]
    } else if (!isPageDataAvailable && hasPrevPageData) {
      /* When we delete the last item of the current page, update the table by
        showing the previous page of data, if available */
      currentPage -= 1
      tableData = prevPageData
      prevPageData = null
    }
  }

  return [
    tableData,
    prevPageData,
    nextPageData,
    { ...pagination, currentPage, totalItems },
  ]
}

const deleteItem = ({
  itemId,
  currentPageData,
  ...rest
}: TableOpFunctionProps): TableOpFunctionReturnType => {
  const updatedData = currentPageData.filter(item => item.id !== itemId)
  return getTableAndPaginationData({
    currentPageData: updatedData,
    ...rest,
    action: ACTIONS.DELETE,
  })
}

const deleteVersion = ({
  parentId,
  versionId,
  currentPageData,
  ...rest
}: TableOpFunctionProps): TableOpFunctionReturnType => {
  let isParentDeleted = false

  const updatedData = currentPageData.filter(parent => {
    if (parent.id !== parentId) {
      return true
    }

    const { children: versions } = parent

    // Remove the parent entirely if we will be deleting the last version
    if (versions.length === 1) {
      isParentDeleted = true
      return false
    }

    // If parent has more than one version, remove the deleted version
    parent.children = versions.filter(version => version.id !== versionId)

    return true
  })

  return getTableAndPaginationData({
    currentPageData: updatedData,
    ...rest,
    action: isParentDeleted ? ACTIONS.DELETE : ACTIONS.DELETE_VERSION,
  })
}

const calculateLimitAndOffset = (
  pageNumber: number,
  itemsPerPage: number,
  totalItems: number
): { limit: number; offset: number } => {
  const hasPrevPage = pageNumber > 1
  const hasNextPage = pageNumber < Math.ceil(totalItems / itemsPerPage)

  let pagesToRetrieve = 1

  if (hasPrevPage) {
    pagesToRetrieve += 1
  }

  if (hasNextPage) {
    pagesToRetrieve += 1
  }

  const limit = itemsPerPage * pagesToRetrieve
  const offset = (hasPrevPage ? pageNumber - 2 : pageNumber - 1) * itemsPerPage

  return { limit, offset }
}

/**
 *
 * A custom hook that handles the intialization, modification, and pagination of
 * table data.
 *
 * @param props The custom hook properties.
 * @param props.entries The entries for the current view of the table. The data
 * expected in this array is the current page entries as well as the previous
 * and/or next page entries.
 * @param props.totalCount The total number of items the table will show.
 * @param props.fetchQuery The query to call in order to fetch more data.
 * @returns An array holding the following data at the respective index:
 * - 0: `tableData` - An array holding the current page data that will be
 * rendered by the Table.
 * - 1: `dispatchTableAction` - The function to call to perform an operation on
 * the table data (e.g. deletion).
 * - 2: `pagination` - An object holding all pagination information for the Table.
 * - 3: `handlePagination` - The function to call when navigating to a different
 * page on the table.
 */
const useTableData = ({
  entries,
  totalCount,
  fetchQuery,
  filters,
  initialFilters,
  contentType = '',
}: {
  entries: ContentTableItemProps[]
  totalCount: number
  fetchQuery: (limit: number, offset: number) => Promise<unknown>
  filters: Filters
  initialFilters
  contentType: string
}): [
  DataProps[],
  (
    action: ACTIONS,
    variables?: { targetItemId: string; parentId?: string },
    refetchTime?: number
  ) => void,
  {
    currentPage: number
    itemsPerPage: number
    totalItems: number
  },
  (pageNumber: number) => Promise<void>
] => {
  const [tableInfo, setTableInfo] = useState<{
    currentPageData: DataProps[]
    prevPageData: DataProps[]
    nextPageData: DataProps[]
    pagination: PaginationProps
  }>({
    currentPageData: null,
    prevPageData: null,
    nextPageData: null,
    pagination: {
      currentPage: DEFAULT_PAGINATION.CURRENT_PAGE,
      itemsPerPage: DEFAULT_PAGINATION.ITEMS_PER_PAGE,
      totalItems: totalCount,
    },
  })
  const [activePage, setActivePage] = useState(tableInfo.pagination.currentPage)
  const [refetchTimeout, setRefetchTimeout] = useState<number>(null)
  // Initialize table data by fetching the current and next page data
  useEffect(() => {
    const numberOfPagesToFetch = 2
    void fetchQuery(DEFAULT_PAGINATION.ITEMS_PER_PAGE * numberOfPagesToFetch, 0)
  }, [fetchQuery])

  // Cleanup timeout
  useEffect(() => {
    return () => {
      if (refetchTimeout) {
        window.clearTimeout(refetchTimeout)
      }
    }
  }, [refetchTimeout])

  useEffect(() => {
    setTableInfo(prev => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        totalItems: totalCount,
      },
    }))
  }, [totalCount])

  useEffect(() => {
    const itemsPerPage = tableInfo.pagination.itemsPerPage

    const hasNoData = isEmpty(entries)
    const isFirstPage = activePage === 1

    if (hasNoData) {
      setTableInfo(prev => ({
        ...prev,
        currentPageData: null,
        prevPageData: null,
        nextPageData: null,
        pagination: {
          ...prev.pagination,
          currentPage: activePage,
        },
      }))
      return
    }

    const tableFirstPageLimit = itemsPerPage
    const tableSecondPageLimit = itemsPerPage * 2

    const hasPreviousPageData =
      !isFirstPage && entries.length > tableFirstPageLimit

    const firstPageData = generateTableEntries(
      entries.slice(0, tableFirstPageLimit),
      contentType
    )

    const secondPageData = generateTableEntries(
      entries.slice(tableFirstPageLimit, tableSecondPageLimit),
      contentType
    )

    const thirdPageData = generateTableEntries(
      entries.slice(tableSecondPageLimit),
      contentType
    )

    const prevPageData = hasPreviousPageData ? firstPageData : null
    const currentPageData = hasPreviousPageData ? secondPageData : firstPageData
    const nextPageData = isFirstPage ? secondPageData : thirdPageData

    setTableInfo(prev => ({
      ...prev,
      prevPageData,
      currentPageData,
      nextPageData,
      pagination: {
        ...prev.pagination,
        currentPage: activePage,
      },
    }))
  }, [entries, activePage, tableInfo.pagination.itemsPerPage])
  const isFiltersApplied = !isEqual(filters, initialFilters)
  useEffect(() => {
    if (isFiltersApplied) {
      setActivePage(1)
    }
  }, [isFiltersApplied, filters])

  const handlePagination = useCallback(
    async (pageNumber: number) => {
      const { limit, offset } = calculateLimitAndOffset(
        pageNumber,
        tableInfo.pagination.itemsPerPage,
        tableInfo.pagination.totalItems
      )

      await fetchQuery(limit, offset)

      setActivePage(pageNumber)
    },
    [
      fetchQuery,
      tableInfo.pagination.totalItems,
      tableInfo.pagination.itemsPerPage,
    ]
  )

  const refetchPage = useCallback(
    ({
      currentPage,
      refetchTime = REFETCH_TIMEOUT_MS,
    }: {
      currentPage: number
      refetchTime?: number
    }) => {
      if (refetchTimeout) {
        window.clearTimeout(refetchTimeout)
      }
      setRefetchTimeout(
        window.setTimeout(() => {
          void handlePagination(currentPage)
          setRefetchTimeout(null)
        }, refetchTime)
      )
    },
    [refetchTimeout, handlePagination]
  )

  const dispatchTableAction = useCallback(
    (
      action: string,
      variables?: { targetItemId: string; parentId?: string },
      refetchTime?: number
    ) => {
      const {
        currentPageData,
        pagination,
        prevPageData,
        nextPageData,
      } = tableInfo

      let tableOpFunctionProps: TableOpFunctionProps
      let tableOpFunction: (
        props: TableOpFunctionProps
      ) => TableOpFunctionReturnType

      const commonProps = {
        currentPageData,
        prevPageData,
        nextPageData,
        pagination,
      }

      switch (action) {
        case ACTIONS.DELETE_VERSION:
          tableOpFunction = deleteVersion
          tableOpFunctionProps = {
            parentId: variables?.parentId,
            versionId: variables?.targetItemId,
            ...commonProps,
          }
          break
        case ACTIONS.DELETE:
          tableOpFunction = deleteItem
          tableOpFunctionProps = {
            itemId: variables?.targetItemId,
            ...commonProps,
          }
          break
        case ACTIONS.REFETCH:
          void refetchPage({
            currentPage: pagination.currentPage,
            refetchTime,
          })
          return
        default:
          return null
      }

      const [
        updatedTableData,
        updatedPrevPageData,
        updatedNextPageData,
        updatedPaginationData,
      ] = tableOpFunction(tableOpFunctionProps)

      const hasTableCountChanged =
        pagination.totalItems !== updatedPaginationData.totalItems

      setTableInfo(prev => ({
        ...prev,
        currentPageData: updatedTableData,
        prevPageData: updatedPrevPageData,
        nextPageData: updatedNextPageData,
        pagination: updatedPaginationData,
      }))

      if (hasTableCountChanged) {
        refetchPage({ currentPage: updatedPaginationData.currentPage })
      }
    },
    [refetchPage, tableInfo]
  )

  return [
    tableInfo.currentPageData || [],
    dispatchTableAction,
    tableInfo.pagination,
    handlePagination,
  ]
}

export default useTableData
