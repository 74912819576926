import React, { FunctionComponent } from 'react'
import { PageHeader } from 'ds4-beta'
import { ContentCTAProps } from './types'
import isEmpty from 'lodash/isEmpty'

const ContentCTAModule: FunctionComponent<ContentCTAProps> = ({
  entries,
  headerText,
}) => {
  const renderButtons = () => {
    const buttons = []
    entries.map(item => {
      buttons.push({
        text: item?.buttonText,
        onClick: item?.onClick,
        dataTestid: item?.dataTestId,
        variant: item?.variant,
        isDisabled: item?.disabled ?? false,
        icon: item?.icon && 'Add',
      })
    })
    return buttons
  }

  return (
    !isEmpty(entries) && (
      <PageHeader h1Text={headerText} primaryButtons={renderButtons()} />
    )
  )
}

export default ContentCTAModule
