import {
  BROWSE_MENU_TREE_MODAL_ID,
  BrowseMenuTreeModuleContext,
  BrowseMenuTreeOperations,
} from './types'
import {
  ContentModalOptionsProps,
  ModalType,
} from 'components/ContentModalModule/types'
import { PUBLISH_MODAL, VERSION_CONFLICT_MODAL } from 'src/constants'
import { CONTENT_TYPE } from 'src/ds4/components/ContentEditorCTA/types'
import { useMemo } from 'react'

export const useBrowseMenuTreeModalOptions = ({
  moduleContext,
  operations,
}: {
  moduleContext: BrowseMenuTreeModuleContext
  operations: BrowseMenuTreeOperations
}): { modalOptions: { [key: string]: ContentModalOptionsProps } } => {
  const modalOptions: { [key: string]: ContentModalOptionsProps } = useMemo(
    () => ({
      [BROWSE_MENU_TREE_MODAL_ID.GRAPH_CONFLICT]: {
        title: VERSION_CONFLICT_MODAL.title,
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: VERSION_CONFLICT_MODAL.content,
          confirmButton: {
            text: VERSION_CONFLICT_MODAL.submitText,
            onClick: () => window.location.reload(),
          },
          cancelButton: {
            text: VERSION_CONFLICT_MODAL.cancelText,
            onClick: moduleContext.closeModal,
          },
        },
      },
      [BROWSE_MENU_TREE_MODAL_ID.PUBLISH]: {
        title: PUBLISH_MODAL.title,
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: PUBLISH_MODAL.getContent(CONTENT_TYPE.MENU),
          confirmButton: {
            text: PUBLISH_MODAL.submitText,
            onClick: () =>
              operations.publishMenu({
                variables: {
                  menuId: moduleContext.menuId,
                  versionId: moduleContext.versionId,
                },
              }),
          },
          cancelButton: {
            text: PUBLISH_MODAL.cancelText,
            onClick: moduleContext.closeModal,
          },
        },
      },
    }),
    [moduleContext, operations]
  )

  return { modalOptions }
}
