import {
  CREATE_UNIVERSAL_DESCRIPTOR,
  DELETE_SINGLE_DESCRIPTOR_BY_ID,
  GET_UNIVERSAL_DESCRIPTORS,
  UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID,
} from 'services/graphql/universalDescriptors'
import {
  InvalidContextProps,
  SETTINGS_MENU,
  UniversalDescriptorsProps,
} from './types'
import { MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  getDescriptorsWithDuplicateIDs,
  getListToCreateAndToUpdate,
  handleUpload,
  onFileInputChange,
  validateUploadData,
} from './utils'
import { useLazyQuery, useMutation } from '@apollo/client'
import AddComponentMode from 'src/ds4/modules/settings/utils/AddComponentMode'
import AllComponentsMode from 'src/ds4/modules/settings/utils/AllComponentsMode'
import ComponentViewMode from 'src/ds4/modules/settings/utils/ComponentViewMode'
import ConfirmDeleteModal from './modals/DeleteDescriptorModal'
import ConfirmUploadModal from './modals/ConfirmUploadModal'
import DescriptorDetails from './descriptor-details/DescriptorDetails'
import { DescriptorProps } from 'store/types'
import DuplicateDescriptorsModal from './modals/DuplicateDescriptorsModal'
import InvalidUploadDataModal from './modals/InvalidUploadDataModal'
import { SHOW_TOAST_MESSAGE } from 'modules/toast-messages/actions'
import { StyledComponentName, StyledMainSettingsContainer } from './styles'
import { Table } from 'ds4-beta'
import { getColumns } from './columns'
import isObject from 'lodash/isObject'
import { useToast } from 'ds4-beta'
import size from 'lodash/size'
import { useDispatch } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'
import { Box, PageHeader, SpacingUnit, theme } from 'ds4-beta'
import SettingsModal from './settingsModal'
import DescriptorContextMenu from 'src/ds4/modules/settings/descriptor-context-menu'
import EditComponentMode from './utils/EditComponentMode'
import ViewComponentMode from './utils/ViewComponentMode'

// eslint-disable-next-line sonarjs/cognitive-complexity
const Settings = (): ReactElement => {
  window.updateBreadcrumb([
    {
      label: `home`,
      url: '/',
    },
    {
      label: `experiences`,
      url: '/experiences/settings',
    },
    {
      label: `settings`,
      url: '/experiences/settings',
      disabled: true,
    },
  ])
  const [getUniversalDescriptors, { loading, data }] = useLazyQuery<
    UniversalDescriptorsProps
  >(GET_UNIVERSAL_DESCRIPTORS)
  const [allDescriptors, setAllDescriptors] = useState<any[]>(null)
  const showToast = useToast()
  const [createUniversalDescriptor] = useMutation(CREATE_UNIVERSAL_DESCRIPTOR, {
    update(cache, { data: updatedData }) {
      const {
        universalDescriptors,
      }: { universalDescriptors: DescriptorProps[] } = cache.readQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
      })
      cache.writeQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
        data: {
          universalDescriptors: [
            ...universalDescriptors,
            updatedData.createUniversalDescriptor,
          ],
        },
      })
    },
  })

  const [updateUniversalDescriptor] = useMutation<DescriptorProps>(
    UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID
  )
  const [storedDescriptors, setStoredDescriptors] = useState<DescriptorProps[]>(
    []
  )
  const [descriptorsToCreate, setDescriptorsToCreate] = useState([])
  const [descriptorsToUpdate, setDescriptorsToUpdate] = useState([])
  const [selected, setSelected] = useState<{
    descriptor: DescriptorProps
  } | null>(null)
  const [duplicateDescriptorIds, setDuplicateDescriptorIds] = useState<
    Set<string>
  >(null)
  const [
    isConfirmUploadModalVisible,
    setIsConfirmUploadModalVisible,
  ] = useState(false)
  const dispatch = useDispatch()
  const [isDuplicateIdModalVisible, setIsDuplicateIdModalVisible] = useState(
    false
  )
  const [deleteSingleDescriptor] = useMutation(DELETE_SINGLE_DESCRIPTOR_BY_ID)
  const [
    invalidDataContext,
    setInvalidDataContext,
  ] = useState<null | InvalidContextProps>(null)
  const [mode, setMode] = useState<ComponentViewMode>(AllComponentsMode)
  const [descriptorIds, setDescriptorIds] = useState([])
  const [showDeleteModal, setShowDeteleModal] = useState<boolean>(false)

  const handleFetchDescriptors = useCallback(async (): Promise<void> => {
    try {
      await getUniversalDescriptors()
    } catch (err) {
      console.error(err)
      void showToast({
        id: 'get-descriptors',
        label: MESSAGES.ERROR_GET_DESCRIPTORS,
        variant: 'alert',
      })
    }
  }, [getUniversalDescriptors])
  const [isBulkUploadModalOpen, setBulkUploadOpenModal] = useState<boolean>(
    false
  )
  const handleClickAddComponent = () => {
    setMode(AddComponentMode)
  }

  useEffect(() => {
    void handleFetchDescriptors()
  }, [handleFetchDescriptors])

  useEffect(() => {
    if (data) {
      const desc = data.universalDescriptors
      const ids = desc.map(nested => nested.id)
      setDescriptorIds(ids)
    }
  }, [data])

  useEffect(() => {
    const isUploadDataValid = validateUploadData(
      storedDescriptors,
      setInvalidDataContext
    )

    if (isUploadDataValid && size(storedDescriptors)) {
      const descriptorsWithDuplicateIDs: Set<string> = getDescriptorsWithDuplicateIDs(
        storedDescriptors
      )
      if (descriptorsWithDuplicateIDs.size) {
        setDuplicateDescriptorIds(descriptorsWithDuplicateIDs)
        setIsDuplicateIdModalVisible(true)
      } else {
        const { toCreate, toUpdate } = getListToCreateAndToUpdate(
          allDescriptors,
          storedDescriptors
        )
        setDescriptorsToCreate(toCreate)
        setDescriptorsToUpdate(toUpdate)

        if (size(toUpdate)) {
          setIsConfirmUploadModalVisible(true)
        } else {
          void handleUpload({
            storedDescriptors,
            descriptorsToCreate: toCreate,
            descriptorsToUpdate: toUpdate,
            createUniversalDescriptor,
            updateUniversalDescriptor,
          })
          setStoredDescriptors([])
        }
      }
    }
  }, [
    storedDescriptors,
    allDescriptors,
    createUniversalDescriptor,
    updateUniversalDescriptor,
  ])

  const handleConfirmUploadModalClose = () => {
    setIsConfirmUploadModalVisible(false)
    setStoredDescriptors([])
  }

  const handleDelete = useCallback(async () => {
    try {
      await deleteSingleDescriptor({
        variables: {
          id: selected?.descriptor?.id,
        },
      })
      showToast({
        id: 'delete-component',
        label: MESSAGES.getDeletedSuccess(selected?.descriptor?.label),
        variant: 'default',
      })
      mode.retrieveOnNavigateBack(setMode, setSelected)()
      await handleFetchDescriptors()
    } catch (err) {
      dispatch(
        SHOW_TOAST_MESSAGE({
          message: MESSAGES.getDeletedError(selected?.descriptor?.label),
          kind: TOAST_MESSAGE_TYPES.ALERT,
        })
      )
    }
  }, [deleteSingleDescriptor, selected, dispatch])

  const handleDuplicateDescriptorsModalClose = () => {
    setIsDuplicateIdModalVisible(false)
    setDuplicateDescriptorIds(null)
    setStoredDescriptors([])
  }

  const handleInvalidUploadDataModalClose = () => {
    setInvalidDataContext(null)
    setStoredDescriptors([])
  }

  const handleModalClose = () => setShowDeteleModal(false)

  const handleBulkUploadModal = () => {
    setBulkUploadOpenModal(!isBulkUploadModalOpen)
  }

  const onQuickLinkClick = (descriptor, settingMode) => {
    setSelected({ descriptor })
    if (settingMode !== SETTINGS_MENU.DELETE) {
      setMode(
        settingMode === SETTINGS_MENU.EDIT
          ? EditComponentMode
          : ViewComponentMode
      )
    } else {
      setShowDeteleModal(true)
    }
  }
  useEffect(() => {
    const filteredData = data?.universalDescriptors?.map(item => {
      return {
        id: item?.id,
        data: {
          'Component name': {
            value: (
              <Box color={theme.color.blue[500]} as='span'>
                <StyledComponentName>{item?.label}</StyledComponentName>
              </Box>
            ),
          },
          ' ': {
            value: (
              <div className='show-on-row-hover'>
                <DescriptorContextMenu
                  descriptor={item}
                  onClickItem={(descriptor, view) =>
                    onQuickLinkClick(descriptor, view)
                  }
                />
              </div>
            ),
          },
        },
      }
    })
    setAllDescriptors(filteredData)
  }, [data?.universalDescriptors])

  const onModalClose = () => {
    setBulkUploadOpenModal(false)
  }

  const onBulkUpload = (file: any) => {
    onFileInputChange({
      file,
      setStoredDescriptors,
      setInvalidDataContext,
    })
  }

  return (
    <>
      {showDeleteModal && (
        <ConfirmDeleteModal
          handleModalClose={handleModalClose}
          handleDelete={handleDelete}
          title={selected?.descriptor?.label}
        />
      )}
      {mode !== AllComponentsMode ? (
        <>
          <DescriptorDetails
            mode={mode}
            setMode={setMode}
            id={mode.retrieveId(selected?.descriptor?.id)}
            onNavigateBack={mode.retrieveOnNavigateBack(setMode, setSelected)}
            descriptorIds={descriptorIds}
            showDeleteModal={() => setShowDeteleModal(true)}
          />
        </>
      ) : (
        <StyledMainSettingsContainer>
          <Box padding={{ top: 6, bottom: 6, left: 6, right: 6 }}>
            <Box margin={{ bottom: 4 as SpacingUnit }}>
              <PageHeader
                h1Text='Experiences settings'
                primaryButtons={[
                  {
                    text: 'Bulk upload',
                    onClick: handleBulkUploadModal,
                    dataTestid: 'add-component-label',
                    variant: 'secondary',
                  },
                  {
                    text: 'Add component',
                    onClick: handleClickAddComponent,
                    dataTestid: 'add-component-label',
                    icon: 'Add',
                    variant: 'primary',
                  },
                ]}
              />
            </Box>
            <Table
              columns={getColumns() as any}
              data={allDescriptors ?? []}
              loading={loading}
              showPagination={true}
            />
            {isConfirmUploadModalVisible && (
              <ConfirmUploadModal
                handleModalClose={handleConfirmUploadModalClose}
                storedDescriptors={storedDescriptors}
                descriptorsToUpdate={descriptorsToUpdate}
                descriptorsToCreate={descriptorsToCreate}
                createUniversalDescriptor={createUniversalDescriptor}
                updateUniversalDescriptor={updateUniversalDescriptor}
              />
            )}
            {isObject(invalidDataContext) && (
              <InvalidUploadDataModal
                onClose={handleInvalidUploadDataModalClose}
                context={invalidDataContext}
              />
            )}
            {isDuplicateIdModalVisible && (
              <DuplicateDescriptorsModal
                onClose={handleDuplicateDescriptorsModalClose}
                duplicateIds={duplicateDescriptorIds}
              />
            )}
          </Box>
        </StyledMainSettingsContainer>
      )}
      {isBulkUploadModalOpen && (
        <SettingsModal
          onBulkUpload={file => onBulkUpload(file)}
          onClose={onModalClose}
        />
      )}
    </>
  )
}

export default Settings
