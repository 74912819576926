import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Button, Box } from 'ds4-beta'
import { MANAGE_NODE_SIDEBAR_LABEL } from 'src/constants'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const AssetSection: React.FC = () => {
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)

  return (
    <Card
      showCollapse
      header={MANAGE_NODE_SIDEBAR_LABEL.IMAGES}
      body={
        <>
          <Box>
            {hasEditAccess && (
              <Button
                dataTestid='add-asset-cta'
                text='Add asset'
                variant='tertiary'
                icon='Add'
                onClick={() => {}}
              />
            )}
          </Box>
        </>
      }
    />
  )
}

export default AssetSection
