import React, { ReactElement } from 'react'
import ContextMenu from 'components/ContextMenu'
import { DescriptorProps } from 'store/types'
import { Icon } from '@teamfabric/copilot-ui'
import { SETTINGS } from 'src/constants'
import { SETTINGS_MENU } from '../types'
import { StyledQuickLinks } from './styles'
import { XM_EDIT_DESCRIPTORS_ROLE } from 'src/rbac-constants'
import { useRbacPermission } from 'hooks/useRbacPermission'
import { InteractiveIcon, Tooltip } from 'ds4-beta'

const DescriptorContextMenu = ({
  descriptor,
  onClickItem,
}: {
  descriptor: DescriptorProps
  onClickItem: (desc: DescriptorProps, mode: SETTINGS_MENU) => void
}): ReactElement => {
  const hasAccess = useRbacPermission(XM_EDIT_DESCRIPTORS_ROLE)
  const retrieveUdOption = () => {
    if (hasAccess) {
      return (
        <>
          <Tooltip
            id='descriptor-context-view'
            placement='top'
            text={SETTINGS.CONTEXT.VIEW}
            theme='dark'
            showArrow
          >
            <InteractiveIcon
              iconName='View'
              onClick={() => onClickItem(descriptor, SETTINGS_MENU.VIEW)}
              dataTestid='descriptor-context-view'
            />
          </Tooltip>
          <Tooltip
            id='descriptor-context-edit'
            placement='top'
            text={SETTINGS.CONTEXT.EDIT}
            theme='dark'
            showArrow
          >
            <InteractiveIcon
              iconName='Pencil'
              onClick={() => onClickItem(descriptor, SETTINGS_MENU.EDIT)}
              dataTestid='descriptor-context-edit'
            />
          </Tooltip>
          <Tooltip
            id='descriptor-context-delete'
            placement='top'
            text={SETTINGS.CONTEXT.DELETE}
            theme='dark'
            showArrow
          >
            <InteractiveIcon
              iconName='Trash'
              onClick={() => onClickItem(descriptor, SETTINGS_MENU.DELETE)}
              dataTestid='descriptor-context-delete'
            />
          </Tooltip>
        </>
      )
    }
    return (
      <>
        <Tooltip
          id='descriptor-context-view'
          placement='top'
          text={SETTINGS.CONTEXT.VIEW}
          theme='dark'
          showArrow
        >
          <InteractiveIcon
            iconName='View'
            onClick={() => onClickItem(descriptor, SETTINGS_MENU.VIEW)}
            dataTestid='descriptor-context-view'
          />
        </Tooltip>
      </>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '16px',
        gap: '8px',
      }}
    >
      {retrieveUdOption()}
    </div>
  )
}

export default DescriptorContextMenu
