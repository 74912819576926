export enum NODE_TYPE {
  NODE,
  SECTION,
  SUBNODE,
}

export interface ReactDnDProvidedProps {
  innerRef: React.LegacyRef<HTMLDivElement>
  droppableProps: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement>
  placeholder:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
}

interface Name {
  [key: string]: string
}

export interface Node {
  id: string
  _id: string
  name: Name
  url: string
  disabled: boolean
  section: boolean
  idEditing: boolean
  order: number
  parent: string
  rootNodeName: Name
  shouldFetchNodes: boolean
  children: Node[]
  images: Image[]
  attributes: Attribute[]
  label?: string
}

export interface DeleteModalProps {
  show: boolean
  isSection: boolean
  isLoading?: boolean
  onCancel?: () => void
  onYesClicked: () => void
}

export interface MenuTreeNode {
  _id: string
  rootNodeName: Names
  parent: string
  name: Names
  url: string
  disabled: boolean
  section: boolean
  order: number
  images: Image[]
  attributes: Attribute[]
  shouldFetchNodes?: boolean
  children?: MenuTreeNode[]
}

export interface MenuVariantByIdResponse {
  menuVariantById: MenuVariantById
}

export interface MenuVariantById {
  id: string
  versionName: string
  description: string
  graph: Graph
  version: number
  status: string
  startDate: string
  endDate: string
  updatedAt: string
  isArchived: boolean
}

export interface MenuNodesAsListResponse {
  menuNodesAsList: MenuNodesAsList[]
}

export interface MenuNodesAsList {
  id: string
  url: string
  name: Names
  images: Image[]
  videos: Video[]
  attributes: Attribute[]
  section: boolean
  disabled: boolean
}

export interface MenuVariantAndNodesListResponse {
  menuVariantById: MenuVariantById
  menuNodesAsList: MenuNodesAsList[]
}

export interface Names {
  [key: string]: string
}

export interface Image {
  locales: string[]
  url: string
  altText: JSON | { default: string }
  name?: string
  description?: string
}

export interface Video {
  locales: string[]
  url: string
  altText: JSON | { default: string }
  name?: string
  description?: string
}

export interface Attribute {
  kind: string
  value: string
}

export interface CreateMenuNodeResponse {
  createNode: MenuGraphPayload
}

export interface DeleteMenuNodeResponse {
  deleteNodes: MenuGraphPayload
}

export interface MenuNode {
  id: string
  name: Names
  url: string
  images: Image[]
  attributes: Attribute[]
}

export interface UpdateMenuGraphResponse {
  updateMenuGraph: MenuGraphPayload
}

export interface MenuGraphPayload {
  graph: Graph
  updatedNode?: MenuNode
  version?: number
}

export interface Graph {
  [key: string]: {
    id: string
    section: boolean
    edges: string[]
    isActive: boolean
  }
}

export interface UpdateMenuNodeResponse {
  updateNode: MenuNode
}

export interface HandleCreateProps {
  depth: number
  name: string
  nodeParentId?: string
  nodeType: NODE_TYPE
  order?: number
}

export interface PublishMenuVariantResponse {
  immediatePublish: {
    contentId: string
    variantId: string
    status: string
    updatedAt: string
    startDate: string
    endDate: string
    channels: [string]
    locales: [string]
    segments: [string]
    components: [JSON]
  }
}
