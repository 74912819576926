import {
  ADD_MENU_LABEL,
  DEBOUNCE_DURATION_MS,
  DIRECTION_DESC,
  EMPTY_STRING,
  MESSAGES,
  NAVIGATION,
  NO_RESULTS_FOUND,
  NO_RESULTS_SEARCH_ID,
  QUICK_LINK,
  SEARCH_LIMIT,
  STATUS,
} from 'src/constants'
import {
  BROWSE_MENU_MODAL_ID,
  getContentScheduledAt,
  getIsScheduledOrLive,
  hasLiveOrScheduledVersion,
  populateVariantQuickLinkTitle,
} from '../utils'
import {
  BrowseMenuFeaturesList,
  GenerateTableProps,
  ModalDetailProps,
} from './types'
import {
  ContentTableItemProps,
  ContentTableProps,
} from 'src/ds4/components/ContentTableModule/types'
import { EdgesNodes, ListMenuResponse } from 'services/graphql/types'
import { SearchDropdown, UseSearchResults } from 'src/ds4/data/pages-list/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BMT_PATH, NEW_BMT_PATH } from 'contexts/navigationLinksContext'
import { CONTEXT_MENU_OPTIONS } from 'components/ContentContextMenuModule/constants'
import { Channel } from 'store/channels/types'
import { ContentCTAProps } from 'src/ds4/components/ContentCTAModule/types'
import { ContextMenuOptionProps } from 'components/ContentContextMenuModule/types'
import { I18nLocale } from 'store/i18n/types'
import { LIST_MENUS } from 'services/graphql'
import { NavigateFn } from '@reach/router'
import { TABLE_COLUMN_OPTIONS } from 'src/ds4/components/ContentTableModule/constants'
import { UserPermissions } from 'contexts/userPermissions'
import { VERSION_STATUS } from 'modules/editor/constants'
import { showSnackbar } from 'src/ds4/data/services'
import statusMapping from 'components/DetailsTable3.0/statusMapping'
import { useDebounce } from 'hooks/useDebounce'
import { useLazyQuery } from '@apollo/client'

export const generateCTAProps = (
  showModal: (modalDetails: ModalDetailProps) => void,
  hasEditorPermissions?: boolean
): ContentCTAProps => {
  let entries = []
  if (hasEditorPermissions) {
    entries = [
      {
        id: 'add-browse-menu-cta',
        buttonText: ADD_MENU_LABEL,
        dataTestId: 'add-browse-menu-cta',
        onClick: () => {
          showModal({
            visibleModalId: BROWSE_MENU_MODAL_ID.CREATE,
          })
        },
        variant: 'primary',
      },
    ]
  }
  return {
    entries,
    headerText: NAVIGATION.MENU,
  }
}

const populateQuickLinkHandler = (
  versionId: string,
  navigate: NavigateFn,
  menuId: string,
  reschedule: boolean,
  navNameChange: boolean
) => () =>
  void navigate(
    `${
      navNameChange ? NEW_BMT_PATH : BMT_PATH
    }?menuId=${menuId}&versionId=${versionId}${
      reschedule ? '&reschedule=true' : ''
    }`
  )

const createBrowseMenuCtxOptions = ({
  menuId,
  name,
  showModal,
  channels,
  locales,
  userPermissions,
  hasLiveOrScheduledVariants,
  menu,
}: {
  menuId: string
  name: string
  showModal: (modalDetails: ModalDetailProps) => void
  channels: Channel[]
  locales: I18nLocale[]
  userPermissions: UserPermissions
  hasLiveOrScheduledVariants: boolean
  menu: EdgesNodes['node']
}): ContextMenuOptionProps[] => {
  if (!userPermissions.hasEditorPermissions) {
    return [
      {
        label: CONTEXT_MENU_OPTIONS.VIEW_DETAILS,
        onClick: () => {
          showModal({
            visibleModalId: BROWSE_MENU_MODAL_ID.VIEW_DETAILS,
            targetItemId: menuId,
            targetItemName: name,
            targetItemChannels: channels,
            targetItemLocales: locales,
          })
        },
      },
    ]
  }
  const editDetails = {
    label: CONTEXT_MENU_OPTIONS.EDIT_DETAILS,
    onClick: () => {
      showModal({
        visibleModalId: BROWSE_MENU_MODAL_ID.EDIT_DETAILS,
        targetItemId: menuId,
        targetItemName: name,
        targetItemChannels: channels,
        targetItemLocales: locales,
      })
    },
  }

  const copyMenu = {
    label: CONTEXT_MENU_OPTIONS.MAKE_A_COPY,
    onClick: () => {
      showModal({
        visibleModalId: BROWSE_MENU_MODAL_ID.COPY,
        targetItemId: menuId,
        targetItemName: name,
        targetItemChannels: channels,
        targetItemLocales: locales,
        menu,
      })
    },
  }

  const newVersion = {
    label: CONTEXT_MENU_OPTIONS.NEW_VERSION,
    onClick: () => {
      showModal({
        visibleModalId: BROWSE_MENU_MODAL_ID.NEW_VERSION,
        targetItemId: menuId,
        targetItemName: name,
      })
    },
  }

  const deleteMenu = {
    label: CONTEXT_MENU_OPTIONS.DELETE,
    onClick: () => {
      showModal({
        visibleModalId: BROWSE_MENU_MODAL_ID.DELETE,
        targetItemId: menuId,
        targetItemName: name,
      })
    },
  }

  if (!userPermissions.hasPublisherPermissions && hasLiveOrScheduledVariants) {
    return [editDetails, newVersion, copyMenu]
  }
  return [editDetails, newVersion, copyMenu, deleteMenu]
}

const createBrowseMenuVariantCtxOptions = ({
  menuId,
  versionId,
  versionStatus,
  name,
  showModal,
  navigate,
  userPermissions,
  navNameChange,
}: {
  menuId: string
  versionId: string
  versionStatus: string
  name: string
  showModal: (modalDetails: ModalDetailProps) => void
  navigate: NavigateFn
  userPermissions: UserPermissions
  navNameChange: boolean
}): ContextMenuOptionProps[] => {
  const navigateToBrowseMenuTree = () => {
    void navigate(
      `${
        navNameChange ? NEW_BMT_PATH : BMT_PATH
      }?menuId=${menuId}&versionId=${versionId}`
    )
  }
  const isScheduledOrLive = getIsScheduledOrLive(versionStatus)

  const makeACopyOption = {
    label: CONTEXT_MENU_OPTIONS.MAKE_A_COPY,
    onClick: () =>
      showModal({
        visibleModalId: BROWSE_MENU_MODAL_ID.COPY_VERSION,
        targetItemId: {
          versionId,
          parentId: menuId,
        },
        targetItemName: name,
      }),
  }

  if (!userPermissions.hasEditorPermissions) {
    return []
  }

  if (!userPermissions.hasPublisherPermissions && isScheduledOrLive) {
    return [makeACopyOption]
  }

  const initialOptionsArray = []
  if (userPermissions.hasPublisherPermissions && isScheduledOrLive) {
    initialOptionsArray.push({
      label: CONTEXT_MENU_OPTIONS.EDIT,
      onClick: navigateToBrowseMenuTree,
    })
  }

  return [
    ...(initialOptionsArray as ContextMenuOptionProps[]),
    makeACopyOption,
    {
      label: CONTEXT_MENU_OPTIONS.DELETE,
      onClick: () => {
        showModal({
          visibleModalId: BROWSE_MENU_MODAL_ID.DELETE_VERSION,
          targetItemId: {
            versionId,
            parentId: menuId,
          },
          targetItemName: name,
        })
      },
    },
  ]
}

export const constructEntriesList = ({
  menuList,
  isArchived,
  showModal,
  navigate,
  featuresList,
  userPermissions,
  navNameChange,
}: {
  menuList: EdgesNodes[]
  isArchived: boolean
  showModal: (modalDetails: ModalDetailProps) => void
  navigate: NavigateFn
  featuresList: BrowseMenuFeaturesList
  userPermissions: UserPermissions
  navNameChange: boolean
}): ContentTableItemProps[] => {
  return menuList?.map(menu => ({
    ...menu.node,
    status: statusMapping[
      isArchived ? STATUS.ARCHIVED : STATUS.ACTIVE
    ] as string,
    channels: menu.node.channels?.map(c => c.label) ?? [],
    scheduledAt: getContentScheduledAt(menu.node.variants),
    contextMenuOptions: createBrowseMenuCtxOptions({
      isActive: !isArchived,
      showModal,
      menu: menu.node,
      menuId: menu.node.id,
      name: menu.node.name,
      channels: menu.node.channels ?? [],
      locales: menu.node.locales ?? [],
      featuresList,
      userPermissions,
      hasLiveOrScheduledVariants: hasLiveOrScheduledVersion(menu.node.variants),
    }),
    children: menu.node.variants.map(v => {
      const { versionName: name, status, startDate, ...rest } = v
      return {
        ...rest,
        name,
        status: statusMapping[status] as string,
        scheduledAt: startDate ?? '',
        contextMenuOptions: createBrowseMenuVariantCtxOptions({
          menuId: menu.node.id,
          versionId: v.id,
          name,
          showModal,
          navigate,
          userPermissions,
          versionStatus: v?.status,
          navNameChange,
        }),
        quickLinkTitle: populateVariantQuickLinkTitle(userPermissions, v, {
          content: QUICK_LINK.VIEW_MENU,
        }),
        quickLinkHandler: populateQuickLinkHandler(
          v.id,
          navigate,
          menu.node.id,
          v?.status === VERSION_STATUS.SCHEDULED,
          navNameChange
        ),
      }
    }),
  }))
}

export const generateTableProps = ({
  data,
  featuresList,
  paginationProps,
}: GenerateTableProps): ContentTableProps => {
  const { multiChannel, i18n } = featuresList

  return {
    data,
    columns: [
      {
        title: TABLE_COLUMN_OPTIONS.NAME,
        width: 150,
      },
      multiChannel && {
        title: TABLE_COLUMN_OPTIONS.CHANNEL,
        width: 150,
      },
      i18n && {
        title: TABLE_COLUMN_OPTIONS.LOCALE,
        width: 150,
      },
      {
        title: TABLE_COLUMN_OPTIONS.STATUS,
        width: 120,
      },
      {
        title: TABLE_COLUMN_OPTIONS.SCHEDULED_AT,
        width: 150,
      },
      {
        title: TABLE_COLUMN_OPTIONS.UPDATED_AT,
        width: 150,
      },
      {
        title: TABLE_COLUMN_OPTIONS.ACTIONS,
        width: 150,
      },
    ].filter(item => item),
    paginationProps,
  }
}

export const useBrowseMenuSearchResults = (
  searchTerm: string
): UseSearchResults => {
  const [dropDownValues, setDropDownValues] = useState<Array<SearchDropdown>>(
    []
  )
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const noSearchResults = useMemo(() => {
    return {
      name: NO_RESULTS_FOUND,
      tags: '',
      _id: NO_RESULTS_SEARCH_ID,
    }
  }, [])

  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DURATION_MS)
  const [fetchMenus] = useLazyQuery<ListMenuResponse>(LIST_MENUS)
  const clearSearchDropdown = () => {
    setDropDownValues([])
  }

  const isEmptyOrSpaces = (str: string) => {
    return str === EMPTY_STRING || str.match(/^ *$/) !== null
  }

  const getSearchResults = useCallback(async () => {
    if (debouncedSearchTerm === EMPTY_STRING) {
      setDropDownValues([])
      return
    }

    setIsSearching(true)

    const results =
      !isEmptyOrSpaces(debouncedSearchTerm) &&
      (await fetchMenus({
        variables: {
          input: {
            isArchived: false,
            filter: {
              searchTerm: debouncedSearchTerm ?? '',
            },
          },
          orderBy: {
            field: TABLE_COLUMN_OPTIONS.UPDATED_AT,
            direction: DIRECTION_DESC,
          },
        },
      }))

    setIsSearching(false)
    if (results?.error) {
      showSnackbar(MESSAGES.ERROR_SEARCH, true)
    }

    const searchResults = results?.data?.menus?.edges?.map(item => {
      const { name, id } = item.node
      return {
        name,
        tags: '',
        _id: id,
      }
    })

    if (searchResults?.length) {
      setDropDownValues(searchResults.slice(0, SEARCH_LIMIT))
      return
    }

    setDropDownValues([noSearchResults])
  }, [noSearchResults, debouncedSearchTerm, fetchMenus])

  useEffect(() => {
    void getSearchResults()
  }, [debouncedSearchTerm, getSearchResults])

  return { dropDownValues, clearSearchDropdown, isSearching }
}
