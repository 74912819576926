import React, { FunctionComponent, useState } from 'react'
import { getFormComponents, mergeYupSchemas } from './utils'
import { AnyObjectSchema } from 'yup'
import { ContentModalOptionsProps } from './types'
import { Formik } from 'formik'
import VersionDetailHeader from 'src/ds4/components/FormDialog/components/VersionDetailHeader'
import { getModeConfigurationForHeader } from 'src/ds4/components/FormDialog/common/utils/getModeConfigurationForHeader'
import isEmpty from 'lodash/isEmpty'
import { Box, Modal, Tab, TabItem } from 'ds4-beta'

const FormModal: FunctionComponent<ContentModalOptionsProps> = ({
  title,
  size,
  mode,
  forms,
  onClose,
  onSubmit,
}) => {
  const [activeForm, setActiveForm] = useState(forms?.length && forms[0])
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  if (!activeForm) {
    return null
  }

  /* Aggregate validation schemas from all the forms in the
    modal to make sure all of fields are validated before submit */
  const validationSchemas = mergeYupSchemas(
    forms?.length
      ? forms.map(form => form?.validationSchema as AnyObjectSchema)
      : []
  )

  return (
    <Modal
      isVisible
      size={size}
      headerText={title}
      onClose={onClose}
      onBackdropClick={onClose}
    >
      <Tab
        tabChangeHandler={(index: number) => {
          setActiveTabIndex(index)
          setActiveForm(forms[index])
        }}
        customActiveIndex={activeTabIndex}
      >
        {forms.map(form => {
          const { name } = form
          return (
            <TabItem label={name} data-testid={`modal-section-${name}`}>
              <Box padding={{ top: 5 }}>
                <Formik
                  /* Aggregate all the initial values from all the forms in the
                modal to obtain the full list of submittable values in the modal
                to initialize */
                  initialValues={forms.reduce(
                    (
                      modalInitialValues,
                      { initialValues: formInitialValues }
                    ) => ({
                      ...modalInitialValues,
                      ...formInitialValues,
                    }),
                    {}
                  )}
                  onSubmit={onSubmit}
                  validationSchema={validationSchemas}
                  validateOnMount={true}
                >
                  {formikProps => (
                    <>
                      <form onSubmit={formikProps.handleSubmit}>
                        {getFormComponents({
                          components: activeForm.getComponents(
                            formikProps.values,
                            formikProps
                          ),
                          mode,
                          ...formikProps,
                        })}
                      </form>
                      <VersionDetailHeader
                        {...getModeConfigurationForHeader({
                          mode,
                          onSubmitHandler: formikProps.handleSubmit,
                          onCloseHandler: onClose,
                          submitDisabled:
                            !isEmpty(formikProps.errors) ||
                            formikProps.isSubmitting,
                        })}
                      />
                    </>
                  )}
                </Formik>
              </Box>
            </TabItem>
          )
        })}
      </Tab>
    </Modal>
  )
}

export default FormModal
