import { ParamsProps } from './types'
import { nanoid } from 'nanoid'

export enum APPLY_MODES {
  ADD_MODE,
  EDIT_MODE,
}

export const shouldDisableApplyCTA = (
  currentAttribute: ParamsProps,
  attributeValues: ParamsProps[],
  mode: APPLY_MODES
): boolean => {
  if (!currentAttribute || !currentAttribute.kind || !currentAttribute.value) {
    return true
  }

  if (mode === APPLY_MODES.EDIT_MODE) {
    return (attributeValues ?? []).some(attr => {
      if (currentAttribute._id === attr._id) {
        return false
      }
      return attr.kind === currentAttribute.kind.trim()
    })
  }

  return (attributeValues ?? []).some(
    attr => attr.kind === currentAttribute.kind.trim()
  )
}

export const createDefaultAttribute = (): ParamsProps => ({
  _id: nanoid(),
  kind: '',
  value: '',
})
