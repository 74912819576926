import styled from 'styled-components'
import { theme } from 'ds4-beta'

export const StyledActionButton = styled.button`
  border: none;
  width: 202px;
  background: ${theme.color.grey[0]};
  ${theme.typography.body1.regular};
  cursor: pointer;
  height: 40px;
  text-align: left;
  padding-left: 12px;
  &:first-child {
    border-radius: 4px 4px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 4px 4px;
  }
  &:hover {
    background: ${theme.color.blue[50]};
  }
`

export const Caption = styled.div`
  ${theme.typography.body2.regular};
  color: ${theme.color.grey[700]};
`
export const PaginationCaption = styled.div`
  ${theme.typography.body2.regular}
  color: ${theme.color.grey[600]};
`
