import React from 'react'
import styled from 'styled-components'
import { ACTIONS_ICONS_MAPPING, MORE_ACTIONS } from './constants'
import { StyledActionButton } from './styles'
import { ActionsTableRowProps } from './types'
import { Box, InteractiveIcon, Popover, Tooltip, theme } from 'ds4-beta'

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: ${theme.space[4]};
  gap: ${theme.space[2]};
`

const ActionsTableRow = ({ actions, contentType }: ActionsTableRowProps) => {
  let actionsOfMoreOptions = MORE_ACTIONS?.[contentType] || []

  if (actions?.length <= 3) {
    actionsOfMoreOptions = actionsOfMoreOptions?.filter(
      action => action !== 'Delete'
    )
  }

  const outActions = actions?.filter(
    (action: any) =>
      !actionsOfMoreOptions?.some(label => action?.label?.includes(label))
  )

  const moreActions = actions?.filter((action: any) =>
    actionsOfMoreOptions?.some(label => action?.label?.includes(label))
  )

  return (
    <StyledContainer className='show-on-row-hover'>
      {outActions?.map((action: any) => (
        <Tooltip
          id={action?.label}
          placement='top'
          text={action?.label}
          theme='dark'
          showArrow
        >
          <InteractiveIcon
            iconName={ACTIONS_ICONS_MAPPING?.[action?.label]}
            onClick={action?.onClick}
            dataTestid={action?.label}
          />
        </Tooltip>
      ))}
      {moreActions?.length > 0 ? (
        <Tooltip
          id={'more-actions'}
          placement='top'
          text={'More actions'}
          theme='dark'
          showArrow
        >
          <Popover
            trigger={
              <InteractiveIcon
                dataTestid='more-actions-icon'
                iconName={'MoreActions'}
              />
            }
            placement='bottom-end'
          >
            <Box flex={{ flexDirection: 'column' }}>
              {moreActions?.map((action: any) => (
                <StyledActionButton
                  key={action?.label}
                  onClick={action?.onClick}
                >
                  {action?.label}
                </StyledActionButton>
              ))}
            </Box>
          </Popover>
        </Tooltip>
      ) : null}
    </StyledContainer>
  )
}

export default ActionsTableRow
