import 'styles/global.css'
import 'tippy.js/dist/tippy.css'
import { FF_NAMES, USER_ACCESS_SPLASH_PAGE } from './constants'
import { GlobalStyle, Jumbotron } from '@teamfabric/copilot-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { addNewCookie, getAccountId } from 'lib/cookieUtils'
import { isUnleashEnvSetup, unleashConfig } from 'src/business-layer-client'
import { ApolloProvider } from '@apollo/client'
import { CONFIG_RESET } from 'store/configuration/actions'
import Editor from 'pages/editor'
import FlagProvider from '@unleash/proxy-client-react'
import {
  BASEPATH,
  NEW_NAV_BASEPATH,
  NavigationLinksProvider,
  getBasePath,
} from 'contexts/navigationLinksContext'
import { Router } from '@reach/router'
import Spinner from 'components/Spinner'
import { ThemeProvider } from 'styled-components'
import { UserDataContext } from 'contexts/userDataContext'
import { UserPermissionsProvider } from 'src/contexts/userPermissions'
import ViewWithSideBar from 'pages/viewWithSideBar'
import { XM_VIEWER_ROLE } from './rbac-constants'
import client from './src/graphql-client'
import { documentScriptConfig } from 'src/config'
import { enableMapSet } from 'immer'
import { noop } from 'lodash'
import { rbac } from '@teamfabric/copilot-utilities'
import store from 'store'
import theme from 'styles/theme'
import { unleashClient } from './src/business-layer-client'

const App = () => {
  const [userData, setUserData] = useState({})
  const [noXmAccess, setNoXmAccess] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [featureFlags, setFeatureFlags] = useState({
    i18n: false,
    rbac: false,
    navNameChange: false,
  })

  enableMapSet()

  const updateFeatureFlagValues = useCallback(() => {
    setFeatureFlags({
      i18n: unleashClient.isEnabled(FF_NAMES.unleashFFs.I18N),
      rbac: unleashClient.isEnabled(FF_NAMES.unleashFFs.USE_RBAC),
      navNameChange: unleashClient.isEnabled(
        FF_NAMES.unleashFFs.NAVIGATION_NAME_CHANGE
      ),
    })
  }, [setFeatureFlags])

  useEffect(() => {
    const redirectToNewRoute =
      featureFlags.navNameChange && window.location.pathname.includes('xpm')
    if (redirectToNewRoute) {
      window.location.replace(
        window.location.origin +
          window.location.pathname.replace(BASEPATH, NEW_NAV_BASEPATH)
      )
    }
  }, [featureFlags.navNameChange, window.location.pathname])

  useEffect(() => {
    const onReady = () => {
      updateFeatureFlagValues()
      setIsLoading(false)
    }

    unleashClient.on('ready', onReady)
    unleashClient.on('update', onReady)

    unleashClient.on('error', () => {
      console.error('Error with unleash client')
      setIsLoading(false)
    })

    unleashClient.updateContext({ userId: getAccountId() })
    unleashClient.start().then(() => {
      onReady()
    })

    return () => {
      unleashClient.stop()
    }
  }, [updateFeatureFlagValues])

  useEffect(() => {
    addNewCookie('XM_UI_VERSION', documentScriptConfig.UI_VERSION)

    if (!isUnleashEnvSetup) {
      console.error('Error retrieving unleash environment variables')
    }

    return () => {
      setUserData({})
      store.dispatch({ type: CONFIG_RESET })
    }
  }, [featureFlags.i18n])

  useEffect(() => {
    const { hasAccess: canViewRole } = rbac.checkPermission({
      allowedPermissions: XM_VIEWER_ROLE,
    })

    setNoXmAccess(!canViewRole && featureFlags.rbac)
  }, [featureFlags.rbac])

  if (isLoading) {
    return <Spinner variant='fullScreen' />
  }

  if (noXmAccess) {
    return (
      <>
        <GlobalStyle />
        <Jumbotron
          primaryText={USER_ACCESS_SPLASH_PAGE.primaryText}
          secondaryText={USER_ACCESS_SPLASH_PAGE.secondaryText}
          height='100vh'
          onClick={noop}
        />
      </>
    )
  }

  return (
    <FlagProvider config={unleashConfig}>
      <UserPermissionsProvider>
        <NavigationLinksProvider>
          <ApolloProvider client={client}>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
              <UserDataContext.Provider value={userData}>
                <Routes
                  i18n={featureFlags.i18n}
                  navNameChange={featureFlags.navNameChange}
                />
              </UserDataContext.Provider>
            </ThemeProvider>
          </ApolloProvider>
        </NavigationLinksProvider>
      </UserPermissionsProvider>
    </FlagProvider>
  )
}

const Routes = ({ i18n, navNameChange }) => {
  const basepath = getBasePath(navNameChange)
  return (
    <Router basepath={basepath}>
      <Editor path='/editor' i18n={i18n} />
      <Editor path='/ge-editor' i18n={i18n} isGlobal />
      <ViewWithSideBar path='*' />
    </Router>
  )
}

export default App
