import { Box } from 'ds4-beta'
import React from 'react'
import { format, parseISO } from 'date-fns'
import { StyledDate, StyledTime } from './style'

type Props = {
  date: string
}

const DateTime = ({ date }: Props): React.ReactElement => {
  const RenderDate = ({
    dateString,
  }: {
    dateString: string
  }): React.ReactElement => {
    const parsedDate = parseISO(dateString)
    return (
      <>
        <StyledDate>{format(parsedDate, 'd MMM yyyy ')}</StyledDate>
        <StyledTime>{format(parsedDate, 'hh:mm a')}</StyledTime>
      </>
    )
  }

  return (
    <Box
      width='100%'
      flex={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {date ? <RenderDate dateString={date} /> : '-'}
    </Box>
  )
}

export default DateTime
