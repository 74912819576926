import { ActionType, ContentTableItemProps } from './types'
import isValid from 'date-fns/isValid'
import { Badge, Box, ColumnProps, DataProps, Tag } from 'ds4-beta'
import DateTime from '../DateTime'
import {
  GE_TABLE_EMPTY_ARCHIVE_MESSAGE,
  GE_TABLE_EMPTY_MESSAGE,
  MENU_TABLE_EMPTY_MESSAGE,
  NAVIGATION,
  NOT_SCHEDULED,
  PAGES_TABLE_EMPTY_ARCHIVE_MESSAGE,
  PAGES_TABLE_EMPTY_MESSAGE,
  STATUS,
} from 'src/constants'
import {
  NULL_ENTRY,
  STATUS_MAPPING,
  TABLE_COLUMN_OPTIONS,
  TABLE_COLUMN_TITLES,
} from './constants'
import React from 'react'
import rfdc from 'rfdc'
import statusMapping from 'components/DetailsTable3.0/statusMapping'
import ActionsTableRow from './ActionsTableRow'
import { Caption } from './styles'
import { TableColumns } from 'src/ds4/data/pages-list/types'

const deepClone = rfdc()

export const getEmptyTableMessage = ({
  contentType,
  isArchivedTab,
}: {
  contentType: string
  isArchivedTab?: boolean
}): string => {
  let emptyTableMessage = ''
  switch (contentType) {
    case NAVIGATION.PAGES:
      emptyTableMessage = isArchivedTab
        ? PAGES_TABLE_EMPTY_ARCHIVE_MESSAGE
        : PAGES_TABLE_EMPTY_MESSAGE
      break
    case NAVIGATION.GLOBAL_ELEMENTS:
      emptyTableMessage = isArchivedTab
        ? GE_TABLE_EMPTY_ARCHIVE_MESSAGE
        : GE_TABLE_EMPTY_MESSAGE
      break
    case NAVIGATION.MENU:
      emptyTableMessage = MENU_TABLE_EMPTY_MESSAGE
      break
    default:
      break
  }
  return emptyTableMessage
}

/** Sort versions according to last updated */
const getSortedVersions = (versions): ContentTableItemProps[] => {
  const _versions = deepClone(versions) as ContentTableItemProps[]
  return Array.isArray(_versions)
    ? _versions.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    : []
}

export const generateTableColumns = (
  columnOptions: TableColumns[]
): ColumnProps[] => {
  return columnOptions.map(columnOption => ({
    title: TABLE_COLUMN_TITLES[columnOption.title] as string,
    accessor: columnOption.title,
    sortable: false,
    width: columnOption.width,
  }))
}

export const generateTableEntries = (
  entryOptions: ContentTableItemProps[],
  contentType: string
): DataProps[] => {
  if (!entryOptions) {
    return null
  }
  return entryOptions.map(entryOption => {
    const {
      id,
      name,
      locales = null,
      channels = null,
      pageCount = null,
      prefix = null,
      status,
      scheduledAt,
      updatedAt,
      contextMenuOptions,
      children,
      quickLinkTitle = '',
      quickLinkHandler = () => {},
      pageTypeName,
    } = entryOption
    const uid = name?.replace(/[^a-z0-9]/gi, '').toLowerCase()

    const actionsTable = [
      { label: quickLinkTitle, onClick: quickLinkHandler },
      ...contextMenuOptions,
    ].filter(item => item.label) as ActionType[]

    return {
      id,
      uid,
      data: {
        [TABLE_COLUMN_TITLES.name]: {
          value: (
            <>
              {name}
              {pageTypeName ? <Caption>{pageTypeName}</Caption> : null}
            </>
          ),
        },
        [TABLE_COLUMN_TITLES.locale]: {
          value:
            locales?.length > 0 ? (
              <Box
                gap={1}
                padding={{ top: 2, bottom: 2 }}
                width={'200px'}
                flex={{ flexDirection: 'row', flexWrap: 'wrap' }}
              >
                {locales?.map(tag => (
                  <Tag key={tag} id={tag} primaryLabel={tag} />
                ))}
              </Box>
            ) : (
              NULL_ENTRY
            ),
        },
        [TABLE_COLUMN_TITLES.status]: {
          value: <Badge label={status} status={STATUS_MAPPING[status]} />,
        },
        [TABLE_COLUMN_TITLES.channel]: Array.isArray(channels)
          ? channels.join(', ')
          : NULL_ENTRY,
        [TABLE_COLUMN_TITLES.scheduledAt]: {
          value: isValid(new Date(scheduledAt)) ? (
            <DateTime date={scheduledAt} />
          ) : (
            NOT_SCHEDULED
          ),
        },
        [TABLE_COLUMN_TITLES.updatedAt]: {
          value: isValid(new Date(updatedAt)) ? (
            <DateTime date={updatedAt} />
          ) : (
            NULL_ENTRY
          ),
        },
        [TABLE_COLUMN_TITLES.prefix]: prefix ?? NULL_ENTRY,
        [TABLE_COLUMN_TITLES.pageCount]: getPageCountField(pageCount),
        [TABLE_COLUMN_OPTIONS.ACTIONS]: {
          value:
            actionsTable?.length > 0 ? (
              <ActionsTableRow
                actions={actionsTable}
                contentType={contentType}
              />
            ) : (
              ''
            ),
        },
      },

      children: generateTableEntries(getSortedVersions(children), contentType),
    }
  })
}

export const getPageCountField = (pageCount: number): string => {
  if (!Number.isFinite(pageCount)) {
    return NULL_ENTRY
  }
  return `${pageCount} page${pageCount > 1 ? 's' : ''}`
}

export const getTableItemStatus = ({
  isActive,
}: {
  isActive: boolean
}): string => {
  return statusMapping[isActive ? STATUS.ACTIVE : STATUS.INACTIVE]
}
