import styled from 'styled-components'
import { theme } from 'ds4-beta'

export const StyledUL = styled.ul`
  list-style-position: inside;
  max-height: 30vh;
  overflow-y: auto;

  li {
    line-height: ${({ theme }) => theme.typography.lineHeight.snug};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const StyledPreformatted = styled.div`
  pre {
    border-radius: ${({ theme }) => theme.borderRadius.default};
    padding: ${({ theme }) => theme.space.space_2};
  }
`

export const StyledSummary = styled.div`
  ${theme.typography.body1.regular};
`
