import React, { useState } from 'react'
import { AttributeMenuProps } from './types'
import { StyledActionButton } from './styles'
import { Box, Popover, InteractiveIcon } from 'ds4-beta'
import { ITEM_CONTEXT_MENU_OPTIONS } from 'src/constants'

const AttributeMenu: React.FC<AttributeMenuProps> = ({
  handleOnClickEdit,
  handleOnClickDelete,
}) => {
  const [toggle, setToggle] = useState(false)

  return (
    <Popover
      trigger={
        <InteractiveIcon
          iconName='MoreActions'
          onClick={() => setToggle(!toggle)}
        />
      }
      triggerEvent='click'
      controlled={{ open: toggle }}
      placement='bottom-start'
    >
      <Box flex={{ flexDirection: 'column' }}>
        <StyledActionButton
          key={ITEM_CONTEXT_MENU_OPTIONS.EDIT}
          onClick={() => {
            setToggle(!toggle)
            handleOnClickEdit()
          }}
        >
          {ITEM_CONTEXT_MENU_OPTIONS.EDIT}
        </StyledActionButton>
        <StyledActionButton
          key={ITEM_CONTEXT_MENU_OPTIONS.DELETE}
          onClick={() => {
            handleOnClickDelete()
            setToggle(!toggle)
          }}
        >
          {ITEM_CONTEXT_MENU_OPTIONS.DELETE}
        </StyledActionButton>
      </Box>
    </Popover>
  )
}

export default AttributeMenu
