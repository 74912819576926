import { Box } from 'ds4-beta'
import React, { FunctionComponent } from 'react'
import ContentCTAModule from 'src/ds4/components/ContentCTAModule'
import ContentFilterModule from 'src/ds4/components/ContentFilterModule'
import { ContentListModuleProps } from './types'
import ContentSearchModule from 'src/ds4/components/ContentSearchModule'
import ContentTabModule from 'src/ds4/components/ContentTabModule'
import ContentTableModule from 'src/ds4/components/ContentTableModule'
import PaginationCaptionTable from 'src/ds4/components/ContentTableModule/PaginationCaptionTable'
import { hasCheckedFilters } from 'src/ds4/data/utils'
import ContentModalModule from 'src/ds4/components/ContentModalModule'

const ContentListModule: FunctionComponent<ContentListModuleProps> = ({
  ctaProps,
  filterProps,
  modalProps,
  tabProps,
  tableProps,
  searchProps,
}) => {
  const doesHaveFilter = Boolean(filterProps?.entries?.length > 0)
  const doesHaveTabs = Boolean(tabProps?.tabs?.length > 0)

  return (
    <Box padding={[6]}>
      <ContentModalModule {...modalProps} />
      <Box padding={{ bottom: 4 }}>
        <ContentCTAModule {...ctaProps} />
      </Box>
      {doesHaveTabs ? (
        <Box padding={{ bottom: 5 }}>
          <ContentTabModule {...tabProps} />
        </Box>
      ) : null}
      {doesHaveFilter && searchProps ? (
        <Box padding={{ bottom: 3 }}>
          <ContentSearchModule {...searchProps} />
        </Box>
      ) : null}
      <Box
        flex={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
        margin={{ bottom: 4 }}
      >
        {doesHaveFilter ? (
          <ContentFilterModule {...filterProps} />
        ) : (
          <ContentSearchModule {...searchProps} />
        )}
        <PaginationCaptionTable {...tableProps} />
      </Box>
      <ContentTableModule
        {...tableProps}
        hasCheckedFilters={hasCheckedFilters(filterProps?.entries || [])}
      />
    </Box>
  )
}

export default ContentListModule
