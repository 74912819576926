import { OK, SETTINGS } from 'src/constants'
import { Modal } from 'ds4-beta'
import React from 'react'
import styled from 'styled-components'

const StyledUL = styled.ul`
  list-style-position: inside;
  li {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const DuplicateDescriptorsModal = ({
  onClose,
  duplicateIds,
}: {
  onClose: () => void
  duplicateIds: Set<string>
}): JSX.Element => {
  return (
    <Modal
      size='small'
      onClose={onClose}
      onBackdropClick={onClose}
      headerText={SETTINGS.DUPLICATE_DESCRIPTORS_DIALOG.HEADING}
      isVisible={true}
      dataTestid='modal'
      footerButtons={[
        {
          variant: 'primary',
          onClick: onClose,
          text: OK,
        },
      ]}
    >
      <div>
        <p>{SETTINGS.DUPLICATE_DESCRIPTORS_DIALOG.SUMMARY}</p>
        <p>
          <StyledUL>
            {Array?.from(duplicateIds).map?.(dId => (
              <li key={`duplicate-id-${dId}`}>{dId}</li>
            ))}
          </StyledUL>
        </p>
        <p>{SETTINGS.DUPLICATE_DESCRIPTORS_DIALOG.CALL_TO_ACTION}</p>
        <p>{SETTINGS.DUPLICATE_DESCRIPTORS_DIALOG.NOTE}</p>
      </div>
    </Modal>
  )
}

export default DuplicateDescriptorsModal
