import { QUICK_LINK, STATUS } from 'src/constants'
import {
  getContentScheduledAt,
  populateVariantQuickLinkTitle,
} from 'src/data/utils'
import { useGEPageCtxOptions, useGEVersionCtxOptions } from './context-options'
import { ContentTableItemProps } from 'components/ContentTableModule/types'
import { FeaturesList } from 'data/types'
import { GEContent } from 'src/graphql-proxy/transformations/globalElements/types'
import { NavigateFn } from '@reach/router'
import { TABLE_COLUMN_OPTIONS } from 'src/ds4/components/ContentTableModule/constants'
import { TableColumns } from '../pages-list/types'
import { UserPermissions } from 'contexts/userPermissions'
import { VERSION_STATUS } from 'modules/editor/constants'
import { getTableItemStatus } from 'components/ContentTableModule/utils'
import { navigateToGEVariantEditor } from './modal-operations'
import statusMapping from 'components/DetailsTable3.0/statusMapping'

export function constructTableColumns(
  featuresFlags: FeaturesList
): TableColumns[] {
  return [
    { title: TABLE_COLUMN_OPTIONS.NAME, width: 150 },
    featuresFlags.multiChannel.enabled && {
      title: TABLE_COLUMN_OPTIONS.CHANNEL,
      width: 150,
    },
    featuresFlags.i18n.enabled && {
      title: TABLE_COLUMN_OPTIONS.LOCALE,
      width: 150,
    },
    { title: TABLE_COLUMN_OPTIONS.STATUS, width: 120 },
    { title: TABLE_COLUMN_OPTIONS.SCHEDULED_AT, width: 150 },
    { title: TABLE_COLUMN_OPTIONS.UPDATED_AT, width: 150 },
    { title: TABLE_COLUMN_OPTIONS.ACTIONS, width: 150 },
  ].filter(item => item)
}

export function globalElementsDtoToTableEntries(
  gelements: GEContent[],
  {
    userPermissions,
    archived,
    getVersionCtxOptions,
    getPageCtxOptions,
    navigate,
  }: {
    userPermissions: UserPermissions
    archived: boolean
    getPageCtxOptions: ReturnType<typeof useGEPageCtxOptions>
    getVersionCtxOptions: ReturnType<typeof useGEVersionCtxOptions>
    navigate: NavigateFn
  }
): ContentTableItemProps[] {
  if (!gelements?.length) {
    return []
  }

  return gelements.map(ge => {
    return {
      name: ge.name,
      id: ge?.id,
      uid: ge?.id,
      scheduledAt: getContentScheduledAt(ge?.variants ?? []),
      status: archived
        ? (statusMapping[STATUS.ARCHIVED] as string)
        : getTableItemStatus({ isActive: ge?.isActive }),
      updatedAt: ge?.updatedAt,
      contextMenuOptions: getPageCtxOptions(ge),
      children: ge?.variants.map(v => {
        return {
          id: v?.id,
          uid: v?.id,
          name: v?.versionName,
          status: (archived
            ? statusMapping[STATUS.ARCHIVED]
            : statusMapping[v?.status]) as string,
          scheduledAt: v?.startDate ?? '',
          contextMenuOptions: getVersionCtxOptions(ge, v),
          quickLinkTitle: populateVariantQuickLinkTitle(userPermissions, v, {
            content: QUICK_LINK.VIEW_GE,
          }),
          quickLinkHandler: populateQuickLinkHandler({
            versionId: v?.id,
            navigate,
            globalElementId: ge?.id,
            reschedule:
              v?.status === VERSION_STATUS.SCHEDULED &&
              userPermissions.hasPublisherPermissions,
          }),
          channels: v?.channels?.map(c => c.name),
          locales: v?.locales,
          updatedAt: v?.updatedAt,
        }
      }),
    }
  })
}

const populateQuickLinkHandler = ({
  versionId,
  navigate,
  globalElementId,
  reschedule = false,
}: {
  versionId: string
  navigate: NavigateFn
  globalElementId: string
  reschedule?: boolean
}) => {
  return () =>
    navigateToGEVariantEditor(globalElementId, versionId, navigate, {
      reschedule,
    })
}
