import { theme } from 'ds4-beta'
import styled from 'styled-components'

export const StyledForm = styled.form`
  overflow: visible;
`
export const StyledFormField = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: ${theme.space[2]};
  margin-top: ${theme.space[2]};

  textarea {
    ${({ viewMode }) =>
      viewMode &&
      `
        pointer-events: none;
        opacity: 0.5;
    `}
  }

  .url__label .input__label {
    position: relative;
  }
`

export const StyledButtonWrapper = styled.div`
  margin-top: ${theme.space[6]};
  display: flex !important;
  justify-content: flex-end;
  gap: ${theme.space[2]};

  & > button:first-child {
    border: 1px solid transparent;
  }
`
