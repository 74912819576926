import 'react-datepicker/dist/react-datepicker.css'
import { Box, DatePicker, Modal, TimePicker, useToast, Banner } from 'ds4-beta'
import { EDITOR_TOAST_MESSAGES, VERSION_STATUS } from 'modules/editor/constants'
import { FF_NAMES } from 'src/constants'
import React, { useEffect, useState } from 'react'
import {
  SCHEDULE_VARIANT,
  UPDATE_GLOBAL_ELEMENTS_AND_SCHEDULE,
} from 'services/graphql'
import { getDate, getMonth, getYear, set } from 'date-fns'
import {
  getErrorMessageOnInvalidStartDate,
  getErrorMessageOnInvalidStartTime,
} from 'lib/validation/schedulePublishValidator'
import { isEditorPage, isGcEditorPage } from 'modules/editor/utils'
import { retrieveTitle, retrieveToastMessage } from './utils'
import PropTypes from 'prop-types'
import { SHOW_TOAST_MESSAGE } from 'src/ds4/modules/toast-messages/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import globalElementService from 'services/api/globalElement'
import { handleFormError } from 'src/ds4/components/FormDialog/utils'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import pageService from 'services/api/page'
import useAsync from 'hooks/useAsync'
import { useFlag } from '@unleash/proxy-client-react'
import { useLocation } from '@reach/router'
import { useMutation } from '@apollo/client'
const {
  unleashFFs: { I18N },
} = FF_NAMES
const VersionPublishDialog = props => {
  const {
    handleClose,
    updateStatus,
    globalElement,
    page,
    menu,
    version,
    onSuccess,
    handleOnSaveButtonClick,
    updatedComponents,
    updatedLocalizedComponents,
  } = props
  const showToast = useToast()
  const shouldUseGraphQL = useFlag(FF_NAMES.unleashFFs.USE_GRAPHQL) ?? false
  const [startDate, setStartDate] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [validationErrorMessages, setValidationErrorMessages] = useState({})
  const location = useLocation()

  const [scheduleVariant] = useMutation(SCHEDULE_VARIANT)
  const [updateGlobalElementsAndSchedule] = useMutation(
    UPDATE_GLOBAL_ELEMENTS_AND_SCHEDULE
  )
  const isPage = isEditorPage(location.pathname)
  const isGlobal = isGcEditorPage(location.pathname)
  const isI18n = useFlag(I18N)
  useEffect(() => {
    setValidationErrorMessages({
      datePicker: getErrorMessageOnInvalidStartDate(startDate),
      timePicker: getErrorMessageOnInvalidStartTime(startDate, startTime),
    })
  }, [startDate, startTime])

  const datePickerErrorMessage = validationErrorMessages.datePicker
  const timePickerErrorMessage = validationErrorMessages.timePicker
  const hasErrors = !!(datePickerErrorMessage || timePickerErrorMessage)
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSubmit = async () => {
    try {
      const scheduleDateAndTime = set(startTime, {
        year: getYear(startDate),
        month: getMonth(startDate),
        date: getDate(startDate),
      })

      const isPastErrorMessage = getErrorMessageOnInvalidStartTime(
        scheduleDateAndTime
      )

      if (isPastErrorMessage) {
        setValidationErrorMessages({ timePicker: isPastErrorMessage })

        return
      }

      let response
      const shouldHandleSave = isPage || (!shouldUseGraphQL && isGlobal)
      if (shouldHandleSave) {
        await handleOnSaveButtonClick()
      }
      if (shouldUseGraphQL || !isEmpty(menu)) {
        if (isGlobal) {
          const contentId = globalElement?.id
          response = await updateGlobalElementsAndSchedule({
            variables: {
              input: {
                contentId,
                variantId: version?.id,
                ...(!isI18n && { components: updatedComponents }),
                ...(isI18n && {
                  localizedComponents: updatedLocalizedComponents,
                }),
                startDate: scheduleDateAndTime.toISOString(),
              },
            },
          })
          showToast({
            label: EDITOR_TOAST_MESSAGES.GLOBAL_ELEMENT_PUBLISH_SUCCESS,
          })
        } else {
          const contentId = menu?.id ?? page?.pageId ?? globalElement?.id
          const { data } = await scheduleVariant({
            variables: {
              input: {
                contentId,
                variantId: version.id,
                startDate: scheduleDateAndTime.toISOString(),
              },
            },
          })
          response = data.schedulePublish
        }
      } else if (isPage) {
        response = await pageService.updateVersion(
          {
            pageId: page.pageId,
            versionId: version.versionId,
          },
          {
            startDate: scheduleDateAndTime.toISOString(),
          }
        )
      } else if (isGlobal) {
        response = await globalElementService.updateVersion(
          {
            globalElementId: globalElement.id,
            versionId: version.id,
          },
          {
            startDate: scheduleDateAndTime.toISOString(),
          },
          true
        )
      }

      updateStatus(VERSION_STATUS.SCHEDULED)
      showToast({
        label: retrieveToastMessage(isPage, isGlobal),
      })

      if (onSuccess) onSuccess()
      handleClose()
      return response
    } catch (err) {
      handleFormError(err)
    }
  }

  const submission = useAsync(handleSubmit)

  const renderModalContent = () => {
    return (
      <Box>
        {submission.error && (
          <Banner
            label={submission.error.message}
            type='default'
            variant='error'
          />
        )}
        <Box
          flex={{
            justifyContent: 'start',
          }}
          gap={4}
        >
          <Box>
            <DatePicker
              label='Start date'
              minDate={new Date()}
              onSelect={newDate => setStartDate(newDate)}
              defaultValue={startDate}
              error={datePickerErrorMessage}
            />
          </Box>
          <Box>
            <TimePicker
              label='Start time'
              defaultValue={startTime}
              onSelect={newTime => setStartTime(newTime)}
              error={timePickerErrorMessage}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Modal
      headerText={retrieveTitle(isPage, isGlobal)}
      isVisible={true}
      footerButtons={[
        {
          onClick: handleClose,
          text: 'Cancel',
          variant: 'tertiary_dark',
        },
        {
          onClick: submission.run,
          text: 'Save',
          variant: 'primary',
          isDisabled: hasErrors || submission.loading,
          dataTestid: 'submit-button',
        },
      ]}
      size='small'
      onBackdropClick={handleClose}
      onClose={handleClose}
    >
      {renderModalContent()}
    </Modal>
  )
}

VersionPublishDialog.defaultProps = {
  page: {},
  globalElement: {},
  menu: {},
  version: {},
  updatedComponents: [],
  updatedLocalizedComponents: [],
  handleClose: noop,
  updateStatus: noop,
  handleOnSaveButtonClick: noop,
}

VersionPublishDialog.propTypes = {
  page: PropTypes.shape({
    pageId: PropTypes.number,
    name: PropTypes.string,
    pageUrl: PropTypes.string,
  }),
  globalElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  menu: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  version: PropTypes.shape({
    versionId: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    id: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    name: PropTypes.string.isRequired,
  }),
  handleClose: PropTypes.func.isRequired,
  updateStatus: PropTypes.func,
  onSuccess: PropTypes.func,
  showToastMessage: PropTypes.func.isRequired,
  handleOnSaveButtonClick: PropTypes.func.isRequired,
  updatedComponents: PropTypes.shape([
    {
      id: PropTypes.string,
      allowNestedComponents: PropTypes.bool,
      isGlobal: PropTypes.bool,
      isSelected: PropTypes.bool,
      key: PropTypes.string,
      label: PropTypes.string,
      order: PropTypes.number,
      params: PropTypes.shape({}),
    },
  ]),
  updatedLocalizedComponents: PropTypes.shape([
    {
      locale: PropTypes.string,
      components: PropTypes.shape([
        {
          id: PropTypes.string,
          allowNestedComponents: PropTypes.bool,
          isGlobal: PropTypes.bool,
          isSelected: PropTypes.bool,
          key: PropTypes.string,
          label: PropTypes.string,
          order: PropTypes.number,
          params: PropTypes.shape({}),
        },
      ]),
    },
  ]),
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showToastMessage: SHOW_TOAST_MESSAGE,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(VersionPublishDialog)
