import { Box, useToast } from 'ds4-beta'
import { FF_NAMES, PUBLISH_MODAL } from 'src/constants'
import React, { useEffect, useMemo, useState } from 'react'
import {
  getDescriptorMismatchState,
  getSelectedRoute,
} from 'src/ds4/modules/editor/selectors'
import { CONTENT_TYPE } from 'src/ds4/components/ContentEditorCTA/types'
import ConfirmDialog from 'src/ds4/components/ConfirmDialog'
import ContentEditorCTA from 'src/ds4/components/ContentEditorCTA'
import EditorPageTitle from 'src/ds4/modules/editor/components/editorPageTitle'
import PreviewControls from 'src/ds4/components/PreviewControls'
import PropTypes from 'prop-types'
import { VERSION_STATUS } from 'src/ds4/modules/editor/constants'
import { getActiveLanguage } from 'store/i18n/selectors'
import getHasEditAccessBasedOnVersionStatus from 'lib/getHasEditAccessBasedOnVersionStatus'
import getSaveTimeDuration from './getSaveTimeDuration'
import { getVersionLocaleList } from './EditorFilters/utils'
import noop from 'lodash/noop'
import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'

export const EDITOR_TYPES = Object.freeze({
  GLOBAL_COMPONENT: 'gcEditor',
  PAGE: 'pageEditor',
})

const {
  unleashFFs: { USE_GRAPHQL, I18N },
} = FF_NAMES
// eslint-disable-next-line sonarjs/cognitive-complexity
const EditorContentHeader = props => {
  const {
    saveTime,
    versionName,
    versionStatus,
    updatedAt,
    isParentActive,
    handleOnPublishButtonClick,
    selectedDevice,
    hasCuratedComponents,
    previewUrl,
    handleOnFrameChange,
    handleOnSaveButtonClick,
    handleOnScheduleButtonClick,
    type,
    multiChannel,
    isEditorLoading,
    locales,
    onEditorOptionClick,
  } = props

  const selectedRoute = useSelector(getSelectedRoute)
  const [selectedLocale, setSelectedLocale] = useState('')
  const activeLang = useSelector(getActiveLanguage)
  const showToast = useToast()
  const [isSaveTimeReady, setIsSaveTimeReady] = useState(false)

  let assignedLocales = useMemo(() => {
    return []
  }, [])

  if (selectedRoute?.locales && locales) {
    assignedLocales = getVersionLocaleList(locales, selectedRoute.locales)
  }

  useEffect(() => {
    if (assignedLocales.find(({ code }) => code === activeLang)) {
      setSelectedLocale(activeLang)
    } else {
      setSelectedLocale(assignedLocales[0]?.code)
    }
  }, [assignedLocales, activeLang])

  const hasDescriptorMismatch = useSelector(getDescriptorMismatchState)
  const [timer, setTimer] = useState(null)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const duration = 30000
  const isLiveVersion = versionStatus === VERSION_STATUS.LIVE
  const isPublishDisabled =
    hasDescriptorMismatch || !hasCuratedComponents || !isParentActive
  const contentType =
    type === EDITOR_TYPES.GLOBAL_COMPONENT
      ? CONTENT_TYPE.GLOBAL_ELEMENT
      : CONTENT_TYPE.PAGE

  const { hasPublisherPermissions, hasEditorPermissions } = useUserPermissions()
  const hasEditAccess = getHasEditAccessBasedOnVersionStatus(
    { hasEditorPermissions, hasPublisherPermissions },
    versionStatus
  )
  const isI18n = useFlag(I18N)
  const useGraphql = useFlag(USE_GRAPHQL)
  const isGraphQlEnabled = isI18n || useGraphql
  const isSaveEnabled = !isLiveVersion && hasEditAccess

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(Date.now())
    }, duration)

    return () => clearInterval(interval)
  }, [])

  const handleConfirmDialogSubmit = async () => {
    if (!isGraphQlEnabled) {
      await handleOnSaveButtonClick()
    }

    handleOnPublishButtonClick()
    setShowConfirmationDialog(false)
  }

  const handleConfirmDialogCancel = () => {
    setShowConfirmationDialog(false)
  }

  const saveTimeDuration = useMemo(() => {
    const result = getSaveTimeDuration({
      time: saveTime,
      timer,
      versionName: versionName,
    })
    setIsSaveTimeReady(true)
    return result
  }, [saveTime, timer, versionName])

  useEffect(() => {
    if (isSaveTimeReady && saveTimeDuration && saveTimeDuration.trim() !== '') {
      showToast({
        id: 'saveTimeToast',
        label: saveTimeDuration,
      })
    }
    setIsSaveTimeReady(false)
  }, [isSaveTimeReady, saveTimeDuration, showToast])

  return (
    <Box margin={{ top: 6 }}>
      <Box
        flex={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <EditorPageTitle
          versionStatus={versionStatus}
          versionName={versionName}
          onEditorOptionClick={onEditorOptionClick}
          selectedRoute={selectedRoute}
        />
        {!isEditorLoading && (
          <ContentEditorCTA
            locales={assignedLocales}
            selectedLocale={selectedLocale}
            contentType={contentType}
            isContentLive={isLiveVersion}
            lastUpdatedDateString={updatedAt}
            onScheduleOptionClicked={handleOnScheduleButtonClick}
            publishButtonProps={{
              hasTooltip: !hasCuratedComponents,
              isDisabled: isPublishDisabled,
              isVisible: hasPublisherPermissions,
              onClick: () => {
                setShowConfirmationDialog(true)
              },
            }}
            saveButtonProps={{
              hasTooltip: !hasCuratedComponents,
              isDisabled: !hasCuratedComponents || hasDescriptorMismatch,
              isVisible: isSaveEnabled,
              onClick: handleOnSaveButtonClick,
              isSavePrimary: hasEditAccess && !hasPublisherPermissions,
            }}
            shouldShowLockIcon={!hasEditAccess}
            multiChannel={multiChannel}
          />
        )}
      </Box>
      {showConfirmationDialog && (
        <ConfirmDialog
          isVisible={true}
          title={PUBLISH_MODAL.title}
          content={PUBLISH_MODAL.getContent(contentType)}
          submitText={PUBLISH_MODAL.submitText}
          onSubmit={handleConfirmDialogSubmit}
          cancelText={PUBLISH_MODAL.cancelText}
          onCancel={handleConfirmDialogCancel}
        />
      )}
      <PreviewControls
        selectedDevice={selectedDevice}
        previewUrl={previewUrl}
        handleOnFrameChange={handleOnFrameChange}
      />
    </Box>
  )
}

EditorContentHeader.defaultProps = {
  saveTime: null,
  versionName: '',
  versionStatus: '',
  updatedAt: '',
  isParentActive: false,
  previewUrl: '',
  selectedDevice: 'desktop',
  hasCuratedComponents: false,
  handleOnSaveButtonClick: noop,
  handleOnScheduleButtonClick: noop,
  handleOnPublishButtonClick: noop,
  handleOnFrameChange: noop,
  type: EDITOR_TYPES.PAGE,
  multiChannel: false,
  isEditorLoading: true,
  isUsingToby: false,
  locales: null,
}

EditorContentHeader.propTypes = {
  saveTime: PropTypes.number,
  versionName: PropTypes.string.isRequired,
  versionStatus: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  isParentActive: PropTypes.bool.isRequired,
  previewUrl: PropTypes.string.isRequired,
  selectedDevice: PropTypes.string.isRequired,
  hasCuratedComponents: PropTypes.bool.isRequired,
  handleOnSaveButtonClick: PropTypes.func.isRequired,
  handleOnScheduleButtonClick: PropTypes.func.isRequired,
  handleOnPublishButtonClick: PropTypes.func.isRequired,
  handleOnFrameChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  multiChannel: PropTypes.bool.isRequired,
  isEditorLoading: PropTypes.bool.isRequired,
  locales: PropTypes.array,
  onEditorOptionClick: PropTypes.func.isRequired,
}

export default EditorContentHeader
