import {
  CommonInputFieldProps,
  CustomFormikProps,
} from '../versions/VersionDetailsDialog/types'
import { getFormComponents } from 'src/ds4/components/ContentModalModule/utils'

export const getFormInputFields = ({
  contentElement,
  values,
  ...rest
}: CustomFormikProps): JSX.Element => {
  const inputFields: Array<CommonInputFieldProps> = contentElement.getInputFields(
    values
  )

  return getFormComponents({ components: inputFields, values, ...rest })
}
