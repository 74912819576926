import {
  EDITOR_INSERT_COMPONENT,
  EDITOR_IS_DELETING,
} from 'src/ds4/modules/editor/actions'
import React, { useCallback } from 'react'
import {
  StyledComponentItem,
  StyledComponentItemLabel,
  StyledComponentItemOptions,
  StyledIconDelete,
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { CuratedComponentProps } from 'store/types'
import { SortableComponentProps } from './types'
import { SortableElement } from 'react-sortable-hoc'
import getHasEditAccessBasedOnVersionStatus from 'lib/getHasEditAccessBasedOnVersionStatus'
import { getVersionStatus } from 'src/ds4/modules/editor/selectors'
import { nanoid } from 'nanoid'
import produce from 'immer'
import rfdc from 'rfdc'
import { useUserPermissions } from 'contexts/userPermissions'
import { Box, Icon } from 'ds4-beta'

const SortableItem = SortableElement((props: SortableComponentProps) => {
  const deepClone = rfdc() as (
    cmp: CuratedComponentProps
  ) => CuratedComponentProps
  const dispatch = useDispatch()
  const {
    component,
    handleOnUpdateComponent,
    isGlobalComponentEditor,
    setTooltipText,
    position,
  } = props

  const versionStatus = useSelector(getVersionStatus) as string
  const { hasEditorPermissions, hasPublisherPermissions } = useUserPermissions()
  const hasEditAccess = getHasEditAccessBasedOnVersionStatus(
    { hasEditorPermissions, hasPublisherPermissions },
    versionStatus
  )

  const onClickComponentItem = useCallback(() => {
    const nextComponent = produce(component, draftState => {
      draftState.isSelected = true
    })
    const payload = {
      key: component.key,
      nextComponent,
    }

    handleOnUpdateComponent(payload)
  }, [component, handleOnUpdateComponent])

  const onClickDeleteItem = (e: Event) => {
    e.stopPropagation()
    dispatch(
      EDITOR_IS_DELETING({
        isDeletingComponent: true,
        deletePayload: {
          id: component.id,
          order: component.order,
          label: component.label,
          isParentComponent: true,
        },
      })
    )
  }

  const onClickInsertItem = () => {
    const targetComponentCopy = deepClone(component)
    targetComponentCopy.key = nanoid()
    delete targetComponentCopy._id
    targetComponentCopy.order++

    const updateComponents = (components, componentToAdd) => {
      const startIndex = position + 1

      const componentsWithNewOrder = components.slice(startIndex).map(c => {
        c.order++
        return c
      }) as CuratedComponentProps[]
      const affectedComponentsCount = componentsWithNewOrder.length + 1

      components.splice(
        startIndex,
        affectedComponentsCount,
        componentToAdd,
        ...componentsWithNewOrder
      )

      return components
    }

    dispatch(
      EDITOR_INSERT_COMPONENT({
        component: targetComponentCopy,
        callback: updateComponents,
        paths: ['curatedComponents'],
      })
    )
  }

  const onMouseEnter = () => setTooltipText(component.label)
  const onMouseLeave = () => setTooltipText('')

  const renderComponentItemOptions = () => {
    return (
      <StyledComponentItemOptions
        isGlobalComponentEditor={isGlobalComponentEditor}
      >
        <Box onClick={e => onClickDeleteItem(e as any)}>
          <Icon iconName='Trash' />
        </Box>
        {!isGlobalComponentEditor && (
          <Box
            onClick={onClickInsertItem}
            dataTestid='component-context-menu-duplicate'
          >
            <Icon iconName='Copy' />
          </Box>
        )}
      </StyledComponentItemOptions>
    )
  }

  return (
    <StyledComponentItem
      onClick={onClickComponentItem}
      data-testid='sortable-item'
      data-order={component.order}
    >
      <StyledComponentItemLabel
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {component.label}
      </StyledComponentItemLabel>
      {hasEditAccess && renderComponentItemOptions()}
    </StyledComponentItem>
  )
})

export default SortableItem
