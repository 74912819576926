import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { Box, InlineBanner, Modal } from 'ds4-beta'

const ConfirmDialog = props => {
  const { isVisible, title, content, cancelText, onCancel, onSubmit } = props

  const [shouldDisableCTA, setDisableCTA] = useState(false)

  if (!content || !isVisible || !title) {
    return null
  }

  const handleSubmit = () => {
    setDisableCTA(true)
    onSubmit()
  }

  return (
    <Modal
      isVisible={true}
      footerButtons={[
        {
          text: cancelText,
          variant: 'tertiary_dark',
          onClick: onCancel,
          dataTestid: 'confirm-dialog-cancel-button',
        },
        {
          text: 'Yes, delete',
          variant: 'destructive',
          onClick: handleSubmit,
          dataTestid: 'confirm-dialog-submit-button',
          isDisabled: shouldDisableCTA,
        },
      ]}
      headerText={title}
      onBackdropClick={onCancel}
      onClose={onCancel}
      size='small'
    >
      <Box margin={{ bottom: 4 }}>
        <InlineBanner
          label='You can’t take back this action.'
          variant='error'
        />
      </Box>
      {content}
    </Modal>
  )
}

ConfirmDialog.defaultProps = {
  isVisible: false,
  title: '',
  content: '',
  submitText: 'okay',
  cancelText: 'cancel',
  onCancel: noop,
  onSubmit: noop,
  submitButtonProps: {},
  cancelButtonProps: {},
}

ConfirmDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
}

export default memo(ConfirmDialog)
