import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

// type ThemeProps = {
//   theme: {
//     constants: {
//       barHeight: string
//     }
//   }
// }

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamily.sans};
  padding: ${({ theme }) => theme.space.space_6};

  a,
  a:visited {
    color: ${themeUi.palette.ui.cta.blue};
    text-decoration: none;
  }
`

export const StyledContent = styled.div``

export const StyledPrimaryText = styled.div`
  color: ${themeUi.palette.ui.neutral.grey2};
  padding-bottom: ${({ theme }) => theme.space.space_6};
`

export const StyledSecondaryText = styled.div`
  color: ${themeUi.palette.brand.primary.charcoal};
  font-size: ${({ theme }) => theme.typography.fontSize['2xl']};
  padding-bottom: ${({ theme }) => theme.space.space_7};
  white-space: pre-wrap;
`
export const StyledButtonBlock = styled.div`
  padding-bottom: ${({ theme }) => theme.space.space_8};
  button {
    display: inline-block;
  }
`
