import {
  CommonElementProps,
  DialogModalSectionProps,
  GEParentElementProps,
  MenuParentElementProps,
  PageParentElementProps,
  VersionTypesEnum,
} from './types'
import { EDITOR_OPTIONS, FF_NAMES, MODAL_ACTION } from 'src/constants'
import { MenuVersionCreateGraphQLResponse } from 'src/graphql-proxy/transformations/menu-version/types'
import ModalSections from 'src/ds4/components/ModalSections'
import React from 'react'
import { GetDialogModalSections } from './utils'
import { useFlag } from '@unleash/proxy-client-react'
import { useNavigate } from '@reach/router'

const { CREATE, EDIT, VIEW, COPY } = MODAL_ACTION

const modalTitleMap = {
  [CREATE]: EDITOR_OPTIONS.CREATE,
  [EDIT]: EDITOR_OPTIONS.EDIT,
  [VIEW]: EDITOR_OPTIONS.VIEW,
  [COPY]: EDITOR_OPTIONS.DUPLICATE,
}

const VersionDetailsDialog = ({
  isVisible,
  contentType,
  parentElement,
  versionElement,
  handleClose,
  mode,
  setVersionName,
  setVersionDescription,
  setVersionStatus,
  setMenu,
}: {
  isVisible: boolean
  contentType: VersionTypesEnum
  parentElement:
    | PageParentElementProps
    | GEParentElementProps
    | MenuParentElementProps
  versionElement: CommonElementProps
  handleClose: () => void
  mode: string
  setVersionName?: (versionName: string) => void
  setVersionDescription?: (versionDescription: string) => void
  setVersionStatus?: (versionStatus: string) => void
  setMenu?: (
    menu: MenuVersionCreateGraphQLResponse['createMenuVariant']
  ) => void
}): JSX.Element => {
  // TODO: 'shouldUseGraphQL' can be removed in when we have moved over to GraphQL from REST
  const navigate = useNavigate()
  const useGraphql: boolean = useFlag(FF_NAMES.unleashFFs.USE_GRAPHQL) ?? false
  const i18n = useFlag(FF_NAMES.unleashFFs.I18N) ?? false
  const shouldUseGraphQL = useGraphql || i18n
  const dialogModalSections: DialogModalSectionProps = GetDialogModalSections({
    contentType,
    parentElement,
    versionElement,
    handleClose,
    navigate,
    shouldUseGraphQL,
    mode,
    i18n,
    setVersionName,
    setVersionDescription,
    setVersionStatus,
    setMenu,
  })
  return (
    <ModalSections
      isVisible={isVisible}
      modalSections={dialogModalSections}
      selectedSection='general-details'
      onClose={handleClose}
      title={modalTitleMap[mode]}
    />
  )
}

export default VersionDetailsDialog
