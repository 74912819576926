import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { Box, ButtonProps, Dropzone, GridCol, GridRow, Modal } from 'ds4-beta'

const SettingsModal = (props: any) => {
  const { onBulkUpload, onClose } = props
  const [file, setFile] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const onReplaceOrRetryFile = async (newFile, setUpdatedFiles) => {
    const reader = new FileReader()
    reader.onload = () => {
      setUpdatedFiles([
        {
          name: newFile.name,
          size: newFile.size,
          status: 'success',
        },
      ])
      setFile([newFile])
    }
    reader.readAsDataURL(newFile)
    return true
  }

  const filehandler = async (item, setUpdatedFiles) => {
    const reader = new FileReader()
    const file = item[0]
    const fileName = file?.name
    setLoading(true)
    if (fileName) {
      const idxDot = fileName.lastIndexOf('.') + 1
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
      if (extFile) {
        reader.onload = () => {
          setFile([file])
          setUpdatedFiles([
            {
              name: file.name,
              size: file.size,
              status: 'success',
            },
          ])
        }
        reader.readAsDataURL(file)
        setLoading(false)
      } else {
        setFile({})
        setUpdatedFiles([
          {
            name: file.name,
            size: file.size,
            status: 'error',
          },
        ])
        setLoading(false)
      }
    }

    return true
  }

  const onRemove = async (file: File, setFiles) => {
    setFile({})
    setFiles([])
    return true
  }

  const modalBody = () => {
    return (
      <>
        <GridRow dataTestid='csv-modal-body' padding={false}>
          <GridCol sm={12}>
            <Box padding={{ left: 1, right: 1 }}>
              <Dropzone
                dataTestId='dropzone-container'
                accept={{
                  'application/json': ['.json'],
                }}
                onRemove={onRemove}
                onDrop={filehandler}
                onReplace={onReplaceOrRetryFile}
                onRetry={onReplaceOrRetryFile}
              />
            </Box>
          </GridCol>
        </GridRow>
      </>
    )
  }

  const footerButtons: ButtonProps[] = [
    {
      variant: 'secondary',
      onClick: () => {
        onClose()
      },
      text: 'Cancel',
    },
    {
      variant: 'primary',
      isDisabled: !file[0]?.name,
      onClick: () => {
        onBulkUpload(file)
        onClose()
      },
      text: 'Upload file',
      isLoading: loading,
    },
  ]

  return (
    <>
      <Modal
        children={modalBody()}
        size='small'
        headerText={'Import CSV file'}
        onClose={onClose}
        isVisible={true}
        dataTestid='csv-modal-container'
        footerButtons={footerButtons}
      />
    </>
  )
}

export default SettingsModal
