import { GridCol, GridRow, theme } from 'ds4-beta'
import styled from 'styled-components'

export const StyledSEOMetaDataLabel = styled.span`
  margin-top: ${theme.space[2]};
  ${theme.typography.h5};
`

export const StyledAddButtonContainer = styled.div`
  display: flex;
  align-self: end;
  margin-bottom: 2px;
`

const abSize = '50px'
export const StyledSEOMetaDataWrapper = styled.div`
  align-items: center;
`
export const StyledGridRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexGridCol = styled(GridCol)`
margin-left: ${theme.space[4]};
  display: flex;
`;

export const StyledContentFieldContainer = styled(GridCol)`
  margin-left: ${theme.space[4]};
`;