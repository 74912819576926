import {
  CREATE_NEW_VERSION_LABEL,
  MESSAGES,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import React, { useEffect, useState } from 'react'
import {
  hasInvalidCharacters,
  showSpecialCharactersToast,
} from 'src/ds4/components/FormDialog/common/utils/validateSpecialCharacters'
import { ACTIONS } from 'src/ds4/modules/pages/actions'
import ModalSections from 'src/ds4/components/ModalSections'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dialogModalSections from '../dialogModalSections'
import { noop } from 'lodash'
import pageService from 'services/api/page'
import { showToast } from 'components/ToastSnackbarContainer'
import useAsync from 'hooks/useAsync'
import { useNavigate } from '@reach/router'
import validation from './validation'
import {
  getBasePath,
  useNewNavigation,
} from 'src/contexts/navigationLinksContext'

const CreateNewPageVersionDialog = props => {
  const { isVisible, page, handleClose, onMakeCopyOfVersion } = props
  const [formValues, setFormValues] = useState(page)
  const [validationErrors, setValidationErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const submission = useAsync(handleSubmit)
  const navigate = useNavigate()
  const navNameChange = useNewNavigation()
  const basepath = getBasePath(navNameChange)

  useEffect(() => {
    if (submission.value) {
      handleClose()

      showToast({
        message: MESSAGES.getCreatedSuccess(formValues.versionName),
        kind: TOAST_MESSAGE_TYPES.SUCCESS,
      })

      if (submission.value.version?.versionId) {
        const redirectTo = `${basepath}/editor?pageId=${page.pageId}&versionId=${submission.value.version.versionId}`
        navigate(redirectTo)
      }
    }
    setValidationErrors(validation(formValues))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, submission.value])

  async function handleSubmit() {
    if (hasInvalidCharacters(formValues.versionName)) {
      await showSpecialCharactersToast()
      setShowErrors(true)
    } else if (Object.keys(validationErrors).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      const response = await pageService.createVersion({
        pageId: page.pageId,
        payload: {
          versionName: formValues.versionName,
          description: formValues.description,
        },
      })
      onMakeCopyOfVersion({
        version: response.version ?? null,
      })

      return response
    }
  }

  return (
    <ModalSections
      isVisible={isVisible}
      modalSections={dialogModalSections({
        formValues,
        submission,
        validationErrors,
        showErrors,
        handleClose,
        setFormValues,
      })}
      selectedSection='general-details'
      onClose={handleClose}
      title={CREATE_NEW_VERSION_LABEL}
    />
  )
}

CreateNewPageVersionDialog.defaultProps = {
  isVisible: true,
  page: {},
  handleClose: noop,
  onMakeCopyOfVersion: noop,
}

CreateNewPageVersionDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  page: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  onMakeCopyOfVersion: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onMakeCopyOfVersion: ACTIONS.MAKE_A_COPY_OF_VERSION,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(CreateNewPageVersionDialog)
