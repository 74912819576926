import styled from 'styled-components'
import { theme } from 'ds4-beta'

const FLYOUT_WIDTH = '200px'

export const StyledDropdown = styled.div`
  position: relative;
  height: fit-content;

  button[data-testid='preview-channels-button'] {
    background: transparent;
    border: none;
    color: ${theme.color.grey[100]};
    display: flex;
    gap: ${theme.space[5]};
    white-space: nowrap;
    padding: 0.625rem 0.75rem;
    margin: 0;

    .icon,
    path {
      fill: inherit;
    }
  }
`

export const StyledDropdownList = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  list-style: none;
  align-items: center;
  width: ${FLYOUT_WIDTH};
  ${theme.elevation[2]}
  top: 40px;
  border: 1px solid ${theme.color.grey[200]};
  border-radius: 0.25rem;
  background: ${theme.color.grey[0]};
  ${theme.typography.body1.regular};
`

export const StyledDropdownListItem = styled.li`
  padding: 1rem 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.grey[100]};
  }

  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`
