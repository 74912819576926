import PropTypes from 'prop-types'
import React from 'react'
import { StyledButtonWrapper } from './styles'
import { noop } from 'lodash'
import { Button } from 'ds4-beta'

// TODO: Rename and move to components/ContentModalModule/ when we transition to ContentModalModule
const VersionDetailHeader = props => {
  const {
    viewMode,
    submitButtonText,
    submitDisabled,
    onCancel,
    onSubmit,
  } = props
  return (
    <StyledButtonWrapper>
      {!viewMode && (
        <Button variant='secondary' text='Cancel' onClick={onCancel} />
      )}
      <Button
        text={submitButtonText}
        disabled={submitDisabled}
        dataTestid='submit-btn'
        onClick={onSubmit}
        variant='primary'
      />
    </StyledButtonWrapper>
  )
}

VersionDetailHeader.defaultProps = {
  viewMode: false,
  submitButtonText: 'Submit',
  submitDisabled: false,
  onCancel: noop,
  onSubmit: noop,
}

VersionDetailHeader.propTypes = {
  viewMode: PropTypes.bool,
  submitButtonText: PropTypes.string,
  submitDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default VersionDetailHeader
