import styled from 'styled-components'
import { theme } from 'ds4-beta'

export const StyledContainer = styled.div``

export const StyledDescriptorWrapper = styled.div`
  width: 100%;
  background: ${theme.color.grey[0]};
`
export const StyledDescriptorContainer = styled.div`
  padding: 32px !important;
`
