import { StyledPreformatted, StyledUL } from './styles'
import { InvalidContextProps } from '../types'
import { Modal } from 'ds4-beta'
import { OK } from 'src/constants'
import React from 'react'

const renderErrorContext = (context: InvalidContextProps) => {
  return (
    <div>
      <p>{context.description}</p>
      {Array.isArray(context.violations) ? (
        <StyledUL data-testid='violation-list'>
          {context.violations.map(item => (
            <li key={`violation-id-${item}`}>{item}</li>
          ))}
        </StyledUL>
      ) : (
        <StyledPreformatted>
          <p>Example:</p>
          <pre>
            {`[
              {
                "type": "Component",
                "id": "myComponentId",
                "label": "My Component",
                "attributes": {
                  
                }
              }
            ]`}
          </pre>
        </StyledPreformatted>
      )}
      <p>{context.footerText}</p>
    </div>
  )
}

const InvalidUploadDataModal = ({
  onClose,
  context,
}: {
  onClose: () => void
  context: InvalidContextProps
}): JSX.Element => {
  return (
    <Modal
      size='small'
      onClose={onClose}
      onBackdropClick={onClose}
      headerText={context.title}
      isVisible={true}
      dataTestid='modal'
      footerButtons={[
        {
          variant: 'primary',
          onClick: onClose,
          text: OK,
        },
      ]}
    >
      {renderErrorContext(context)}
    </Modal>
  )
}

export default InvalidUploadDataModal
